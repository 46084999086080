import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import NoteIcon from '@mui/icons-material/DescriptionOutlined'

import { useCurrentCompanyBranch } from '../../../../modules/companies'
import { type JobRequestForPartner } from '../../../../modules/jobRequests'
import { type CustomConfirmationFormProps } from '../../../ConfirmationModal/ConfirmationModal.types'
import { Form, NoteField, SubmitButton } from '../../../form'
import { ModalDescription, ModalFooter } from '../../../Modal'

type DeclineJobRequestConfirmationFormProps = CustomConfirmationFormProps & {
  jobRequest: JobRequestForPartner
}

const DeclineJobRequestConfirmationForm: React.FC<DeclineJobRequestConfirmationFormProps> = ({
  jobRequest,
  onConfirm,
  onDecline,
}) => {
  const { t } = useTranslation()

  const { data: currentCompanyBranch } = useCurrentCompanyBranch()
  const note = jobRequest.move.notes?.fromPartner?.find(note => note.companyBranchId === currentCompanyBranch?.id)?.note

  const form = useForm({
    defaultValues: { note: note?.note },
  })

  const onSubmit = async (values: { note?: string | null }) => {
    onConfirm(values)
  }

  return (
    <Form form={form} onSubmit={onSubmit}>
      <ModalDescription>
        <div className="mb-4 text-base">
          <div className="mb-4">
            { t('forms.declineJobRequest.description') }
          </div>

          <div className="mb-2 mt-4 flex items-center gap-x-2 text-base font-bold text-neutral-500">
            <NoteIcon className="!text-[16px]" />
            { t('forms.fields.partnerMessageToTheAgent.label') }
          </div>

          <div className="rounded-md border p-2">
            <NoteField
              name="note"
              className="border-none [&_.field-wrapper]:p-[2px]"
              autoFocus
            />
          </div>
        </div>
      </ModalDescription>
      <ModalFooter>
        <div>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onDecline}
            size="medium"
          >
            { t('actions.cancel') }
          </Button>
        </div>
        <div>
          <SubmitButton
            variant="contained"
            size="medium"
          >
            { t('actions.decline') }
          </SubmitButton>
        </div>
      </ModalFooter>
    </Form>
  )
}

export default DeclineJobRequestConfirmationForm
