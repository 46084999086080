import { type File } from '../../../../common/file/file.type'
import { useConfig } from '../../../config'
import { type CompanyBranch } from '../companies.models'

export const useLegalFileUrlGenerator = (companyBranch?: CompanyBranch) => {
  const apiUrl = useConfig('api.url')
  const legalFilesServerPath = useConfig('companies.legalFilesServerPath')

  return (file: File) => {
    if (!companyBranch) {
      return
    }
    return [
      apiUrl,
      legalFilesServerPath,
      companyBranch.id,
      file.id,
      file.filename,
    ].join('/')
  }
}
