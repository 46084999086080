import { useCurrentCompanyBranch, useNotifications } from '../../companies'
import { useConfig } from '../../config'
import { type PaginatedQueryResultData } from '../../graphQl'
import { useJobRequests } from './jobRequests.data.hooks'
import { JobRequestStatus } from './jobRequests.enums'
import { type LightJobRequest } from './jobRequests.models'

/**
 * this will fetch the pending job requests for the current company branch
 */
export const useFetchPendingRequestsNotifications = () => {
  const pendingPreviewLimit = useConfig<number>('jobRequests.pendingPreviewLimit')
  const { listening, set } = useNotifications()
  const { fetchJobRequests } = useJobRequests(listening ?? undefined)

  return async () => {
    if (!listening) {
      return
    }
    const key = `${listening}:pendingRequestsNotifications`

    const data = await fetchJobRequests({
      query: {
        nbResultsPerPage: pendingPreviewLimit,
        page: 1,
      },
      status: JobRequestStatus.Pending,
    })
    set(key, data)
  }
}

/**
 * return the pending request notifications globally fetch in the app
 */
export const usePendingRequestsNotifications = (companyBranchId?: string): PaginatedQueryResultData<LightJobRequest> | undefined => {
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()
  const { get } = useNotifications()
  const id = companyBranchId ?? currentCompanyBranch?.id
  if (!id) {
    return
  }
  const key = `${id}:pendingRequestsNotifications`
  return get(key)
}
