import { useTranslation } from 'react-i18next'
import Alert from '@mui/material/Alert'
import LockIcon from '@mui/icons-material/LockPersonOutlined'

import {
  type FullMove,
  type QuotePayload,
  useCancelSelectedQuoteAction,
  useEditQuoteAction,
} from '../../../modules/adminMoves'
import { useMoveState } from '../../../modules/moves'
import Spinner from '../../../components/Spinner'
import QuoteDetailsForm from '../../../components/forms/quotes/QuoteDetailsForm'
import ConfirmationModal, { useConfirmation } from '../../../components/ConfirmationModal'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { errorNotification } from '../../../components/ToastNotifications'

type CustomizeQuotePageProps = {
  move: FullMove
}

const CustomizeQuotePage: React.FC<CustomizeQuotePageProps> = ({
  move,
}) => {
  const { t } = useTranslation()
  const { selectedQuote } = useMoveState().forMove(move)
  const [cancelSelectedQuote, { loading: cancellingCustomization }] = useCancelSelectedQuoteAction()
  const [editQuote, { loading: editing }] = useEditQuoteAction()
  const { confirm, modalProps } = useConfirmation()
  const formatError = useErrorFormatter()

  const handleSubmit = async (payload: QuotePayload) => {
    try {
      await editQuote({ moveId: move.id, payload })
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  if (!selectedQuote) {
    return null
  }

  const handleCancel = async () => {
    if (!await confirm({
      question: t('pages.admin.move.customizeQuote.cancelConfirmation'),
      danger: true,
    })) {
      return
    }
    try {
      await cancelSelectedQuote(move.id)
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  return (
    <>
      { (cancellingCustomization || editing) && <Spinner backdrop floating /> }
      <Alert severity="info" className="mb-8 mt-4 !pt-4">
        <div className="-mt-2 font-sans text-lg">
          <LockIcon />
          { ' ' }
          <strong>
            { t('pages.admin.move.customizeQuote.lockWarning.title') }
          </strong>
          { ' ' }
          { t('pages.admin.move.customizeQuote.lockWarning.titleDetail') }
          <ul className="mt-2 list-disc">
            <li className="ml-12 pl-2">
              <strong>
                { t('pages.admin.move.customizeQuote.lockWarning.ifYouConfirm') }
              </strong>
              { ', ' }
              { t('pages.admin.move.customizeQuote.lockWarning.ifYouConfirmDetail') }
            </li>
            <li className="ml-12 pl-2">
              <strong>
                { t('pages.admin.move.customizeQuote.lockWarning.ifYouCancel') }
              </strong>
              { ', ' }
              { t('pages.admin.move.customizeQuote.lockWarning.ifYouCancelDetail') }
            </li>
          </ul>
        </div>
      </Alert>

      <QuoteDetailsForm
        quote={selectedQuote}
        promoCode={move.promoCode}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
      <ConfirmationModal {...modalProps} />
    </>
  )
}

export default CustomizeQuotePage
