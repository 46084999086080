import {
  useCurrentCompanyBranch,
  useLabourPricing,
  useSeason,
  useSeasonalPricing,
} from '../../../../../modules/companies'
import { ChargeDescription } from '../../../../../modules/invoices'
import { type JobForPartner } from '../../../../../modules/jobs'

/**
 * returns company price for the given date
 * includes stairs, special services and special items
 */
const useCompanyPricing = (date: Date) => {
  const { data: currentcompanyBranch } = useCurrentCompanyBranch()
  const getSeason = useSeason()
  const getSeasonalPricing = useSeasonalPricing()
  const getMovingLabourPricing = useLabourPricing()

  if (!currentcompanyBranch) {
    return
  }

  const season = getSeason(currentcompanyBranch, date)
  const movingLabour = getMovingLabourPricing({ companyBranch: currentcompanyBranch, date })
  const stairs = getSeasonalPricing(currentcompanyBranch?.additionalCharges?.stairs, season, true)
  const specialServices = Object.fromEntries(
    (currentcompanyBranch?.additionalCharges?.specialServices ?? [])
      .map((service) => ([
        service.key, getSeasonalPricing(service.value, season, true),
      ]))
      .filter(([, value]) => !!value),
  )
  const specialItems = Object.fromEntries(
    (currentcompanyBranch?.additionalCharges?.specialItems ?? [])
      .map((item) => ([
        item.key, getSeasonalPricing(item.value, season, true),
      ]))
      .filter(([, value]) => !!value),
  )
  const protectiveMaterial = Object.fromEntries(
    (currentcompanyBranch?.protectiveMaterial?.products ?? [])
      .map((product) => ([
        product.key, getSeasonalPricing(product.value, season, true),
      ]))
      .filter(([, value]) => !!value),
  )

  return {
    movingLabour,
    stairs,
    specialServices,
    specialItems,
    protectiveMaterial,
  }
}

/**
 * returns the list of charges that are available for a given company branch
 * if a company offer a service or product for free, this will exclude it
 * also, we ignore charges that are not even editable.
 * This is used to filtered out charges that can be added
 */
export const useCompanyApplicableCharges = (date: Date): ChargeDescription[] => {
  const pricing = useCompanyPricing(date)

  return Object.values(ChargeDescription).filter((charge) => {
    switch (charge) {
      case ChargeDescription.movingLabour:
      case ChargeDescription.transportLabour:
      case ChargeDescription.protectiveMaterialProduct:
        return true
      case ChargeDescription.additionalChargesStairs:
        return !!pricing?.stairs
      case ChargeDescription.additionalChargesSpecialServices:
        return Object.keys(pricing?.specialServices ?? {}).length > 0
      case ChargeDescription.additionalChargesSpecialItems:
        return Object.keys(pricing?.specialItems ?? {}).length > 0
      default:
        return false
    }
  })
}

/**
 * extract unit price from the given job
 */
const useGetCurrentJobPrice = () => {
  return (description: ChargeDescription, job: JobForPartner) => {
    return job.quote.details.find(charge => charge.description === description)?.unitPrice?.price
  }
}

export const useGetChargePrice = (job: JobForPartner) => {
  const getCurrentJobPrice = useGetCurrentJobPrice()
  const pricing = useCompanyPricing(new Date(job.date))

  return (description: ChargeDescription): number => {
    const pricingInQuote = getCurrentJobPrice(description, job)

    if (pricingInQuote) {
      return pricingInQuote
    }
    if (!pricing) {
      return 0
    }

    switch (description) {
      case ChargeDescription.movingLabour:
      case ChargeDescription.transportLabour:
      {
        const existingCharge = job.quote.details.find(
          (charge) => charge.description === ChargeDescription.movingLabour,
        )
        if (existingCharge) {
          return existingCharge.unitPrice.price
        }

        const nbMen = job.quote.resolvedNbMen
        if (nbMen === 1 && pricing.movingLabour.movingLabourSingleMan) {
          return pricing.movingLabour.movingLabourSingleMan
        }

        if (pricing.movingLabour.movingLabour[nbMen - 2]) {
          return pricing.movingLabour.movingLabour[nbMen - 2]
        }

        const extraMen = nbMen - (pricing.movingLabour.movingLabour.length + 1)

        return pricing.movingLabour.movingLabour[pricing.movingLabour.movingLabour.length - 1] + (pricing.movingLabour.movingLabourExtraMen * extraMen)
      }
      case ChargeDescription.additionalChargesStairs:
        return (pricing.stairs ?? 0) * job.quote.resolvedNbMen
      default:
        return 0
    }
  }
}

/**
 * when adding charge, get the item value for the given job
 */
export const useGetChargeDefaultItem = (job: JobForPartner) => {
  return (description: ChargeDescription) => {
    const existingItem = job.quote.details.find(charge => charge.description === description)?.item
    if (existingItem) {
      return existingItem
    }
    switch (description) {
      case ChargeDescription.movingLabour:
      case ChargeDescription.transportLabour:
      case ChargeDescription.additionalChargesStairs:
        return String(job.quote.resolvedNbMen)
    }
  }
}

/**
 * extract collection item prices from the quote
 */
const useCurrentJobCollectionItemsPrice = (description: ChargeDescription, job: JobForPartner): Record<string, number> => {
  const charges = job.quote.details
    .filter(charge => !!charge.item && charge.description === description)
  return Object.fromEntries(
    charges.map(charge => ([charge.item, charge.unitPrice.price])),
  )
}

/**
 * returns the available items for the collection of charges
 */
export const useCollectionItemsPrice = (
  description: ChargeDescription,
  job: JobForPartner,
): Record<string, number> => {
  const pricingInQuote = useCurrentJobCollectionItemsPrice(description, job)
  const pricing = useCompanyPricing(new Date(job.date))

  switch (description) {
    case ChargeDescription.additionalChargesSpecialServices:
      return {
        ...pricing?.specialServices,
        ...pricingInQuote,
      }
    case ChargeDescription.additionalChargesSpecialItems:
      return {
        ...pricing?.specialItems,
        ...pricingInQuote,
      }
    case ChargeDescription.protectiveMaterialProduct:
      return {
        ...pricing?.protectiveMaterial,
        ...pricingInQuote,
      }
    default:
      return {}
  }
}
