import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import ColumnsIcon from '@mui/icons-material/ViewColumnOutlined'
import ResetIcon from '@mui/icons-material/AutorenewOutlined'

import Menu, { MenuItem } from '../Menu'
import { useDataGridContext } from './DataGridContext'
import StaticCheckbox from './Table/StaticCheckbox'
import { useDefaultVisibleColumns, useVisibleColumns } from './useToggleColumns'
import { mergeClassName } from '../../utils/mergeClassName'

const ToggleColumnsMenu: React.FC = () => {
  const { t } = useTranslation()
  const { columns, setVisibleColumns, disabled } = useDataGridContext()
  const visibleColumns = useVisibleColumns()
  const defaultColumns = useDefaultVisibleColumns()

  const handleToggle = (event: React.MouseEvent, name: string) => {
    event.preventDefault()
    event.stopPropagation()

    let updatedColumns = [...visibleColumns]
    if (updatedColumns.includes(name)) {
      updatedColumns = updatedColumns.filter(columnName => columnName !== name)
    } else {
      updatedColumns.push(name)
    }
    setVisibleColumns(updatedColumns)
  }

  const handleResetColumns = () => {
    setVisibleColumns(defaultColumns)
  }

  return (
    <Menu
      buttonContent={(
        <div className={mergeClassName(
          'cursor-pointer bg-white p-[10px] text-neutral-500',
          disabled && 'text-neutral-300 cursor-default',
        )}
        >
          <ColumnsIcon />
        </div>
      )}
      disabled={disabled}
    >
      <div className="max-h-[600px] overflow-y-auto">
        { Object.entries(columns).map(([name, column]) => (
          <MenuItem key={name} onClick={(event: React.MouseEvent) => { handleToggle(event, name) }}>
            <div className="flex gap-x-2">
              <StaticCheckbox
                checked={visibleColumns.includes(name)}
                onClick={() => {}}
              />
              { column.label }
            </div>
          </MenuItem>
        )) }
      </div>

      <MenuItem>
        <Button
          startIcon={<ResetIcon />}
          variant="outlined"
          color="secondary"
          size="small"
          fullWidth
          onClick={handleResetColumns}
        >
          { t('actions.reset') }
        </Button>
      </MenuItem>
    </Menu>
  )
}

export default ToggleColumnsMenu
