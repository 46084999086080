import { useMutation } from '@apollo/client'

import * as mutations from './jobs.mutations'
import {
  type CloseJobForPartnerPayload,
  type UpdateNotePayload,
  type UploadNoteAttachmentPayload,
  type DeleteNoteAttachmentPayload,
} from './jobs.type'

/**
 * close a job (for partners)
 */
export const useCloseJobForPartnerAction = () => {
  const [closeJobForPartner, { data, loading, ...mutationProps }] = useMutation(mutations.closeJobForPartner)

  return {
    closeJobForPartner: async (jobId: string, payload: CloseJobForPartnerPayload) => {
      const { data } = await closeJobForPartner({
        variables: {
          jobId,
          payload,
        },
      })

      return data.closeJobForPartner
    },
    data: data?.closeJobForPartner,
    loading,
    ...mutationProps,
  }
}

/**
 * update a note for a job
 */
export const useUpdateJobNoteAction = () => {
  const [updateJobNote, { data, loading, ...mutationProps }] = useMutation(mutations.updateJobNote)

  return {
    updateJobNote: async (jobId: string, payload: UpdateNotePayload) => {
      const { data } = await updateJobNote({
        variables: {
          jobId,
          payload,
        },
      })

      return data.updateJobNote
    },
    data: data?.updateJobNote,
    loading,
    ...mutationProps,
  }
}

/**
 * upload a note attachment for a job
 */
export const useUploadJobNoteAttachmentAction = () => {
  const [uploadJobNoteAttachment, { data, loading, ...mutationProps }] = useMutation(mutations.uploadJobNoteAttachment)

  return {
    uploadJobNoteAttachment: async (jobId: string, payload: UploadNoteAttachmentPayload) => {
      const { data } = await uploadJobNoteAttachment({
        variables: {
          jobId,
          payload,
        },
      })

      return data.uploadJobNoteAttachment
    },
    data: data?.uploadJobNoteAttachment,
    loading,
    ...mutationProps,
  }
}

/**
 * delete a note attachment for a move
 */
export const useDeleteJobNoteAttachmentAction = () => {
  const [deleteJobNoteAttachment, { data, loading, ...mutationProps }] = useMutation(mutations.deleteJobNoteAttachment)

  return {
    deleteJobNoteAttachment: async (jobId: string, payload: DeleteNoteAttachmentPayload) => {
      const { data } = await deleteJobNoteAttachment({
        variables: {
          jobId,
          payload,
        },
      })

      return data.deleteJobNoteAttachment
    },
    data: data?.deleteJobNoteAttachment,
    loading,
    ...mutationProps,
  }
}
