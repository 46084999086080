import { type LightMove } from '../../../modules/adminMoves'
import { useLightMoveState } from '../../../modules/moves'
import { formatDate, formatDateTime } from '../../../utils/date'
import { formatPrice } from '../../../utils/money'
import { capitalizeFirstLetter } from '../../../utils/string'

export const useExportFormatter = () => {
  const getMoveState = useLightMoveState().forMove

  return (move: LightMove, column: string) => {
    const {
      moveDate,
    } = getMoveState(move)

    switch (column) {
      case 'createdAt':
        return formatDateTime(move.createdAt)
      case 'movingDate':
        return formatDate(moveDate)
      case 'user.firstName':
        return formatName(move.user)
      case 'agent':
        return formatName(move.agent)
      case 'nbQuotes':
        return String(move.nbQuotes)
      case 'nbMen':
        return String(move.nbMen)
      case 'nbTrucks':
        return String(move.nbTrucks)
      case 'quoteSelected.companyName':
        return capitalizeFirstLetter(move.quoteSelected?.companyName)
      case 'quoteSelected.subtotal.price':
        return move.quoteSelected?.subtotal ? formatPrice(move.quoteSelected.subtotal) : ''
      case 'activePromoCode.code':
        return move.activePromoCode?.code
      case 'quoteAccepted.date':
        return formatDateTime(move.quoteAccepted?.date)
      case 'moveApprovedByAgent':
        return formatBoolean(move.moveApprovedByAgent)
      case 'depositStatus':
        return move.quoteAccepted ? move.depositStatus : ''
      case 'origin.address.city':
        return `${capitalizeFirstLetter(move.origin.address.city) ?? ''}, ${move.origin.address.region?.toUpperCase() ?? ''} => ${capitalizeFirstLetter(move.destination.address.city) ?? ''}, ${move.destination.address.region?.toUpperCase() ?? ''}`
      case 'platform':
        return capitalizeFirstLetter(move.platform)
      case 'utmSource':
        return move.utmSource
      case 'utmMedium':
        return move.utmMedium
      case 'utmCampaign':
        return move.utmCampaign
      default:
        return ''
    }
  }
}

const formatName = (data?: any) => {
  if (!data?.firstName) {
    return
  }
  return [
    capitalizeFirstLetter(data.firstName),
    capitalizeFirstLetter(data.lastName),
  ].join(' ')
}

const formatBoolean = (data = false) => {
  return data ? '1' : '0'
}
