import { useTranslation } from 'react-i18next'
import DashboardIcon from '@mui/icons-material/SpaceDashboardOutlined'
import CalendarIcon from '@mui/icons-material/CalendarMonthOutlined'
import JobRequestIcon from '@mui/icons-material/MoveToInboxOutlined'
import TruckIcon from '@mui/icons-material/LocalShippingOutlined'
import PricingIcon from '@mui/icons-material/MonetizationOnOutlined'
import RatingIcon from '@mui/icons-material/GradeOutlined'

import MenuItem from './MenuItem'
import Route from '../../../app/Route.enum'
import { getCompanyBranchRouteRegExp, useCurrentCompanyBranch, useOnBehalfState } from '../../../modules/companies'
import { usePendingRequestsNotifications } from '../../../modules/jobRequests'

const PartnerMenuContent: React.FC = () => {
  const { t } = useTranslation()
  const { onBehalf } = useOnBehalfState()
  const { data: currentcompanyBranch } = useCurrentCompanyBranch()
  const pendingRequestsNotifications = usePendingRequestsNotifications()
  const nbPendingRequests = pendingRequestsNotifications?.count ?? 0

  return (
    <>
      <MenuItem
        to={onBehalf ? Route.CompaniesOnBehalf : Route.Dashboard}
        label={t('menu.partner.dashboard')}
        Icon={DashboardIcon}
        exact
      />

      { currentcompanyBranch?.approved && (
        <MenuItem
          to={Route.JobsLandingPage}
          label={t('menu.partner.jobs')}
          Icon={CalendarIcon}
          matchRegex={getCompanyBranchRouteRegExp(Route.Jobs)}
        />
      ) }

      { currentcompanyBranch?.approved && (
        <MenuItem
          to={Route.JobRequestsLandingPage}
          label={t('menu.partner.jobRequests')}
          Icon={JobRequestIcon}
          matchRegex={getCompanyBranchRouteRegExp(Route.JobRequests)}
          notifications={nbPendingRequests}
        />
      ) }

      <MenuItem
        to={Route.BranchSettingsLandingPage}
        label={t('menu.partner.branches')}
        Icon={TruckIcon}
        matchRegex={getCompanyBranchRouteRegExp(Route.BranchSettings)}
      />

      <MenuItem
        to={Route.BranchAvailabilityLandingPage}
        label={t('menu.partner.availability')}
        Icon={PricingIcon}
        matchRegex={getCompanyBranchRouteRegExp(Route.BranchAvailability)}
      />

      <MenuItem
        to={Route.RatingsLandingPage}
        label={t('menu.partner.ratings')}
        Icon={RatingIcon}
        matchRegex={getCompanyBranchRouteRegExp(Route.Ratings)}
      />
    </>
  )
}

export default PartnerMenuContent
