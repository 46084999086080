import { useMutation } from '@apollo/client'

import { type UpdateUserAdminPayload } from './adminUsers.types'
import * as mutations from './adminUsers.mutations'

/**
 * update current user profile
 */
export const useUpdateUserAction = () => {
  const [updateUser, { data, loading, ...mutationProps }] = useMutation(mutations.updateUser)

  return {
    updateUser: async (userId: string, payload: UpdateUserAdminPayload) => {
      const { data } = await updateUser({
        variables: {
          userId,
          payload,
        },
      })

      return data.admin_updateUser
    },
    data: data?.admin_updateUser,
    loading,
    ...mutationProps,
  }
}

/**
 * delete multiple users
 */
export const useDeleteUsersAction = () => {
  const [deleteUsers, { data, loading, ...mutationProps }] = useMutation(mutations.deleteUsers)

  return {
    deleteUsers: async (ids: string[]) => {
      const { data } = await deleteUsers({
        variables: {
          ids,
        },
      })

      return data.admin_deleteUsers
    },
    data: data?.admin_deleteUsers,
    loading,
    ...mutationProps,
  }
}

/**
 * restore multiple users
 */
export const useRestoreUsersAction = () => {
  const [restoreUsers, { data, loading, ...mutationProps }] = useMutation(mutations.restoreUsers)

  return {
    restoreUsers: async (ids: string[]) => {
      const { data } = await restoreUsers({
        variables: {
          ids,
        },
      })

      return data.admin_restoreUsers
    },
    data: data?.admin_restoreUsers,
    loading,
    ...mutationProps,
  }
}
