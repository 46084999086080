import MobileHeader, { type MobileHeaderProps } from '../layout/MobileHeader/MobileHeader'
import { useCurrentCompanyBranch } from '../../modules/companies'

const CompanyBranchMobileHeader: React.FC<MobileHeaderProps> = ({
  backlinkTo,
  ...mobileHeaderProps
}) => {
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()
  const progress = currentCompanyBranch?.setupProgress ?? 0

  const shouldShowBacklink = progress > 0

  return (
    <MobileHeader
      {...mobileHeaderProps}
      backlinkTo={shouldShowBacklink ? backlinkTo : undefined}
    />
  )
}

export default CompanyBranchMobileHeader
