import { gql } from '@apollo/client'

export const companyRatingFields = `
id
createdAt
customerName
overallRating
review
ownerResponse
`
export const getCompanyBranchRatings = gql`
query GetCompanyBranchRatings($companyBranchId: String!, $query: PaginatedQuery) {
  getCompanyBranchRatings(companyBranchId: $companyBranchId, query: $query) {
    count
    hasMore
    data {
      ${companyRatingFields}
    }
  }
}
`

export const getCompanyBranchRatingsForPartner = gql`
query GetCompanyBranchRatingsForPartner($companyBranchId: String!, $query: PaginatedQuery) {
  getCompanyBranchRatingsForPartner(companyBranchId: $companyBranchId, query: $query) {
    count
    hasMore
    data {
      ${companyRatingFields}
      jobId
    }
  }
}
`
