import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useThrottleCallback } from '@react-hook/throttle'
import { useWatch } from 'react-hook-form'

import { type FullMove } from '../../../../modules/adminMoves'
import { type PromoCode } from '../../../../modules/adminPromoCodes'
import { InvoiceCategory, useCalculateInvoiceAction } from '../../../../modules/invoices'
import { type Currency } from '../../../../common/amount'
import Paper from '../../../Paper'
import Spinner from '../../../Spinner'
import { type ChargeFormValue } from '../ChargesFormContent'
import { formatFormOutput } from './formDataBuilder'
import { Summary } from '../../../Charges'
import Tip from './Tip'
import AutoCapture from './AutoCapture'

type SidebarProps = {
  move: FullMove
  category: InvoiceCategory
  currency: Currency
  promoCode?: PromoCode
}

const Sidebar: React.FC<SidebarProps> = ({
  move,
  category,
  currency,
  promoCode,
}) => {
  const { t } = useTranslation()
  const isMainMoveInvoice = category === InvoiceCategory.move
  const charges = (useWatch({ name: 'charges' }) ?? []) as ChargeFormValue[]
  const tip = useWatch({ name: 'tip' })
  const { calculateInvoice, loading, data: invoiceEstimate } = useCalculateInvoiceAction()
  const [throttling, setThrottling] = useState(false)

  const onSubtotalUpdate = useThrottleCallback(() => {
    const formValues = formatFormOutput({
      charges,
      tip,
    }, currency)

    calculateInvoice(move.id, {
      details: formValues.details ?? [],
      promoCode,
      tip: formValues.tip,
    })
      .catch(console.error)
      .finally(() => { setThrottling(false) })
  }, 1, false)

  useEffect(() => {
    setThrottling(true)
    onSubtotalUpdate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charges, tip])

  return (
    <div className="relative">
      { (loading || throttling || !invoiceEstimate) && (
        <div className="absolute inset-0 flex items-center justify-center bg-white/50">
          <Spinner />
        </div>
      ) }

      <Paper>
        <h2 className="mb-8 font-body2 text-2xl font-semibold text-slate-700">
          { t('forms.invoice.sidebar.summary.title') }
        </h2>

        { invoiceEstimate && (
          <Summary
            invoiceEstimate={invoiceEstimate}
          />
        ) }
      </Paper>

      { isMainMoveInvoice && invoiceEstimate && (
        <Tip invoiceEstimate={invoiceEstimate} />
      ) }

      { invoiceEstimate && (
        <AutoCapture invoiceEstimate={invoiceEstimate} />
      ) }
    </div>
  )
}

export default Sidebar
