import { useTranslation } from 'react-i18next'
import TruckIcon from '@mui/icons-material/LocalShippingOutlined'

import { type JobForPartner } from '../../../modules/jobs'
import { getMoveDate } from '../../../modules/moves'
import Paper from '../../../components/Paper'
import JobDetails from '../../../components/move/JobDetails'

type JobProps = {
  job: JobForPartner
}

const Job: React.FC<JobProps> = ({
  job,
}) => {
  const { t } = useTranslation()

  return (
    <Paper className="!mb-0 rounded-tl-none">
      <h2 className="mb-4 font-body2 text-2xl font-semibold text-slate-700">
        <TruckIcon />
        { ' ' }
        { t('pages.companyBranch.job.sections.move') }
      </h2>

      <JobDetails
        move={job.move}
        date={getMoveDate(job.date, job.move.originTimezone)}
        time={job.time}
      />
    </Paper>
  )
}

export default Job
