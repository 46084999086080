import { gql } from '@apollo/client'
import { companyRatingFields } from './companyRatings.queries'

export const updateRatingResponse = gql`
mutation UpdateRatingResponse($companyRatingId: String!, $payload: CompanyRatingResponseDto!) {
  updateRatingResponse(companyRatingId: $companyRatingId, payload: $payload) {
    ${companyRatingFields}
  }
}
`
