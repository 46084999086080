import { useTranslation } from 'react-i18next'
import CompanyIcon from '@mui/icons-material/BusinessOutlined'
import { addMilliseconds } from 'date-fns/addMilliseconds'

import { useConfig } from '../../../../../modules/config'
import { type FullMove } from '../../../../../modules/adminMoves'
import { useMoveState } from '../../../../../modules/moves'
import { useDateFormatter } from '../../../../../utils/date'
import { capitalizeFirstLetter } from '../../../../../utils/string'
import Paper from '../../../../../components/Paper'
import { Cell, Label, Row, Value } from '../../../../../components/cell'
import TimeAgo from '../../../../../components/TimeAgo'
import { JobRequestStatusTag } from '../../../../../components/jobRequest'
import MoveActionButton from '../../MoveActionButton'
import { ActionName } from '../../useActions'

type JobRequestBoxProps = {
  move: FullMove
}

const JobRequestBox: React.FC<JobRequestBoxProps> = ({
  move,
}) => {
  const { t } = useTranslation()
  const formatDate = useDateFormatter()
  const {
    selectedCompanyName,
    canCancelJobRequest,
    isApprovedByAgent,
  } = useMoveState().forMove(move)

  const jobRequestTtl = useConfig<number>('jobRequests.ttl')

  const hasJobs = !!move.jobs?.length

  if (!selectedCompanyName || !move.latestJobRequest || hasJobs || !isApprovedByAgent) {
    return null
  }

  const expireAt = addMilliseconds(new Date(move.latestJobRequest.createdAt), jobRequestTtl)
  const agentName = [
    capitalizeFirstLetter(move.latestJobRequest.createdBy.firstName),
    capitalizeFirstLetter(move.latestJobRequest.createdBy.lastName),
  ].join(' ')

  return (
    <Paper>
      <div className="flex justify-between">
        <h2 className="mb-4 font-body2 text-2xl font-semibold text-slate-700">
          <CompanyIcon />
          { ' ' }
          { t('pages.admin.move.details.jobRequest.title', { companyName: selectedCompanyName }) }
        </h2>

        { canCancelJobRequest && (
          <div>
            <MoveActionButton
              move={move}
              actionName={ActionName.CancelJobRequest}
              overrideAction={{ label: t('actions.cancel') }}
            />
          </div>
        ) }
      </div>

      <div className="pb-4">
        <Row>
          <Cell>
            <Label>
              { t('pages.admin.move.details.jobRequest.submitted') }
            </Label>
            <Value>
              { formatDate(move.latestJobRequest.createdAt) }
              <span className="inline-block pl-2 text-sm text-neutral-400">
                (<TimeAgo date={move.latestJobRequest.createdAt} />)
              </span>
            </Value>
          </Cell>

          <Cell>
            <Label>
              { t('pages.admin.move.details.jobRequest.expire') }
            </Label>
            <Value>
              { formatDate(expireAt) }
              <span className="inline-block pl-2 text-sm text-neutral-400">
                (<TimeAgo date={expireAt} />)
              </span>
            </Value>
          </Cell>

          <Cell>
            <Label>
              { t('pages.admin.move.details.jobRequest.approvedBy') }
            </Label>
            <Value>
              { agentName }
            </Value>
          </Cell>

          <Cell>
            <Label>
              { t('pages.admin.move.details.jobRequest.status') }
            </Label>
            <Value>
              <JobRequestStatusTag
                jobRequestStatus={move.latestJobRequest.status}
              />
            </Value>
          </Cell>
        </Row>

      </div>
    </Paper>
  )
}

export default JobRequestBox
