import { useMutation } from '@apollo/client'

import * as mutations from './jobRequests.mutations'
import {
  type AcceptJobRequestPayload,
  type DeclineJobRequestPayload,
} from './jobRequests.types'

/**
 * accept a job request
 */
export const useAcceptJobRequestAction = () => {
  const [acceptJobRequest, { data, loading, ...mutationProps }] = useMutation(mutations.acceptJobRequest)

  return {
    acceptJobRequest: async (jobRequestId: string, payload: AcceptJobRequestPayload) => {
      const { data } = await acceptJobRequest({
        variables: {
          jobRequestId,
          payload,
        },
      })

      return data.acceptJobRequest
    },
    data: data?.acceptJobRequest,
    loading,
    ...mutationProps,
  }
}

/**
 * decline a job request
 */
export const useDeclineJobRequestAction = () => {
  const [declineJobRequest, { data, loading, ...mutationProps }] = useMutation(mutations.declineJobRequest)

  return {
    declineJobRequest: async (jobRequestId: string, payload: DeclineJobRequestPayload) => {
      const { data } = await declineJobRequest({
        variables: {
          jobRequestId,
          payload,
        },
      })

      return data.declineJobRequest
    },
    data: data?.declineJobRequest,
    loading,
    ...mutationProps,
  }
}
