import { gql } from '@apollo/client'

import { fullCompanyBranchFields, fullCompanyFields } from './adminCompanies.queries'

export const deleteCompanyBranches = gql`
mutation Admin_deleteCompanyBranches($ids: [String!]!) {
  admin_deleteCompanyBranches(ids: $ids) {
    ${fullCompanyBranchFields}
  }
}
`

export const restoreCompanyBranches = gql`
mutation Admin_restoreCompanyBranches($ids: [String!]!) {
  admin_restoreCompanyBranches(ids: $ids) {
    ${fullCompanyBranchFields}
  }
}
`

export const approveCompanyBranches = gql`
mutation Admin_approveCompanyBranches($ids: [String!]!) {
  admin_approveCompanyBranches(ids: $ids) {
    ${fullCompanyBranchFields}
  }
}
`

export const unapproveCompanyBranches = gql`
mutation Admin_unapproveCompanyBranches($ids: [String!]!) {
  admin_unapproveCompanyBranches(ids: $ids) {
    ${fullCompanyBranchFields}
  }
}
`

export const pauseCompanyBranches = gql`
mutation Admin_paauseCompanyBranches($ids: [String!]!) {
  admin_pauseCompanyBranches(ids: $ids) {
    ${fullCompanyBranchFields}
  }
}
`

export const resumeCompanyBranches = gql`
mutation Admin_resumeCompanyBranches($ids: [String!]!) {
  admin_resumeCompanyBranches(ids: $ids) {
    ${fullCompanyBranchFields}
  }
}
`

export const getInvitationUserId = gql`
mutation Admin_getInvitationUserId($payload: GetInvitationUserIdDto!) {
  admin_getInvitationUserId(payload: $payload)
}
`

export const invitePartner = gql`
mutation Admin_invitePartner($companyId: String!, $partnerInfo: InvitePartnerDto!) {
  admin_invitePartner(companyId: $companyId, partnerInfo: $partnerInfo) {
    ${fullCompanyFields}
  }
}
`

export const cancelPartnerInvitation = gql`
mutation Admin_cancelPartnerInvitation($invitationId: String!) {
  admin_cancelPartnerInvitation(invitationId: $invitationId) {
    ${fullCompanyFields}
  }
}
`

export const dissociatePartner = gql`
mutation Admin_dissociatePartner($companyId: String!) {
  admin_dissociatePartner(companyId: $companyId) {
    ${fullCompanyFields}
  }
}
`
