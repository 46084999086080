import { useTranslation, Trans } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'

import { type FullInvoiceEstimate } from '../../../../../modules/invoices'
import { type JobForPartner } from '../../../../../modules/jobs'
import { roundPrice } from '../../../../../utils/money'
import { type Amount } from '../../../../../common/amount'
import Charges, { Summary } from '../../../../Charges'
import Paper from '../../../../Paper'
import { Form, SingleCheckbox, SubmitButton } from '../../../../form'
import Price from '../../../../Price'
import useValidationSchema from './validationSchema'

const PriceDiff = ({ amount }: { amount: Amount }) => (
  <strong>
    { amount.price > 0 && '+' }
    <Price amount={amount} />
  </strong>
)

type PreviewConfirmationFormProps = {
  invoicePreview: FullInvoiceEstimate
  job: JobForPartner
  onSubmit: () => Promise<void> | void
  onCancel: () => Promise<void> | void
}

const PreviewConfirmationForm: React.FC<PreviewConfirmationFormProps> = ({
  invoicePreview,
  job,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation()
  const schema = useValidationSchema()
  const form = useForm({
    resolver: yupResolver(schema),
  })

  const priceDiff = {
    price: roundPrice(invoicePreview.subtotal.price - job.quote.subtotal.price),
    currency: invoicePreview.subtotal.currency,
  }

  const getPriceDiffText = () => {
    if (priceDiff.price === 0) {
      return t('forms.companyBranch.closeJob.previewConfirmationForm.priceDiff.equal')
    }
    if (priceDiff.price < 0) {
      return (
        <Trans i18nKey="forms.companyBranch.closeJob.previewConfirmationForm.priceDiff.cheaper">
          <PriceDiff amount={priceDiff} />
        </Trans>
      )
    }
    return (
      <Trans i18nKey="forms.companyBranch.closeJob.previewConfirmationForm.priceDiff.moreExpensive">
        <PriceDiff amount={priceDiff} />
      </Trans>
    )
  }

  return (
    <Form
      form={form}
      onSubmit={onSubmit}
    >
      <h1 className="mb-8 border-b pb-2 text-center font-body2 text-[1.6rem] leading-7 lg:pb-1 lg:text-left">
        <span className="block text-base lg:inline lg:text-[1.6rem]">
          { t('forms.companyBranch.closeJob.title.step', { step: 2, totalSteps: 2 }) }
          { ' : ' }
        </span>
        <span className="font-bold">
          { t('forms.companyBranch.closeJob.title.step2') }
        </span>
      </h1>

      <p className="mb-1 text-neutral-600">
        { t('forms.companyBranch.closeJob.previewConfirmationForm.intro') }
      </p>

      <p className="mb-10 text-neutral-600">
        <Trans i18nKey="forms.companyBranch.closeJob.previewConfirmationForm.doNotSubmitIfNotOk">
          <strong />
        </Trans>
      </p>

      <div className="mt-8 flex justify-center">
        <div className="lg:w-[800px]">

          <Paper>
            <table className="w-full">
              <tbody>

                <Charges
                  charges={invoicePreview.details}
                />
              </tbody>
            </table>

            <div className="flex justify-end">
              <div className="shrink-0 pt-8 lg:w-[450px]">
                <Summary
                  invoiceEstimate={invoicePreview}
                />
              </div>
            </div>

            <div className="pt-8">
              <Alert
                severity={priceDiff.price > 0 ? 'info' : 'success'}
              >
                { getPriceDiffText() }
              </Alert>
            </div>
          </Paper>

        </div>
      </div>

      <div className="my-8 rounded-md border bg-amber-50/80 px-4 py-8 md:px-8">
        <SingleCheckbox
          name="confirmation"
          label={(
            <div>
              { t('forms.companyBranch.closeJob.previewConfirmationForm.confirmation.title') }
              <ul className="mt-4 list-disc text-neutral-600 [&_>li]:ml-4 [&_>li]:md:ml-8">
                <li>
                  { t('forms.companyBranch.closeJob.previewConfirmationForm.confirmation.actualValues') }
                </li>
                <li>
                  { t('forms.companyBranch.closeJob.previewConfirmationForm.confirmation.chargesDiscussedWithCustomer') }
                </li>
                <li>
                  { t('forms.companyBranch.closeJob.previewConfirmationForm.confirmation.customerWillBeCharged') }
                </li>
              </ul>
            </div>
          )}
        />
      </div>

      <div className="flex justify-between">
        <Button
          variant="contained"
          color="secondary"
          onClick={onCancel}
        >
          { t('actions.edit') }
        </Button>

        <SubmitButton>
          { t('actions.chargeCustomer') }
        </SubmitButton>
      </div>

    </Form>
  )
}

export default PreviewConfirmationForm
