import { useLazyData } from '../../graphQl'
import {
  type QuotesRequestVariables,
  type QuotesRequestPayload,
  type QuotesResponse,
} from './quotes.types'
import * as queries from './quotes.queries'

/**
 * get quotes
 */
export const useQuotes = () => {
  const { fetch: fetchQuotes, ...data } = useLazyData<QuotesResponse, QuotesRequestVariables>(
    queries.getDetailedQuotes,
    { reload: true },
  )
  return {
    fetchQuotes: async (quotesRequest: QuotesRequestPayload) => {
      return await fetchQuotes({ quotesRequest })
    },
    ...data,
  }
}
