import { gql } from '@apollo/client'

import { moveFields } from '../../adminMoves/core/adminMoves.queries'
import { invoiceEstimateFields } from './invoices.queries'

export const calculateInvoice = gql`
mutation CalculateInvoice($moveId: String!, $payload: CalculateInvoiceDto!) {
  calculateInvoice(moveId: $moveId, payload: $payload) {
    ${invoiceEstimateFields}
  }
}
`

export const createMoveInvoice = gql`
mutation Admin_createMoveInvoice($moveId: String!, $payload: CreateInvoiceDto!, $autoCapture: Boolean) {
  admin_createMoveInvoice(moveId: $moveId, payload: $payload, autoCapture: $autoCapture) {
    move {
      ${moveFields}
    }
    paymentCaptured
    paymentError
  }
}
`

export const voidMoveInvoice = gql`
mutation Admin_voidMoveInvoice($invoiceId: String!, $moveId: String!) {
  admin_voidMoveInvoice(invoiceId: $invoiceId, moveId: $moveId) {
    ${moveFields}
  }
}
`
