import { v4 as uuid } from 'uuid'

import { type PricingRule } from '../../../../modules/companies'
import { type PricingRuleFormValues } from './PricingRuleForm.type'
import { formatFormOutput as formatRuleOutput, ruleTemporalityToFormData } from '../TemporalityForm/formDataBuilder'

export const pricingRulesToFormData = (pricingRule: PricingRule): PricingRuleFormValues => {
  return {
    id: pricingRule.id,
    temporality: ruleTemporalityToFormData(pricingRule.temporality),
    movingLabour: pricingRule.movingLabour.filter(v => !!v).map(v => String(v)),
    movingLabourExtraMen: String(pricingRule.movingLabourExtraMen),
    movingLabourSingleMan: String(pricingRule.movingLabourSingleMan ?? ''),
    packingLabour: pricingRule.packingLabour?.map(v => String(v)),
    packingLabourExtraMen: String(pricingRule.packingLabourExtraMen ?? ''),
    packingLabourSingleMan: String(pricingRule.packingLabourSingleMan ?? ''),
  }
}

export const formatFormOutput = (formData: PricingRuleFormValues): Omit<PricingRule, 'priority'> => {
  return {
    id: formData.id ?? uuid(),
    temporality: formatRuleOutput(formData.temporality),
    movingLabour: formData.movingLabour.filter(v => !!v).map(v => parseInt(v)),
    movingLabourExtraMen: parseInt(formData.movingLabourExtraMen),
    movingLabourSingleMan: formData.movingLabourSingleMan ? parseInt(formData.movingLabourSingleMan) : undefined,
    packingLabour: formData.packingLabour?.filter(v => !!v).map(v => parseInt(v)),
    packingLabourExtraMen: formData.packingLabourExtraMen ? parseInt(formData.packingLabourExtraMen) : undefined,
    packingLabourSingleMan: formData.packingLabourSingleMan ? parseInt(formData.packingLabourSingleMan) : undefined,
  }
}
