import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import DeleteIcon from '@mui/icons-material/HighlightOffOutlined'

type RowProps = {
  title: React.ReactNode
  subtitle?: React.ReactNode
  header?: React.ReactNode
  onDelete?: () => void
  children?: React.ReactNode
}

const Row: React.FC<RowProps> = ({
  title,
  subtitle,
  header,
  onDelete,
  children,
}) => {
  const { t } = useTranslation()

  return (
    <div className="overflow-hidden rounded-lg border bg-gray-50/50 shadow-sm">
      <div className="flex items-center">
        <div className="flex grow flex-col items-center justify-between gap-x-4 px-2 py-4 sm:pl-8 lg:flex-row lg:pr-2">
          <div className="flex flex-col text-center lg:text-left">
            <div className="mb-2 text-xl lg:mb-0 lg:text-lg">
              { title }
              { onDelete && (
                <DeleteIcon
                  className="box-content cursor-pointer pb-1 pl-2 !text-[24px] text-red-700 sm:!hidden"
                  onClick={onDelete}
                />
              ) }
            </div>
            { subtitle && (
              <div className="text-base text-neutral-400 lg:text-sm">
                { subtitle }
              </div>
            ) }
          </div>

          <div className="flex shrink-0 flex-col items-center gap-x-6 py-4 lg:flex-row lg:gap-x-4">
            { header }
          </div>
        </div>

        <div className="hidden shrink-0 sm:flex">
          <div className="w-[70px] px-3 text-center">
            { onDelete && (
              <DeleteIcon
                className="cursor-pointer !text-[24px] text-red-700"
                onClick={onDelete}
              />
            ) }
          </div>
        </div>
      </div>

      { children && (
        <div className="px-4 pb-6 md:px-8">
          { children }
        </div>
      ) }
    </div>
  )
}

export default Row
