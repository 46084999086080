import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

const useValidationSchema = () => {
  const { t } = useTranslation()

  return Yup.object({
    confirmation: Yup.boolean().oneOf([true], t('formValidation.required')).required(),
  })
}

export default useValidationSchema
