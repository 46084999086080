import Route from '../../../app/Route.enum'
import { Modal } from '../../../components/Modal'
import { useNavigate } from '../../../modules/navigation'

type CompanyRatingModalProps = {
  children?: React.ReactNode
}

const CompanyRatingModal: React.FC<CompanyRatingModalProps> = ({ children }) => {
  const { navigate } = useNavigate()

  const onClose = () => {
    navigate(Route.CompaniesRatings)
  }

  return (
    <Modal
      open
      onClose={onClose}
      className="lg:max-w-[800px]"
    >
      { children }
    </Modal>
  )
}

export default CompanyRatingModal
