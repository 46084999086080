import { gql } from '@apollo/client'

import { fullCompanyRatingsField } from './adminCompanyRatings.queries'

export const updateCompanyBranchRatingApprobation = gql`
mutation Mutation($companyBranchRatingId: String!, $approved: Boolean!) {
  admin_updateCompanyBranchRatingApprobation(companyBranchRatingId: $companyBranchRatingId, approved: $approved) {
    ${fullCompanyRatingsField}
  }
}
`
