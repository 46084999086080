import { useState } from 'react'
import { useWatch } from 'react-hook-form'
import DeleteIcon from '@mui/icons-material/HighlightOffOutlined'

import { type ChargeDescription } from '../../../../../../modules/invoices'
import { type JobForPartner } from '../../../../../../modules/jobs'
import { IntegerInput, PriceInput } from '../../../../../form'
import Price from '../../../../../Price'
import { type AddChargeOptions, type ChargeRow } from '../../CloseJobForm.types'
import { useCollectionChargeState, useGetChargeState } from '../../hooks'
import Row from '../Row'
import AddItemDropdown from './AddItemDropdown'

type CollectionRowProps = {
  index: number
  job: JobForPartner
  charges: Array<[number, ChargeRow]>
  onAdd: (description: ChargeDescription, options: AddChargeOptions) => void
  onDelete?: (index: number) => void
}

const CollectionRow: React.FC<CollectionRowProps> = ({
  index,
  job,
  charges: rawCharges,
  onAdd,
  onDelete,
}) => {
  const namePrefix = 'charges'
  const name = `${namePrefix}.${index}`
  const values = useWatch({ name })
  const allFormValues = useWatch({ name: namePrefix }) as ChargeRow[]
  const collectionFormValues = allFormValues
    .filter(formValue => formValue.categoryAndDescription === values.categoryAndDescription)
  const charges = rawCharges.filter(i => !!i[1].item)

  const [autoFocus, setAutoFocus] = useState(false)

  const {
    description,
    chargeTitle,
    availableItemsToAdd,
    itemsPrice,
    quantityEditable,
    subtotal,
  } = useCollectionChargeState(job, values, collectionFormValues)
  const getChargeState = useGetChargeState()

  const handleAddItem = (item: string) => {
    setAutoFocus(true)
    onAdd(description, {
      item,
      price: String(itemsPrice[item] ?? 0),
    })
  }

  return (
    <Row
      title={chargeTitle}
      header={(
        <div className="text-right">
          <div className="text-xl font-bold text-neutral-700 lg:w-[120px]">
            { subtotal ? <Price amount={subtotal} /> : '—' }
          </div>
        </div>
      )}
    >

      <div className="border-t-2 border-dashed border-zinc-300 md:border-t-0">

        { charges.map(([index, charge]) => {
          const {
            unitPrice,
            individualItemTitle,
            quantityEditable,
            unitPriceEditable,
            unitPriceDisabled,
          } = getChargeState(charge, job)

          return (
            <div
              key={charge.item}
              className="my-2 mb-4 flex flex-col-reverse items-center gap-x-4 border-b-2 border-dashed border-zinc-300 pb-4 md:flex-row md:border-0 md:pb-0"
            >
              <div className="flex items-center gap-x-4">
                { quantityEditable
                  ? (
                    <IntegerInput
                      name={`${namePrefix}.${index}.quantity`}
                      min={0}
                      max={99}
                    />
                    )
                  : charge.quantity }

                <div className="md:w-[120px]">
                  { unitPriceEditable
                    ? (
                      <PriceInput
                        name={`${namePrefix}.${index}.unitPrice`}
                        disabled={unitPriceDisabled}
                        autoFocus={autoFocus && !unitPriceDisabled}
                      />
                      )
                    : (
                      <div className="text-neutral-500">
                        { unitPrice && (
                          <>
                            @ <Price amount={unitPrice} compact />
                          </>
                        ) }
                      </div>
                      ) }
                </div>
              </div>

              <div className="items-center gap-x-4 pb-4 pt-2 text-center md:flex md:p-0 md:text-left">
                { individualItemTitle }

                { quantityEditable && onDelete && (
                  <DeleteIcon
                    className="box-content cursor-pointer pl-2 !text-[24px] text-red-700"
                    onClick={() => { onDelete(index) }}
                  />
                ) }
              </div>
            </div>
          )
        }) }

        { quantityEditable && !!availableItemsToAdd?.length && (
          <div className="mt-4">
            <AddItemDropdown
              description={description}
              items={availableItemsToAdd ?? []}
              onSelect={handleAddItem}
            />
          </div>
        ) }
      </div>
    </Row>
  )
}

export default CollectionRow
