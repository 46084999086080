import * as Yup from 'yup'

import { type PaymentFormValues } from './PaymentForm.type'
import { useFloatValidator } from '../../../../utils/number'

const useValidationSchema = () => {
  const floatValidator = useFloatValidator()

  return Yup.object({
    externalSource: Yup.string().required(),
    amount: floatValidator({ required: true, min: 0.01, max: 999999 }),
  }) as Yup.ObjectSchema<PaymentFormValues, PaymentFormValues>
}

export default useValidationSchema
