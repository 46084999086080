import { useTranslation } from 'react-i18next'

import { mergeClassName } from '../../utils/mergeClassName'
import { type Source } from './Rating.types'
import RatingSourceLogo from './RatingSourceLogo'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export type RatingProps = {
  rating: number
  nbRatings?: number
  source?: Source
  small?: boolean
} & ReactDiv

const Rating: React.FC<RatingProps> = ({
  rating,
  nbRatings,
  source,
  small = false,
  className,
  onClick,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <div
      className={mergeClassName(
        'inline-flex items-center rounded-2xl border p-1 shadow',
        small && 'p-[0.15rem_0_0.15rem_0.2rem] shadow-sm',
        className,
      )}
      onClick={onClick}
      {...props}
    >
      <RatingSourceLogo
        source={source}
        className={mergeClassName(
          small && 'size-[20px]',
        )}
      />

      <div className={mergeClassName(
        'mx-2 flex items-center gap-x-1',
        small && 'mx-[0.35rem]',
      )}
      >
        <span className={mergeClassName(
          'font-body2 font-bold text-neutral-800',
          small && 'text-sm',
        )}
        >
          { rating }
        </span>
        <span className={mergeClassName(
          'text-sm text-neutral-500',
          small && 'text-xs',
          onClick && 'underline cursor-pointer',
        )}
        >
          ({ t('components.rating.nbRatings', { count: nbRatings }) })
        </span>
      </div>
    </div>
  )
}

export default Rating
