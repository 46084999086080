import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  type CompanyBranch,
  type CompanyBranchCommunication,
  useOnBehalfState,
  SendRatingEmails,
} from '../../../../modules/companies'
import { Form, Input, Label, SingleCheckbox, RadioGroup } from '../../../form'
import useValidationSchema from './validationSchema'
import Section from '../../../Section'
import Paper from '../../../Paper/Paper'
import { CompanyBranchCommunicationToFormData, formatFormOutput } from './formDataBuilder'
import SubmitButton from '../SubmitButton'
import { type CommunicationFormValues } from './CommunicationForm.type'

type CommunicationFormFormProps = {
  onSubmit: (payload: CompanyBranchCommunication) => Promise<any>
  companyBranch?: CompanyBranch
}

const CommunicationForm: React.FC<CommunicationFormFormProps> = ({
  onSubmit,
  companyBranch,
}) => {
  const { t } = useTranslation()
  const schema = useValidationSchema()
  const form = useForm<CommunicationFormValues>({
    resolver: yupResolver(schema),
    defaultValues: CompanyBranchCommunicationToFormData(companyBranch?.communication),
    mode: 'onTouched',
  })
  const { onBehalf } = useOnBehalfState()

  const sendRatingEmailsOptions = Object.values(SendRatingEmails).map(option => ({
    label: t(`forms.companyBranch.communication.ratingEmail.options.${option}`),
    value: option,
  }))

  const formatBeforeSubmit = async (values: CommunicationFormValues) => await onSubmit(formatFormOutput(values))

  return (
    <Form form={form} onSubmit={formatBeforeSubmit}>
      <Section>
        { t('forms.companyBranch.communication.bookingEmail.title') }
      </Section>
      <Paper className="bg-zinc-50">

        <SingleCheckbox
          name="sendBookingEmailsToPrimaryContact"
          label={onBehalf
            ? t('forms.companyBranch.communication.sendCopyToPrimaryContact')
            : t('forms.companyBranch.communication.sendMeACopy')}
        />

        <div className="mb-4">
          <Label>
            { t('forms.companyBranch.communication.ccLabel') }
          </Label>
        </div>
        <Input name="bookingEmailCC" size="small" />
        <small>
          <em>
            { t('forms.companyBranch.communication.ccBottomNote') }
          </em>
        </small>
      </Paper>

      <Section>
        { t('forms.companyBranch.communication.ratingEmail.title') }
      </Section>
      <Paper className="bg-zinc-50">

        { t('forms.companyBranch.communication.ratingEmail.label') }
        <div className="ml-4 mt-4">
          <RadioGroup
            name="sendRatingEmails"
            options={sendRatingEmailsOptions}
          />
        </div>

        <hr className="my-4" />

        <SingleCheckbox
          name="sendRatingEmailsToPrimaryContact"
          label={onBehalf
            ? t('forms.companyBranch.communication.sendCopyToPrimaryContact')
            : t('forms.companyBranch.communication.sendMeACopy')}
        />

        <div className="mb-4">
          <Label>
            { t('forms.companyBranch.communication.ccLabel') }
          </Label>
        </div>
        <Input name="ratingEmailCC" size="small" />
        <small>
          <em>
            { t('forms.companyBranch.communication.ccBottomNote') }
          </em>
        </small>
      </Paper>

      <SubmitButton companyBranch={companyBranch} />
    </Form>
  )
}

export default CommunicationForm
