import Link, { type LinkProps } from '../../Link/Link'
import { Tooltip } from '../../Tooltip'
import { useLocation, usePathGenerator } from '../../../modules/navigation'
import { mergeClassName } from '../../../utils/mergeClassName'

type MenuItemProps = {
  Icon: React.FC<{ className?: string }>
  label?: string
  exact?: boolean
  matchRegex?: RegExp
  adminStyle?: boolean
  notifications?: number
} & LinkProps

const MenuItem: React.FC<MenuItemProps> = ({
  Icon,
  label,
  exact = false,
  matchRegex,
  adminStyle = false,
  notifications,
  to,
  params,
  ...linkProps
}) => {
  const { pathname } = useLocation()
  const generatePath = usePathGenerator()
  const generatedPath = generatePath(to, params)

  if (notifications && notifications >= 100) {
    notifications = 99
  }

  const isCurrent = () => {
    if (matchRegex) {
      return matchRegex.test(pathname)
    }
    if (exact) {
      return pathname === generatedPath
    }
    return pathname.startsWith(generatedPath)
  }

  const current = isCurrent()

  return (
    <Link
      to={generatedPath}
      className={mergeClassName(
        'flex justify-center align-middle text-slate-500 hover:bg-stone-100',
        current && 'bg-zinc-200 hover:bg-zinc-300',
        adminStyle && 'text-white hover:bg-zinc-800 hover:text-white',
        (current && adminStyle) && 'text-white bg-black hover:hover:bg-zinc-800',
      )}
      {...linkProps}
    >
      <Tooltip
        tooltip={label}
        position="right"
        offset={15}
        delayDuration={0}
        openOnClick={false}
        arrow
      >
        <div className="relative flex justify-center px-4 py-5 align-middle">
          { !!notifications && (
            <div className={mergeClassName(
              'absolute right-0 top-[3px] flex h-[18px] w-[18px] items-center justify-center rounded-full bg-primary text-[12px] text-white',
              notifications >= 10 && 'text-[10px]',
            )}
            >
              { notifications }
            </div>
          ) }
          <Icon className="w-8" />
        </div>
      </Tooltip>
    </Link>
  )
}

export default MenuItem
