import { type Charge } from '../../invoices'
import { type PaginatedQuery } from '../../graphQl'
import { type QuotesRequestPayload } from '../../adminQuotes'
import { type PhoneNumber } from '../../../common/phoneNumber'
import { type KeyValueMap } from '../../../common/keyValueMap'
import { type ResidenceType, type Room } from '../../quotes'

export type MovesPaginatedQuery = {
  query?: PaginatedQuery
  showPastMoves?: boolean
  customersFilter?: CustomersFilterValue
  assignedFilter?: AssignedFilterValue
  dateRangeFilter?: DateRangeFilterValue
}

export enum CustomersFilterValue {
  All = 'all',
  AgentRequired = 'agentRequired',
  QuoteSelected = 'quoteSelected',
  QuoteAccepted = 'quoteAccepted',
}

export enum AssignedFilterValue {
  All = 'all',
  OnlyMine = 'onlyMine',
  OnlyMineOrUnassigned = 'onlyMineOrUnassigned',
}

export enum DepositStatus {
  pending = 'pending',
  paid = 'paid',
}

export enum NoteScope {
  toCustomer = 'toCustomer',
  toPartner = 'toPartner',
  internal = 'internal',
}

export type DateRangeFilterValue = {
  from: Date
  to?: Date
}

export type MoveCustomerPayload = {
  email: string
  firstName: string
  lastName: string
  phoneNumber: PhoneNumber
  language?: string
}

export type MoveDetailsPayload<TNumber = number> = {
  residenceType: ResidenceType
  residenceRooms?: KeyValueMap<Room, number>
  nbBoxes?: TNumber
  furnitureRatio?: TNumber
  volumeCubicFeet?: TNumber
  weightPounds?: TNumber
}

export type MovePayload<TNumber = number> = {
  user: MoveCustomerPayload
} & QuotesRequestPayload<TNumber> & MoveDetailsPayload<TNumber> & MoveUtmPayload

export type MoveUtmPayload = {
  utmSource?: string | null
  utmMedium?: string | null
  utmCampaign?: string | null
}

export type DetailedQuoteRequest = QuotesRequestPayload & MoveDetailsPayload

export type QuotePayload = {
  details?: Array<Omit<Charge, 'subtotal'>>
}

export type SendQuoteByEmailPayload = {
  moveId: string
  payload: {
    note?: string | null
  }
}

export type ApproveMovePayload = {
  note?: string
  shareNoteFromCustomer?: boolean
}

export type UpdateNotePayload = {
  note?: string | null
  scope: NoteScope
}

export type UploadNoteAttachmentPayload = {
  file: File
  scope: NoteScope
}

export type DeleteNoteAttachmentPayload = {
  fileId: string
  scope: NoteScope
}

export type ApplyPromoCodePayload = {
  moveId: string
  promoCode: string | null
}

export type SelectBranchPayload = {
  moveId: string
  branchId: string
}

export type EditQuotePayload = {
  moveId: string
  payload: QuotePayload
}
