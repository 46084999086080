import { useTranslation } from 'react-i18next'
import Chip from '@mui/material/Chip'
import PromoCodeIcon from '@mui/icons-material/LocalOffer'

import { mergeClassName } from '../../../utils/mergeClassName'
import { Currency } from '../../../common/amount'
import Price from '../../../components/Price'
import { type PromoCode } from './promoCodes.models'
import { PromoCodeType } from './promoCodes.enums'

type PromoCodeOptions = {
  standalone?: boolean
  currency?: Currency
}

/**
 * returns a tag with the label of the promo code
 */
export const usePromoCodeLabel = () => {
  const { t } = useTranslation()

  // eslint-disable-next-line react/display-name
  return (promoCode?: PromoCode, {
    standalone = false,
    currency = Currency.CAD,
  }: PromoCodeOptions = {}) => {
    const render = () => {
      if (!promoCode) {
        return null
      }
      return (
        <div className={mergeClassName(
          'flex items-center gap-2 font-body2 text-base font-bold',
          !standalone && 'mx-1',
        )}
        >
          { promoCode.type === PromoCodeType.Amount && (
            <Price
              amount={{ price: promoCode.value, currency }}
              showDecimals={false}
              compact
            />
          ) }
          { promoCode.type === PromoCodeType.Percentage && (
            <>
              { promoCode.value }%
            </>
          ) }
          { ' ' }
          <span className={mergeClassName(
            'text-xs',
            !standalone && 'text-gray-300',
          )}
          >
            { t('promoCode.off') }
          </span>
        </div>
      )
    }

    if (!promoCode) {
      return null
    }
    if (standalone) {
      return render()
    }
    return (
      <Chip
        color="success"
        icon={<PromoCodeIcon className="!ml-3 !text-[14px]" />}
        label={render()}
      />
    )
  }
}
