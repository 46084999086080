import { useTranslation } from 'react-i18next'
import QuoteIcon from '@mui/icons-material/RequestQuoteOutlined'

import { useCurrentCompany, useCurrentCompanyBranch } from '../../../modules/companies'
import { type JobRequestForPartner } from '../../../modules/jobRequests'
import { convertPartnerQuoteToFullQuote } from '../../../modules/quotes'
import Paper from '../../../components/Paper'
import { Quote } from '../../../components/quotes'
import ServerError from '../../../components/errors/ServerError'

type EstimateProps = {
  jobRequest: JobRequestForPartner
}

const Estimate: React.FC<EstimateProps> = ({
  jobRequest,
}) => {
  const { t } = useTranslation()
  const { data: currentCompany } = useCurrentCompany()
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()

  if (!currentCompanyBranch || !currentCompany) {
    return <ServerError error={new Error()} />
  }

  const fullCompany = {
    ...currentCompanyBranch,
    company: currentCompany,
  }

  return (
    <Paper className="child:p-0 child:lg:p-0">
      <div className="p-4 lg:p-8 lg:pb-4">
        <h2 className="font-body2 text-2xl font-semibold text-slate-700">
          <QuoteIcon />
          { ' ' }
          { t('pages.companyBranch.jobRequest.sections.estimate') }
        </h2>
      </div>
      <Quote
        quote={convertPartnerQuoteToFullQuote(jobRequest.quote, fullCompany)}
        promoCode={jobRequest.move.promoCode}
        presentationMode
        className="scale-1 hover:scale-1 border-none shadow-none hover:shadow-none"
      />
    </Paper>
  )
}

export default Estimate
