import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import RemoveIcon from '@mui/icons-material/HighlightOffOutlined'

import { ApprovedStatusFilterValue } from '../../../modules/adminCompanyRatings'
import { mergeClassName } from '../../../utils/mergeClassName'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from '../../../components/DropDownMenu'
import { type CustomFiltersProps } from '../../../components/dataGrid'

export const APPROVED_STATUS_FILTER = 'approvedStatusFilter'

/**
 * custom filters to render on top of the DataGrid component
 */
const CustomFilters: React.FC<CustomFiltersProps> = ({
  values,
  setValues,
}) => {
  const { t } = useTranslation()

  const approvedStatusFilter: ApprovedStatusFilterValue[] = values[APPROVED_STATUS_FILTER] ?? []
  const [approvedStatusFilterDisplay, setApprovedStatusFilterDisplay] = useState<ApprovedStatusFilterValue[]>(approvedStatusFilter)
  const hasFilterChanges = JSON.stringify(approvedStatusFilter) !== JSON.stringify(approvedStatusFilterDisplay)
  const nbStatusFilterSelected = approvedStatusFilter.length

  /**
   * handle status filter toggle
   */
  const handleApprovedStatusFilterClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    filter: ApprovedStatusFilterValue,
  ) => {
    event.preventDefault()
    event.stopPropagation()
    const isPresent = approvedStatusFilterDisplay.includes(filter)
    if (isPresent) {
      setApprovedStatusFilterDisplay(approvedStatusFilterDisplay.filter(value => value !== filter))
      return
    }
    setApprovedStatusFilterDisplay([...approvedStatusFilterDisplay, filter])
  }

  /**
   * persist filter changes
   * make sure to reset the filter if all options were selected
   */
  const handleApplyFilterChanges = () => {
    const allSelected = approvedStatusFilterDisplay.length === Object.keys(ApprovedStatusFilterValue).length
    setValues({
      ...values,
      [APPROVED_STATUS_FILTER]: allSelected ? [] : approvedStatusFilterDisplay,
    })
    if (allSelected) {
      setApprovedStatusFilterDisplay([])
    }
  }

  /**
   * reset all filters
   */
  const handleResetFilters = () => {
    setApprovedStatusFilterDisplay([])
    setValues({})
  }

  return (
    <div className="hidden items-center gap-4 md:flex">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>

          <Button
            variant="outlined"
            color="secondary"
            endIcon={<KeyboardArrowDownIcon />}
            className="!normal-case"
            disableFocusRipple
          >
            <span className={mergeClassName(
              nbStatusFilterSelected > 0 && 'font-bold',
            )}
            >
              { t('pages.admin.companiesBranchesRatings.table.filters.statusFilter.title', {
                count: nbStatusFilterSelected,
              }) }
            </span>
          </Button>

        </DropdownMenuTrigger>
        <DropdownMenuContent align="start" sideOffset={8}>

          { Object.values(ApprovedStatusFilterValue).map((filter) => {
            const checked = approvedStatusFilterDisplay.includes(filter)
            return (
              <DropdownMenuCheckboxItem
                key={filter}
                checked={checked}
                onClick={event => { handleApprovedStatusFilterClick(event, filter) }}
              >
                { t(`pages.admin.companiesBranchesRatings.table.filters.statusFilter.options.${filter}`) }
              </DropdownMenuCheckboxItem>
            )
          }) }

          { hasFilterChanges && (
            <DropdownMenuItem>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleApplyFilterChanges}
                fullWidth
                className="!normal-case"
              >
                { t('actions.apply') }
              </Button>
            </DropdownMenuItem>
          ) }

          <DropdownMenuSeparator />

          <DropdownMenuItem
            onClick={handleResetFilters}
            className="text-red-700 child:text-red-700"
            disabled={nbStatusFilterSelected === 0}
          >
            <RemoveIcon className="mr-2 !text-[18px]" />
            { t('components.dataGrid.resetFilters') }
          </DropdownMenuItem>

        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

export default CustomFilters
