import { gql } from '@apollo/client'

import { moveFields } from '../../adminMoves/core/adminMoves.queries'

export const createPayment = gql`
mutation Admin_createPayment($moveId: String!, $payload: CreatePaymentDto!) {
  admin_createPayment(moveId: $moveId, payload: $payload) {
    ${moveFields}
  }
}
`

export const deletePayment = gql`
mutation Admin_deletePayment($moveId: String!, $paymentId: String!) {
  admin_deletePayment(moveId: $moveId, paymentId: $paymentId) {
    ${moveFields}
  }
}
`
