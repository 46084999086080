import { useTranslatedValue } from '../../../../common/translation'
import {
  type PublicCompanyBranch,
  useCurrentCompany,
  useCurrentCompanyBranch,
} from '..'

/**
 * returns company branch name (if not defined, we use the parent company name)
 */
export const useCompanyBranchName = (companyBranch?: Pick<PublicCompanyBranch, 'identification' | 'company'>) => {
  const getTranslation = useTranslatedValue()

  return getTranslation(companyBranch?.identification?.name) ??
  getTranslation(companyBranch?.company?.identification.name)
}

/**
 * returns currently selected company branch name (if not defined, we use the parent company name)
 */
export const useCurrentCompanyBranchName = () => {
  const { data: currentBranch } = useCurrentCompanyBranch()
  const { data: currentCompany } = useCurrentCompany()

  return useCompanyBranchName(currentBranch && currentCompany
    ? { ...currentBranch, company: currentCompany }
    : undefined)
}
