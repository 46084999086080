import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import AddIcon from '@mui/icons-material/AddCircleOutline'

import { type ChargeDescription } from '../../../../../modules/invoices'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from '../../../../DropDownMenu'

type AddChargeDropdownProps = {
  options: ChargeDescription[]
  onSelect: (charge: ChargeDescription) => void
}

const AddChargeDropdown: React.FC<AddChargeDropdownProps> = ({
  options,
  onSelect,
}) => {
  const { t } = useTranslation()

  if (options.length === 0) {
    return null
  }
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          className="!normal-case"
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          endIcon={<KeyboardArrowDownIcon />}
          disableFocusRipple
        >
          { t('actions.addCharge') }
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="start" sideOffset={8}>

        { options.map((option) => {
          return (
            <DropdownMenuItem
              key={option}
              className="my-2 cursor-pointer px-4"
              onClick={() => { onSelect(option) }}
            >
              { t(`quotes.pricing.description.${option}`) }
            </DropdownMenuItem>
          )
        }) }

      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default AddChargeDropdown
