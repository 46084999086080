import { RuleTemporalityType } from '../../../../modules/companies'

export const useDefaultTemporalityValues = (selectedDates?: { start: Date, end?: Date }) => {
  if (!selectedDates) {
    return {
      type: RuleTemporalityType.Date,
    }
  }
  if (selectedDates.end) {
    return {
      type: RuleTemporalityType.DateRange,
      from: selectedDates.start,
      to: selectedDates.end,
    }
  }
  return {
    type: RuleTemporalityType.Date,
    date: selectedDates.start,
  }
}
