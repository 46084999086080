import {
  type DataOptions,
  usePaginatedData,
  useData,
} from '../../graphQl'
import {
  type JobRequestForPartner,
  type LightJobRequest,
} from './jobRequests.models'
import * as queries from './jobRequests.queries'
import {
  useCurrentCompanyBranch,
} from '../../companies'
import {
  type JobRequestsPaginatedVariables,
  type JobRequestsPaginatedQuery,
} from './jobRequests.types'

/**
 * returns all job requests for a given company branch
 * query optimized for data table
 */
export const useJobRequests = (companyBranchId?: string) => {
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()

  const { fetch: fetchJobRequests, ...data } = usePaginatedData<LightJobRequest, JobRequestsPaginatedVariables>(
    queries.getJobRequests,
  )

  return {
    fetchJobRequests: async (query: JobRequestsPaginatedQuery) => {
      return await fetchJobRequests({
        companyBranchId: companyBranchId ?? currentCompanyBranch?.id,
        ...query,
      })
    },
    ...data,
  }
}

/**
 * get a job request by id
 */
export const useJobRequest = (jobRequestId?: string, dataOptions?: DataOptions) => {
  return useData<JobRequestForPartner>(queries.getJobRequest, {
    ...dataOptions,
    skip: !jobRequestId,
    variables: { jobRequestId },
  })
}
