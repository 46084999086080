import { type Currency } from '../../../../common/amount'
import { type PaymentPayload } from '../../../../modules/payment'
import { type PaymentFormValues } from './PaymentForm.type'

export const formatFormOutput = (
  data: PaymentFormValues,
  currency: Currency,
): PaymentPayload => {
  return {
    ...data,
    amount: {
      price: parseFloat(data.amount),
      currency,
    },
  }
}
