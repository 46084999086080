import { type Amount } from '../../../common/amount'
import { type ExternalSource } from './payment.enums'

export enum PaymentGatewayName {
  stripe = 'stripe',
}

export enum PaymentStatus {
  succeeded = 'succeeded',
  pending = 'pending',
  failed = 'failed',
  refunded = 'refunded',
  partiallyRefunded = 'partiallyRefunded',
}

export enum DisputeStatus {
  pending = 'pending',
  actionNeeded = 'actionNeeded',
  closed = 'closed',
  won = 'won',
  lost = 'lost',
}

export type PaymentPayload = {
  paymentDate: Date
  externalSource: ExternalSource
  amount: Amount
}
