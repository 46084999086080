import { type Amount } from '../../../common/amount'
import { type Quote } from '../../quotes'
import { type PromoCode } from './promoCodes.models'
import { applyPromoCode } from './promoCodes.utils'

/**
 * apply a promo code to a quotes and returns the rebate
 * returns undefined if the rebate is zero
 */
export const usePromoCodeRebate = (quote?: Quote, promoCode?: PromoCode): Amount | undefined => {
  const updatedSubtotal = useQuoteSubtotalWithPromoCode(quote, promoCode)
  if (!quote || !updatedSubtotal) {
    return
  }
  const rebate = quote.subtotal.price - updatedSubtotal.price

  if (rebate === 0) {
    return
  }

  return {
    ...quote.subtotal,
    price: rebate * -1,
  }
}

/**
 * lazy function to calculate promo code on a given price
 */
export const useGetSubtotalWithPromoCode = () => {
  return (amount: Amount, promoCode?: PromoCode) => {
    if (!promoCode) {
      return amount
    }
    return applyPromoCode(amount, promoCode)
  }
}

/**
 * apply a promo code to a quote and returns the new subtotal
 */
export const useQuoteSubtotalWithPromoCode = (quote?: Quote, promoCode?: PromoCode): Amount | undefined => {
  if (!quote) {
    return
  }
  if (!promoCode) {
    return quote.subtotal
  }
  return applyPromoCode(quote.subtotal, promoCode)
}
