import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'

import { type PricingRule } from '../../../../modules/companies'
import { Form, Label, SubmitButton } from '../../../form'
import {
  ModalHeader,
  ModalTitle,
  ModalDescription,
  ModalFooter,
} from '../../../Modal'
import TemporalityForm from '../TemporalityForm'
import { useDefaultTemporalityValues } from '../TemporalityForm/useDefaultTemporalityValues'
import { AdditionalService, useCurrentCompanyBranch } from '../../../../modules/companies'
import Section from '../../../Section'
import PricingOverrideByMen from './PricingOverrideByMen'
import { type PricingRuleFormValues } from './PricingRuleForm.type'
import { pricingRulesToFormData, formatFormOutput } from './formDataBuilder'
import useValidationSchema from './validationSchema'

type PricingRuleFormProps = {
  onClose: () => void
  onSubmit: (payload: Omit<PricingRule, 'priority'>) => Promise<any>
  data?: PricingRule
  selectedDates?: { start: Date, end?: Date }
}

const PricingRuleForm: React.FC<PricingRuleFormProps> = ({
  onClose,
  onSubmit,
  data,
  selectedDates,
}) => {
  const { t } = useTranslation()
  const { data: companyBranch } = useCurrentCompanyBranch()
  const hasPackingService = companyBranch?.services?.additionalServices?.includes(AdditionalService.Packing) ?? false
  const nbRulesMoving = companyBranch?.labour?.movingLabour?.length ?? 1
  const supportMovingLabourSingleMan = !!companyBranch?.labour?.movingLabourSingleMan?.basePrice
  const nbRulesPacking = companyBranch?.labour?.packingLabour?.length ?? 1
  const supportPackingLabourSingleMan = !!companyBranch?.labour?.packingLabourSingleMan?.basePrice

  const schema = useValidationSchema({ hasPackingService })
  const temporality = useDefaultTemporalityValues(selectedDates)

  const editing = !!data

  const defaultValues = { temporality }

  const form = useForm<PricingRuleFormValues>({
    resolver: yupResolver(schema),
    defaultValues: data ? pricingRulesToFormData(data) : defaultValues,
  })

  const formatBeforeSubmit = async (values: PricingRuleFormValues) => await onSubmit(formatFormOutput(values))

  return (
    <div className="w-full">
      <Form form={form} onSubmit={formatBeforeSubmit}>
        <ModalHeader>
          <ModalTitle>
            { editing
              ? t('forms.companyBranch.availability.pricingRule.editTitle')
              : t('forms.companyBranch.availability.pricingRule.createTitle') }
          </ModalTitle>
        </ModalHeader>
        <ModalDescription className="my-4 max-h-[calc(100dvh-165px)] px-0">

          <div className="-mb-px ml-4 mt-2 inline-block rounded-t border-x border-t bg-zinc-50 px-3 pt-1">
            <Label label={t('forms.companyBranch.availability.temporality.title')} />
          </div>

          <div className="mb-5 border-y bg-zinc-50 p-6 px-4 text-gray-600 lg:px-6">
            <TemporalityForm
              prefixName="temporality"
            />
          </div>

          <div className="px-3 md:px-4 lg:px-6">

            <Section>
              { t('forms.companyBranch.labour.movingLabour') }
            </Section>

            <PricingOverrideByMen
              name="movingLabour"
              nbRules={nbRulesMoving}
              supportSingleMan={supportMovingLabourSingleMan}
            />

            { hasPackingService && (
              <>
                <Section>
                  { t('forms.companyBranch.labour.packingLabour') }
                </Section>

                <PricingOverrideByMen
                  name="packingLabour"
                  nbRules={nbRulesPacking}
                  supportSingleMan={supportPackingLabourSingleMan}
                />
              </>
            ) }

          </div>

        </ModalDescription>
        <ModalFooter>
          <Button variant="contained" color="secondary" onClick={onClose}>
            { t('actions.cancel') }
          </Button>
          <SubmitButton>
            { editing
              ? t('actions.update')
              : t('forms.companyBranch.availability.pricingRule.createTitle') }
          </SubmitButton>
        </ModalFooter>
      </Form>
    </div>
  )
}

export default PricingRuleForm
