import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import InvoiceIcon from '@mui/icons-material/RequestQuoteOutlined'
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined'

import { type FullMove } from '../../../../../modules/adminMoves'
import { useMoveState } from '../../../../../modules/moves'
import Paper from '../../../../../components/Paper'
import InvoicesTable from './InvoicesTable'
import CreateInvoiceModal from './CreateInvoiceModal'

type InvoicesBoxProps = {
  move: FullMove
}

const InvoicesBox: React.FC<InvoicesBoxProps> = ({
  move,
}) => {
  const { t } = useTranslation()
  const {
    canCreateInvoice,
  } = useMoveState(move)
  const [creatingInvoice, setCreatingInvoice] = useState(false)

  return (
    <>
      <CreateInvoiceModal
        move={move}
        open={creatingInvoice}
        onClose={() => { setCreatingInvoice(false) }}
      />
      <Paper>
        <div className="flex justify-between gap-x-4">

          <h2 className="mb-4 flex items-center gap-x-2 font-body2 text-2xl font-semibold text-slate-700 ">
            <InvoiceIcon />
            <div>
              { t('pages.admin.move.accounting.invoices.title') }
            </div>
          </h2>

          <div>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => { setCreatingInvoice(true) }}
              className="!normal-case"
              disabled={!canCreateInvoice}
            >
              { t('actions.createInvoiceOrCredit') }
            </Button>
          </div>
        </div>

        <div className="mb-6">
          <InvoicesTable move={move} />
        </div>
      </Paper>
    </>
  )
}

export default InvoicesBox
