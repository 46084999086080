import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import EditIcon from '@mui/icons-material/Edit'

import { useCurrentCompany, useCurrentCompanyBranch, useCurrentCompanyBranchName } from '../../modules/companies'
import PageTitle from '../layout/PageTitle/PageTitle'
import { CompanyBranchProgress } from '.'
import { useNavigate } from '../../modules/navigation'
import Route from '../../app/Route.enum'
import { mergeClassName } from '../../utils/mergeClassName'

const CompanyBranchTopContent: React.FC = () => {
  const { t } = useTranslation()
  const { data: currentCompany } = useCurrentCompany()
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()
  const currentBranchName = useCurrentCompanyBranchName()
  const { navigate } = useNavigate()

  const showProgress = currentCompanyBranch?.setupProgress !== 100

  return (
    <>
      <PageTitle className={mergeClassName(
        showProgress && 'lg:mb-0',
      )}
      >
        <div className="flex flex-col items-center gap-4 lg:flex-row">
          { currentCompany?.logo?.en && (
            <img src={currentCompany?.logo?.en} className="max-h-[50px] lg:max-h-none lg:max-w-[75px]" alt="" />
          ) }
          { currentBranchName }
          { ' ' }
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            startIcon={<EditIcon />}
            onClick={() => { navigate(`${Route.EditCompany}?from=settings`) }}
          >
            { t('actions.edit') }
          </Button>
        </div>
      </PageTitle>
      <CompanyBranchProgress />
    </>
  )
}

export default CompanyBranchTopContent
