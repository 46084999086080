import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'

import { type ExternalFile } from '../../common/file/file.type'
import { Modal } from '../Modal'
import { useMemo } from 'react'

type ImagesCarouselProps = {
  open: boolean
  onClose: () => void
  files: ExternalFile[]
  selectedFile?: string
}

const ImagesCarousel: React.FC<ImagesCarouselProps> = ({
  open,
  onClose,
  files,
  selectedFile,
}) => {
  const selectedIndex = useMemo(() => {
    return files?.findIndex(file => file.id === selectedFile) ?? 0
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile])

  const images = files.map((file) => ({
    original: file.url,
  }))

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="lg:max-w-[1500px]"
    >
      <div className="my-[-24px]">
        <ImageGallery
          items={images}
          showPlayButton={false}
          startIndex={selectedIndex}
        />
      </div>
    </Modal>
  )
}

export default ImagesCarousel
