import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import UserIcon from '@mui/icons-material/Person'
import PendingInvitationIcon from '@mui/icons-material/PersonAdd'
import DissociateIcon from '@mui/icons-material/PersonOff'

import { formatPhoneNumber } from '../../../../common/phoneNumber'
import Link, { LinkVariant } from '../../../../components/Link'
import Paper from '../../../../components/Paper'
import { Cell, Label, Row, Value } from '../../../../components/cell'
import ProfileAvatar from '../../../../components/users/ProfileAvatar/ProfileAvatar'
import {
  type FullCompanyBranch,
  useCancelPartnerInvitationAction,
  useDissociatePartnerAction,
  useInvitePartnerAction,
} from '../../../../modules/adminCompanies'
import { capitalizeFirstLetter } from '../../../../utils/string'
import UserStatus from '../../user/UserStatus'
import Title from './Title'
import Route from '../../../../app/Route.enum'
import { Permission, usePermission } from '../../../../modules/users'
import { type AdminUser } from '../../../../modules/adminUsers'
import CompanyInvitationForm from '../../../../components/forms/admin/CompanyInvitationForm'
import { useErrorFormatter } from '../../../../components/errors/useErrorFormatter'
import { errorNotification, successNotification } from '../../../../components/ToastNotifications'
import ConfirmationModal from '../../../../components/ConfirmationModal'
import { type InvitationFormOutputPayload } from '../../../../components/forms/admin/CompanyInvitationForm/CompanyInvitationForm.type'

const EMPTY_PLACEHOLDER = '—'

type PrimaryContactTabProps = {
  companyBranch: FullCompanyBranch
}

const PrimaryContactTab: React.FC<PrimaryContactTabProps> = ({
  companyBranch,
}) => {
  const { t } = useTranslation()
  const canReadUsers = usePermission(Permission.canReadUsers)
  const canManageCompanies = usePermission(Permission.canManageCompanies)
  const [invitePartner] = useInvitePartnerAction()
  const [cancelPartnerInvitation] = useCancelPartnerInvitationAction()
  const [dissociatePartner] = useDissociatePartnerAction()
  const formatError = useErrorFormatter()

  const shouldShowCancelInvitationOption = canManageCompanies && !!companyBranch.company.pendingInvitation
  const shouldShowDissociatePartnerOption = canManageCompanies && !!companyBranch.company.primaryContact
  const shouldShowOptions = shouldShowDissociatePartnerOption || shouldShowCancelInvitationOption

  const handleInvitePartner = async (partnerInfo: InvitationFormOutputPayload) => {
    try {
      const result = await invitePartner(
        {
          companyId: companyBranch.company.id,
          partnerInfo,
        },
      )
      if (result?.primaryContact) {
        successNotification(t('pages.admin.companies.actions.invitePartner.userAddedSuccess'))
      } else {
        successNotification(t('pages.admin.companies.actions.invitePartner.userInvitedSuccess'))
      }
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const handleCancelPartnerInvitation = async () => {
    try {
      if (!companyBranch.company.pendingInvitation?.id) {
        return
      }
      await cancelPartnerInvitation({ invitationId: companyBranch.company.pendingInvitation?.id })
      successNotification(t('pages.admin.companies.actions.cancelPartnerInvitation.success'))
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const handleDissociatePartner = async () => {
    try {
      await dissociatePartner({ companyId: companyBranch.company.id })
      successNotification(t('pages.admin.companies.actions.dissociatePartner.success'))
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const renderHeader = (user: AdminUser) => {
    const identification = (
      <div className="flex items-center gap-4 rounded-t-md bg-neutral-100 p-3">
        <ProfileAvatar user={user} size={50} />
        <div>
          <div className="text-lg leading-tight">
            { capitalizeFirstLetter(user.firstName) }
            { ' ' }
            { capitalizeFirstLetter(user.lastName) }
          </div>
          <div className="text-sm text-neutral-500/80">{ user.position }</div>
        </div>
      </div>
    )
    return canReadUsers
      ? (
        <Link to={Route.AdminUser} params={{ userId: user.id }}>{ identification }</Link>
        )
      : identification
  }

  return (
    <div className="px-3">

      { shouldShowOptions && (
        <div className="mb-2 flex flex-wrap gap-3">

          { shouldShowCancelInvitationOption && (
            <ConfirmationModal
              danger
              onConfirm={handleCancelPartnerInvitation}
              question={t('pages.admin.companies.actions.cancelPartnerInvitation.confirmation')}
            >
              <Button
                variant="contained"
                color="error"
                size="small"
                startIcon={<DissociateIcon />}
              >
                { t('actions.cancelInvitation') }
              </Button>
            </ConfirmationModal>
          ) }

          { shouldShowDissociatePartnerOption && (
            <ConfirmationModal
              danger
              onConfirm={handleDissociatePartner}
              question={t('pages.admin.companies.actions.dissociatePartner.confirmation')}
            >
              <Button
                variant="contained"
                color="error"
                size="small"
                startIcon={<DissociateIcon />}
              >
                { t('actions.dissociatePartner') }
              </Button>
            </ConfirmationModal>
          ) }
        </div>
      ) }

      { companyBranch.company.primaryContact &&
        (
          <>
            <Title>
              <UserIcon /> { t('pages.admin.companies.branchPanel.primaryContact.title') }
            </Title>

            <Paper className="child:p-0 child:lg:p-0">

              { renderHeader(companyBranch.company.primaryContact) }

              <div className="p-4 pt-2 lg:p-8 lg:pt-4">

                <Row>
                  <Cell>
                    <Label>
                      { t('forms.fields.email.label') }
                    </Label>
                    <Value>
                      <Link
                        to={`mailto:${companyBranch.company.primaryContact.email}`}
                        variant={LinkVariant.Underlined}
                      >
                        { companyBranch.company.primaryContact.email }
                      </Link>
                    </Value>
                  </Cell>

                  <Cell>
                    <Label>
                      { t('forms.fields.phoneNumber.label') }
                    </Label>
                    <Value>
                      { formatPhoneNumber(companyBranch.company.primaryContact.phoneNumber) }
                    </Value>
                  </Cell>
                </Row>
                <hr className="mb-3 mt-5" />
                <UserStatus user={companyBranch.company.primaryContact} full={false} />
              </div>
            </Paper>
          </>
        ) }

      { companyBranch.company.pendingInvitation && (
        <>
          <Title>
            <PendingInvitationIcon />
            { ' ' }
            { t('pages.admin.companies.branchPanel.primaryContact.pendingInvitationTitle') }
          </Title>
          <Paper>
            <Row>
              <Cell>
                <Label>
                  { t('forms.fields.email.label') }
                </Label>
                <Value>
                  { companyBranch.company.pendingInvitation.email }
                </Value>
              </Cell>
            </Row>
            <Row>
              <Cell>
                <Label>
                  { t('forms.fields.firstName.label') }
                </Label>
                <Value>
                  { companyBranch.company.pendingInvitation.firstName ?? EMPTY_PLACEHOLDER }
                </Value>
              </Cell>
              <Cell>
                <Label>
                  { t('forms.fields.lastName.label') }
                </Label>
                <Value>
                  { companyBranch.company.pendingInvitation.lastName ?? EMPTY_PLACEHOLDER }
                </Value>
              </Cell>
            </Row>
          </Paper>
        </>
      ) }

      {
          !companyBranch.company.primaryContact && !companyBranch.company.pendingInvitation && (
            <>
              <div className="mb-3">
                <Alert severity="info">
                  { t('pages.admin.companies.branchPanel.primaryContact.noPrimaryContact') }
                </Alert>
              </div>
              { canManageCompanies && (
                <CompanyInvitationForm
                  company={companyBranch.company}
                  onSubmit={handleInvitePartner}
                />
              ) }
            </>
          )
         }
    </div>
  )
}

export default PrimaryContactTab
