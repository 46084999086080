import { useMemo, useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

import { usePermission, useRoles, useSuperAdmin } from '../../../../modules/users'
import { type AdminUser } from '../../../../modules/adminUsers'
import { CheckboxGroup } from '../../../form'
import { isIncluded, getRolesOptions } from './utils'

const RolesContainer = styled(Box)(({ theme }) => ({
  padding: '0.5rem 0',
  '& label': {
    alignItems: 'flex-start',
    '& svg': {
      marginTop: -7,
    },
  },
}))

type RolesFieldProps = {
  name: string
  user: AdminUser
}

const RolesField: React.FC<RolesFieldProps> = ({ name, user }) => {
  const roles = useRoles()
  const form = useFormContext()
  const currentRoles: string[] | undefined = useWatch({ name: 'roles' })

  const rolesOptions = useMemo(() => getRolesOptions(roles.data, currentRoles), [roles.data, currentRoles])

  /* when selecting role, unselect children roles */
  useEffect(() => {
    if (!roles?.data?.length || !currentRoles?.length) {
      return
    }
    const simplified = currentRoles.filter(currentRole => {
      const role = roles.data?.find(({ key }) => key === currentRole)
      if (!role) {
        return false
      }
      return !isIncluded(roles.data ?? [], role, currentRoles)
    })

    if (JSON.stringify(simplified) !== JSON.stringify(currentRoles)) {
      form.setValue(name, simplified)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoles])

  return (
    <RolesContainer>
      <CheckboxGroup
        name={name}
        options={rolesOptions}
      />
    </RolesContainer>
  )
}

export default RolesField
