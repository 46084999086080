/* eslint-disable @typescript-eslint/no-misused-promises */

import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import WarningIcon from '@mui/icons-material/WarningAmber'

import { mergeClassName } from '../../utils/mergeClassName'
import {
  Modal,
  ModalDescription,
  ModalHeader,
  ModalTitle,
  ModalFooter,
} from '../Modal'
import { type Render } from './ConfirmationModal.types'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

type ConfirmationModalProps = Omit<ReactDiv, 'onClick' | 'title'> & {
  children?: React.ReactNode
  open?: boolean
  title?: React.ReactNode
  question?: React.ReactNode
  render?: Render
  danger?: boolean
  disabled?: boolean
  onConfirm?: (values?: any) => void | Promise<void>
  onDecline?: () => void | Promise<void>
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  children,
  open = false,
  title,
  question,
  render,
  danger,
  disabled = false,
  onConfirm,
  onDecline,
  ...props
}) => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(open)

  useEffect(() => {
    setModalOpen(open)
  }, [open])

  const onClick = () => {
    setModalOpen(true)
  }

  const handleConfirm = async () => {
    await onConfirm?.()
    setModalOpen(false)
  }

  const handleDecline = async () => {
    await onDecline?.()
    setModalOpen(false)
  }

  const handleCustomResponse = async (values: any) => {
    await onConfirm?.(values)
    setModalOpen(false)
  }

  return (
    <>
      <Modal
        open={modalOpen}
        showCloseIcon={false}
        className={mergeClassName(
          'lg:max-w-[500px]',
          render && 'lg:max-w-[800px]',
        )}
      >

        <div className="flex w-full">
          { danger && (
            <div className="-mr-2 -mt-1 ml-5">
              <WarningIcon className="shrink-0 !text-[30px] text-red-800" />
            </div>
          ) }
          <div className="grid w-full gap-4">

            <ModalHeader>
              <ModalTitle
                className={mergeClassName(
                  danger && 'text-red-800',
                )}
              >
                { title ?? t('components.confirmationModal.title') }
              </ModalTitle>
            </ModalHeader>

            { render
              ? (
                  render({
                    onConfirm: handleCustomResponse,
                    onDecline: handleDecline,
                  })
                )
              : (
                <>
                  <ModalDescription>
                    <div className="text-base">
                      { question }
                    </div>
                  </ModalDescription>
                  <ModalFooter>
                    <div>
                      <Button
                        variant="outlined"
                        color="secondary"
                        size="medium"
                        onClick={handleDecline}
                      >
                        { t('actions.cancel') }
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleConfirm}
                      >
                        { t('actions.confirm') }
                      </Button>
                    </div>
                  </ModalFooter>
                </>
                ) }
          </div>
        </div>
      </Modal>
      { children && (
        <div
          {...props}
          onClick={disabled ? undefined : onClick}
        >
          { children }
        </div>
      ) }
    </>
  )
}

export default ConfirmationModal
