import { removeTypeNameFromObject } from '../../../utils/graphql'
import { type AvailabilityRule, type PricingRule, type RuleTemporality } from './availabilityAndPricing/availabilityAndPricing.models'
import { type Company, type CompanyBranch } from './companies.models'

const formatRuleTemporality = (data?: any): RuleTemporality => {
  if (data.date) {
    data.date = new Date(data.date)
  }
  if (data.from) {
    data.from = new Date(data.from)
  }
  if (data.to) {
    data.to = new Date(data.to)
  }
  return data
}

const formatAvailabilityRule = (data?: any): AvailabilityRule => {
  if (data.temporality) {
    data.temporality = formatRuleTemporality(data.temporality)
  }
  return data
}

const formatPricingRule = (data?: any): PricingRule => {
  if (data.temporality) {
    data.temporality = formatRuleTemporality(data.temporality)
  }
  return data
}

const formatCompanyBranch = (data?: any): CompanyBranch => {
  if (data?.legal?.dateOfIncorporation) {
    data.legal.dateOfIncorporation = new Date(data.legal.dateOfIncorporation)
  }
  if (data?.availabilityRules?.length) {
    data.availabilityRules = data.availabilityRules.map(formatAvailabilityRule)
  }
  if (data?.pricingRules?.length) {
    data.pricingRules = data.pricingRules.map(formatPricingRule)
  }
  return data
}

export const companyAdapter = (
  incomingData: any,
): Company => {
  const data = removeTypeNameFromObject<Company>(incomingData)

  if (data?.branches?.length) {
    data.branches = data.branches.map(formatCompanyBranch)
  }
  return data
}
