import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import SourceIcon from '@mui/icons-material/PublicOutlined'
import PlatformIcon from '@mui/icons-material/MonitorOutlined'
import EditIcon from '@mui/icons-material/Edit'

import { type MoveUtmPayload, type FullMove, useUpdateMoveAction } from '../../../../../modules/adminMoves'
import { capitalizeFirstLetter } from '../../../../../utils/string'
import Paper from '../../../../../components/Paper'
import { useMoveState } from '../../../../../modules/moves'
import EditUtmForm from '../../../../../components/forms/moves/EditUtmForm'
import { useErrorFormatter } from '../../../../../components/errors/useErrorFormatter'
import { errorNotification, successNotification } from '../../../../../components/ToastNotifications'

type SourceBoxProps = {
  move: FullMove
}

const SourceBox: React.FC<SourceBoxProps> = ({
  move,
}) => {
  const { t } = useTranslation()
  const utms = [move.utmSource, move.utmMedium, move.utmCampaign].filter(utm => !!utm).join(', ')
  const [editing, setEditing] = useState(false)
  const { canEditSource } = useMoveState(move)
  const [updateMove] = useUpdateMoveAction()
  const formatError = useErrorFormatter()

  const handleUtmUpdate = async (payload: MoveUtmPayload) => {
    try {
      await updateMove(move.id, payload)
      successNotification(t('pages.admin.move.sidebar.source.updateSuccess'))
      setEditing(false)
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  return (
    <Paper
      title={t('pages.admin.move.sidebar.source.title')}
      titleIcon={<SourceIcon />}
      compact
    >
      <div className="flex gap-x-4">

        <PlatformIcon className="mt-1 !text-[22px] text-gray-400" />

        <div>
          <div className="text-lg text-neutral-700">
            { capitalizeFirstLetter(move.platform ?? 'MovingWaldo') }
          </div>

          { utms && !editing && (
            <div className="text-sm text-neutral-400">
              { utms }
            </div>
          ) }

        </div>
      </div>

      { editing
        ? (
          <EditUtmForm
            onSubmit={handleUtmUpdate}
            onCancel={() => { setEditing(false) }}
            data={{
              utmSource: move.utmSource,
              utmMedium: move.utmMedium,
              utmCampaign: move.utmCampaign,
            }}
          />
          )
        : (
          <div>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => { setEditing(true) }}
              disabled={!canEditSource}
              className="!mt-2 !normal-case"
            >
              { t('actions.edit') }
            </Button>
          </div>
          ) }
    </Paper>
  )
}

export default SourceBox
