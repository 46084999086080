import * as mutations from './adminMoves.mutations'
import { type FullMove } from './adminMoves.models'
import {
  type UpdateNotePayload,
  type ApproveMovePayload,
  type UploadNoteAttachmentPayload,
  type DeleteNoteAttachmentPayload,
  type SendQuoteByEmailPayload,
  type ApplyPromoCodePayload,
  type SelectBranchPayload,
  type EditQuotePayload,
  type MovePayload,
} from './adminMoves.types'
import { useAction, useActionWithPayload } from '../../graphQl'

/**
 * create a move
 */
export const useCreateMoveAction = () => {
  return useActionWithPayload(
    useAction<FullMove>(mutations.createMove),
    (payload: MovePayload) => ({ payload }),
  )
}

/**
 * update a move
 */
export const useUpdateMoveAction = () => {
  return useActionWithPayload(
    useAction<FullMove>(mutations.updateMove),
    (moveId: string, payload: Partial<MovePayload>) => ({ moveId, payload }),
  )
}

/**
 * assign agent to move
 */
export const useAssignAgentToMoveAction = () => {
  return useActionWithPayload(
    useAction<FullMove>(mutations.assignAgentToMove),
    (moveId: string) => ({ moveId }),
  )
}

/**
 * unassign agent to move
 */
export const useUnassignAgentToMoveAction = () => {
  return useActionWithPayload(
    useAction<FullMove>(mutations.unassignAgentToMove),
    (moveId: string) => ({ moveId }),
  )
}

/**
 * send move to SmartMoving
 */
export const useSendMoveToSmartMovingAction = () => {
  return useActionWithPayload(
    useAction<boolean>(mutations.sendMoveToSmartMoving),
    (moveId: string) => ({ moveId }),
  )
}

/**
 * refresh quotes
 */
export const useRefreshQuotesAction = () => {
  return useActionWithPayload(
    useAction<FullMove>(mutations.refreshQuotes),
    (moveId: string) => ({ moveId }),
  )
}

/**
 * cancel selected quote
 */
export const useCancelSelectedQuoteAction = () => {
  return useActionWithPayload(
    useAction<FullMove>(mutations.cancelSelectedQuote),
    (moveId: string) => ({ moveId }),
  )
}

/**
 * select/unselect a quote
 */
export const useApplyPromoCodeAction = () => {
  return useAction<FullMove, ApplyPromoCodePayload>(mutations.applyPromoCode)
}

/**
 * select and customize a quote
 */
export const useSelectBranchAction = () => {
  return useAction<FullMove, SelectBranchPayload>(mutations.selectBranch)
}

/**
 * edit quote
 */
export const useEditQuoteAction = () => {
  return useAction<FullMove, EditQuotePayload>(mutations.editQuote)
}

/**
 * send email reminder to customer
 */
export const useSendQuoteByEmailAction = () => {
  return useAction<FullMove, SendQuoteByEmailPayload>(mutations.sendQuoteByEmail)
}

/**
 * approve move
 */
export const useApproveMoveAction = () => {
  return useActionWithPayload(
    useAction<FullMove>(mutations.approveMove),
    (moveId: string, payload?: ApproveMovePayload) => ({ moveId, payload }),
  )
}

/**
 * cancel a pending job request
 */
export const useCancelJobRequestAction = () => {
  return useActionWithPayload(
    useAction<FullMove>(mutations.cancelJobRequest),
    (moveId: string) => ({ moveId }),
  )
}

/**
 * cancel a job
 */
export const useCancelJobAction = () => {
  return useActionWithPayload(
    useAction<FullMove>(mutations.cancelJob),
    (moveId: string, jobId: string) => ({ moveId, jobId }),
  )
}

/**
 * collect total customer balance on his credit card
 */
export const useCollectTotalBalanceAction = () => {
  return useActionWithPayload(
    useAction<FullMove>(mutations.collectTotalBalance),
    (moveId: string) => ({ moveId }),
  )
}

/**
 * send an email to the customer with a link to pay his balance
 */
export const useSendPaymentRequestEmailAction = () => {
  return useActionWithPayload(
    useAction<FullMove>(mutations.sendPaymentRequestEmail),
    (moveId: string) => ({ moveId }),
  )
}

/**
 * update a note for a move
 */
export const useUpdateMoveNoteAction = () => {
  return useActionWithPayload(
    useAction<FullMove>(mutations.updateMoveNote),
    (moveId: string, payload: UpdateNotePayload) => ({ moveId, payload }),
  )
}

/**
 * upload a note attachment for a move
 */
export const useUploadNoteAttachmentAction = () => {
  return useActionWithPayload(
    useAction<FullMove>(mutations.uploadMoveNoteAttachment),
    (moveId: string, payload: UploadNoteAttachmentPayload) => ({ moveId, payload }),
  )
}

/**
 * delete a note attachment for a move
 */
export const useDeleteNoteAttachmentAction = () => {
  return useActionWithPayload(
    useAction<FullMove>(mutations.deleteMoveNoteAttachment),
    (moveId: string, payload: DeleteNoteAttachmentPayload) => ({ moveId, payload }),
  )
}

/**
 * share a customer note with the partner
 */
export const useShareNoteFromCustomerAction = () => {
  return useActionWithPayload(
    useAction<FullMove>(mutations.shareNoteFromCustomer),
    (moveId: string, shareNoteFromCustomer: boolean) => ({ moveId, shareNoteFromCustomer }),
  )
}
