import {
  type RuleTemporality,
  RuleTemporalityType,
} from '../../../../modules/companies'

export const ruleTemporalityToFormData = (ruleTemporality: RuleTemporality<number>): RuleTemporality<string> => {
  switch (ruleTemporality.type) {
    case RuleTemporalityType.Date:
      return {
        type: ruleTemporality.type,
        date: ruleTemporality.date,
      }
    case RuleTemporalityType.DateRange:
      return {
        type: ruleTemporality.type,
        from: ruleTemporality.from,
        to: ruleTemporality.to,
      }
    case RuleTemporalityType.RecurrentRange:
      return {
        type: ruleTemporality.type,
        fromDay: String(ruleTemporality.fromDay),
        toDay: String(ruleTemporality.toDay),
        months: ruleTemporality.months?.map(m => String(m)),
      }
    case RuleTemporalityType.EveryWeekDay:
      return {
        type: ruleTemporality.type,
        days: ruleTemporality.days?.map(d => String(d)),
        months: ruleTemporality.months?.map(m => String(m)),
      }
  }
}

export const formatFormOutput = (formData: RuleTemporality<string>): RuleTemporality<number> => {
  switch (formData.type) {
    case RuleTemporalityType.Date:
      return {
        type: formData.type,
        date: formData.date,
      }
    case RuleTemporalityType.DateRange:
      return {
        type: formData.type,
        from: formData.from,
        to: formData.to,
      }
    case RuleTemporalityType.RecurrentRange:
      return {
        type: formData.type,
        fromDay: parseInt(formData.fromDay),
        toDay: parseInt(formData.toDay),
        months: (!formData?.months?.length || formData.months.length === 12) ? undefined : formData.months.map(m => parseInt(m)),
      }
    case RuleTemporalityType.EveryWeekDay:
      return {
        type: formData.type,
        days: formData.days?.map(d => parseInt(d)),
        months: (!formData?.months?.length || formData.months.length === 12) ? undefined : formData.months.map(m => parseInt(m)),
      }
  }
}
