import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import CompaniesIcon from '@mui/icons-material/BusinessOutlined'
import MuiTabs from '@mui/material/Tabs'
import MuiTab from '@mui/material/Tab'

import { type FullCompanyBranch } from '../../../../modules/adminCompanies'
import SidePanel from '../../../../components/SidePanel'
import { capitalizeFirstLetter } from '../../../../utils/string'
import { type Action } from '../../../../components/dataGrid'
import CompanyTab from './CompanyTab'
import PrimaryContactTab from './PrimaryContactTab'
import FilesTab from './FilesTab'
import WarehouseTab from './WarehouseTab'
import RatingsTab from './RatingsTab'

enum Tab {
  Company = 'company',
  PrimaryContact = 'primaryContact',
  Files = 'files',
  Warehouse = 'warehouse',
  Ratings = 'ratings',
}

type CompanyBranchPanelProps = {
  companyBranch?: FullCompanyBranch
  onClose: () => void
  actions?: Action[]
}

const CompanyBranchPanel: React.FC<CompanyBranchPanelProps> = ({
  companyBranch,
  onClose,
  actions,
}) => {
  const { t } = useTranslation()
  const [selectedTab, setSelectedTab] = useState(Tab.Company)

  useEffect(() => {
    setSelectedTab(Tab.Company)
  }, [companyBranch?.id])

  return (
    <SidePanel
      open={!!companyBranch}
      onClose={onClose}
      header={(
        <div className="mb-0 mr-8 border-b pb-4 pl-2 font-body2 text-2xl lg:pl-0">

          <div className="flex flex-col md:flex-row md:items-center md:justify-between">
            <div>
              <CompaniesIcon className="ml-1 mr-2 text-neutral-500" />
              { ' ' }
              { capitalizeFirstLetter(companyBranch?.company?.identification?.name.en) }
              <div className="pl-[45px] text-sm text-neutral-400">
                { companyBranch?.id }
              </div>
            </div>

            { companyBranch?.company?.logo?.en && (
              <div>
                <img
                  src={companyBranch?.company?.logo?.en}
                  alt=""
                  className="ml-10 mt-4 max-h-[50px] md:ml-0 md:mr-8 md:mt-0"
                />
              </div>
            ) }

          </div>
        </div>
      )}
    >

      { companyBranch && (
        <>
          <div className="mb-6 border-b">
            <MuiTabs
              value={selectedTab}
              variant="scrollable"
              scrollButtons="auto"
              onChange={(_, value) => { setSelectedTab(value) }}
            >
              <MuiTab
                value={Tab.Company}
                label={t('pages.admin.companies.branchPanel.tabs.company')}
              />

              <MuiTab
                value={Tab.PrimaryContact}
                label={t('pages.admin.companies.branchPanel.tabs.primaryContact')}
              />

              { (companyBranch.legalFiles?.length ?? 0) > 0 && (
                <MuiTab
                  value={Tab.Files}
                  label={t('pages.admin.companies.branchPanel.tabs.files')}
                />
              ) }

              { companyBranch.tripAndTravel?.trucksOriginCoordinates && (
                <MuiTab
                  value={Tab.Warehouse}
                  label={t('pages.admin.companies.branchPanel.tabs.warehouseOrOffice')}
                />
              ) }

              { companyBranch.approved && companyBranch.active && (
                <MuiTab
                  value={Tab.Ratings}
                  label={t('pages.admin.companies.branchPanel.tabs.ratings')}
                />
              ) }
            </MuiTabs>
          </div>
          <div>
            { selectedTab === Tab.Company && (
              <CompanyTab companyBranch={companyBranch} actions={actions} />
            ) }
            { selectedTab === Tab.PrimaryContact && (
              <PrimaryContactTab companyBranch={companyBranch} />
            ) }
            { selectedTab === Tab.Files && (
              <FilesTab companyBranch={companyBranch} />
            ) }
            { selectedTab === Tab.Warehouse && (
              <WarehouseTab companyBranch={companyBranch} />
            ) }
            { selectedTab === Tab.Ratings && (
              <RatingsTab companyBranch={companyBranch} />
            ) }
          </div>
        </>
      ) }
    </SidePanel>
  )
}

export default CompanyBranchPanel
