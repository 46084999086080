import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'

import { type MoveNote } from '../../../../modules/adminMoves'
import {
  Form,
  SubmitButton,
  NoteField,
} from '../../../form'
import { type NoteFormValues } from './NoteFormValues'

type NoteFormProps = {
  note?: MoveNote
  onSubmit: (note: string) => Promise<void> | void
  onUpload?: (file: File) => Promise<void> | void
  onDeleteFile?: (id: string) => Promise<void> | void
  onCancel?: () => void
  autoFocus?: boolean
}

const NoteForm: React.FC<NoteFormProps> = ({
  note,
  onSubmit,
  onUpload,
  onDeleteFile,
  onCancel,
  autoFocus,
}) => {
  const { t } = useTranslation()
  const form = useForm({
    defaultValues: { note: note?.note },
  })
  const handleSubmit = async (value: NoteFormValues) => { await onSubmit(value.note) }
  const [uploading, setUploading] = useState(false)

  return (
    <Form
      form={form}
      onSubmit={handleSubmit}
    >
      <NoteField
        name="note"
        files={note?.attachments}
        onUpload={onUpload}
        onDeleteFile={onDeleteFile}
        onUploading={setUploading}
        className="border-none [&_.field-wrapper]:p-[2px]"
        autoFocus={autoFocus}
      />

      <div className="mt-2 flex justify-end gap-x-2">
        { onCancel && (
          <Button
            variant="contained"
            color="secondary"
            onClick={onCancel}
          >
            { t('actions.cancel') }
          </Button>
        ) }
        <SubmitButton
          disabled={uploading}
        >
          { t('actions.save') }
        </SubmitButton>
      </div>

    </Form>
  )
}

export default NoteForm
