import type Language from '../../../../app/Language.enum'
import { useConfig } from '../../../config'
import { type Invoice } from '../invoices.types'

type UseInvoiceLinkOptions = {
  moveId: string
  language: Language
}

/**
 * returns invoice link to down an invoice
 */
export const useGetInvoiceLink = () => {
  const apiUrl = useConfig('api.url')

  return (invoice: Invoice, {
    moveId,
    language,
  }: UseInvoiceLinkOptions) => {
    const invoiceId = invoice.id
    const invoiceNumber = invoice.invoiceNumber.toLowerCase()
    return `${apiUrl}/invoices/move/${moveId}/${invoiceId}/${invoiceNumber}-${language}.pdf`
  }
}
