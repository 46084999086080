import * as mutations from './adminCompanyRatings.mutations'
import { type FullCompanyRating } from './adminCompanyRatings.models'
import { useAction, useActionWithPayload } from '../../graphQl'

/**
 * approve or reject a cocmpany branch rating
 * query optimized for data table
 */
export const useUpdateCompanyBranchRatingApprobationAction = () => {
  return useActionWithPayload(
    useAction<FullCompanyRating>(mutations.updateCompanyBranchRatingApprobation),
    (companyBranchRatingId: string, approved: boolean) => ({ companyBranchRatingId, approved }),
  )
}
