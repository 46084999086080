import { addDays } from 'date-fns/addDays'
import { startOfDay } from 'date-fns/startOfDay'
import { endOfDay } from 'date-fns/endOfDay'
import { subMonths } from 'date-fns/subMonths'

import {
  type LazyDataOptions,
  useLazyData,
  type DataOptions,
  useData,
} from '../../graphQl'
import * as queries from './jobs.queries'
import { useCurrentCompanyBranch } from '../../companies'
import {
  type JobForPartner,
  type JobsQuery,
  type LightJob,
  type CloseJobForPartnerPayload,
  JobStatus,
} from './jobs.type'
import { sortJobs } from './jobs.utils'
import { type FullInvoiceEstimate } from '../../invoices'

/**
 * returns all jobs for a given company branch
 */
export const useJobs = (dataOptions?: LazyDataOptions) => {
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()
  const { fetch: fetchJobs, ...data } = useLazyData<LightJob[], JobsQuery>(
    queries.getJobs,
    dataOptions,
  )
  return {
    fetchJobs: async (query: JobsQuery) => {
      return await fetchJobs({
        ...query,
        companyBranchId: query.companyBranchId ?? currentCompanyBranch?.id,
      })
    },
    ...data,
  }
}

/**
 * returns all upcoming jobs (7 days) for a given company branch
 */
export const useUpcomingJobs = (dataOptions: DataOptions) => {
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()

  const today = startOfDay(new Date())

  return useData<LightJob[]>(queries.getJobs, {
    ...dataOptions,
    skip: !currentCompanyBranch?.approved,
    variables: {
      companyBranchId: currentCompanyBranch?.id,
      status: [
        JobStatus.Pending,
        JobStatus.Completed,
      ],
      dateRangeFilter: {
        from: today,
        to: addDays(today, 7),
      },
    },
    adapter: (data: any) => sortJobs(data, 'ASC'),
  })
}

/**
 * returns all upcoming jobs (7 days) for a given company branch
 */
export const useJobsReadyToBeClosed = (dataOptions?: DataOptions) => {
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()

  const today = endOfDay(new Date())

  return useData<LightJob[]>(queries.getJobs, {
    ...dataOptions,
    skip: !currentCompanyBranch?.approved,
    variables: {
      companyBranchId: currentCompanyBranch?.id,
      status: [
        JobStatus.Completed,
      ],
      dateRangeFilter: {
        from: subMonths(today, 3),
        to: today,
      },
    },
    adapter: (data: any) => sortJobs(data, 'ASC'),
  })
}

/**
 * get a job by id
 */
export const useJob = (jobId?: string, dataOptions?: DataOptions) => {
  return useData<JobForPartner>(queries.getJob, {
    ...dataOptions,
    variables: { jobId },
  })
}

/**
 * get a an invoice preview
 */
export const usePreviewCustomerInvoiceForPartner = () => {
  const { fetch, ...data } = useLazyData<FullInvoiceEstimate>(
    queries.previewCustomerInvoiceForPartner,
    {
      reload: true,
    },
  )
  return {
    getInvoicePreview: async (
      jobId: string,
      payload: CloseJobForPartnerPayload,
    ) => await fetch({ jobId, payload }),
    ...data,
  }
}
