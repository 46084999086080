import { useState } from 'react'

import { type ExternalFile } from '../../common/file/file.type'
import { isImage } from '../../utils/file'
import File from '../Files/File'
import ImagesCarousel from '../ImagesCarousel'

type MoveNoteAttachmentsProps = {
  attachments: ExternalFile[]
}

const MoveNoteAttachments: React.FC<MoveNoteAttachmentsProps> = ({
  attachments,
}) => {
  const [openCarousel, setOpenCarousel] = useState(false)
  const [selectedFile, setSelectedFile] = useState<string>()

  const onClick = (fileId: string) => {
    const file = attachments.find(attachment => attachment.id === fileId)
    if (!file) {
      return
    }
    if (!isImage(file.filename)) {
      window?.open(file.url, '_blank')?.focus()
      return
    }

    setSelectedFile(fileId)
    setOpenCarousel(true)
  }

  return (
    <>
      <ImagesCarousel
        open={openCarousel}
        onClose={() => { setOpenCarousel(false) }}
        files={attachments.filter(file => isImage(file.filename))}
        selectedFile={selectedFile}
      />
      <div className="mt-4 flex flex-wrap justify-center gap-2 xl:justify-start">
        { attachments.map((attachment) => {
          const isImageFile = isImage(attachment.filename, { onlyBinary: false })
          return (
            <File
              key={attachment.id}
              id={attachment.id}
              filename={attachment.filename}
              preview={isImageFile ? attachment.url : undefined}
              onClick={() => { onClick(attachment.id) }}
              className="cursor-pointer transition-all hover:scale-105"
            />
          )
        }) }
      </div>
    </>
  )
}

export default MoveNoteAttachments
