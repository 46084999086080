import { useAction, useActionWithPayload } from '../../graphQl'
import { type FullMove } from '../../adminMoves'
import { type PaymentPayload } from './payment.types'
import * as mutations from './payments.mutations'

/**
 * create payment
 */
export const useCreatePaymentAction = () => {
  return useActionWithPayload(
    useAction<FullMove>(mutations.createPayment),
    (moveId: string, payload: PaymentPayload) => ({ moveId, payload }),
  )
}

/**
 * delete a payment
 */
export const useDeletePaymentAction = () => {
  return useActionWithPayload(
    useAction<FullMove>(mutations.deletePayment),
    (moveId: string, paymentId: string) => ({ moveId, paymentId }),
  )
}
