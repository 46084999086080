import { useTranslation } from 'react-i18next'
import LinearProgress from '@mui/material/LinearProgress'

import Spinner from '../../../components/Spinner'

const ReportSpinner: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center  backdrop-blur-sm">
      <div className="relative overflow-hidden rounded-xl border bg-white p-12 shadow-lg">
        <div className="flex items-center gap-x-6">
          <div className="scale-[0.5]">
            <Spinner />
          </div>
          <div>
            <div className="font-body2 text-2xl leading-tight">
              { t('pages.admin.accounting.spinner.title') }
            </div>
            <div className="mt-2 text-neutral-400">
              { t('pages.admin.accounting.spinner.subtitle') }
            </div>
          </div>
        </div>
        <div className="absolute -top-px left-0 w-full opacity-50">
          <LinearProgress color="primary" />
        </div>
      </div>
    </div>
  )
}

export default ReportSpinner
