import * as Yup from 'yup'

import { usePriceValidation } from '../../../../modules/companies'
import { useFloatValidator } from '../../../../utils/number'
import { type CloseJobFormValues } from './CloseJobForm.types'

const useValidationSchema = () => {
  const floatValidator = useFloatValidator()
  const validateQuantity = floatValidator({ min: 0, max: 999, maxDecimals: 2, required: false })

  const priceValidator = usePriceValidation()
  const validatePrice = priceValidator({
    allowDecimal: true,
    allowZero: true,
    required: false,
  })

  return Yup.object({
    charges: Yup.array().of(Yup.object({
      quantity: validateQuantity,
      unitPrice: validatePrice,
    })),
  }) as Yup.ObjectSchema<CloseJobFormValues, CloseJobFormValues>
}

export default useValidationSchema
