import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import { SortDirection } from '../../../modules/graphQl'
import {
  Permission,
  protectAdminPage,
  usePermission,
} from '../../../modules/users'
import { useCompaniesBranchesRatings } from '../../../modules/adminCompanyRatings'
import { useNavigate } from '../../../modules/navigation'
import Route from '../../../app/Route.enum'
import { capitalizeFirstLetter } from '../../../utils/string'
import ContainedPage from '../../../components/layout/ContainedPage/ContainedPage'
import PageContent from '../../../components/layout/PageContent/PageContent'
import PageTitle from '../../../components/layout/PageTitle/PageTitle'
import ServerError from '../../../components/errors/ServerError'
import Link, { LinkVariant } from '../../../components/Link'
import {
  DataGrid,
  Column,
  Row,
  Cell,
  SmallValue,
  DateValue,
} from '../../../components/dataGrid'
import Rating from './Rating'
import ReviewApprobationLabel from './ReviewApprobationLabel'
import ReviewMessage from './ReviewMessage'
import CustomFilters, { APPROVED_STATUS_FILTER } from './CustomFilters'

const CompaniesRatingsPage: React.FC = () => {
  const { t } = useTranslation()
  const {
    fetchCompaniesBranchesRatings,
    data: companiesBranchesRatings,
    error: companiesBranchesRatingsErrors,
  } = useCompaniesBranchesRatings()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onQueryUpdate = useCallback(fetchCompaniesBranchesRatings, [])

  const canReadMoves = usePermission(Permission.canReadMoves)

  const { navigate } = useNavigate()

  const handleRowClick = (companyBranchRatingId: string) => {
    navigate(Route.CompanyRating, { companyBranchRatingId })
  }

  if (companiesBranchesRatingsErrors) {
    return <ContainedPage><ServerError error={companiesBranchesRatingsErrors} /></ContainedPage>
  }

  const renderCustomer = (customerName: string, moveId?: string) => {
    if (!canReadMoves || !moveId) {
      return capitalizeFirstLetter(customerName)
    }
    return (
      <Link
        to={Route.AdminMove}
        params={{ moveId }}
        variant={LinkVariant.Primary}
        onClick={event => { event.stopPropagation() }}
      >
        { capitalizeFirstLetter(customerName) }
      </Link>
    )
  }

  return (
    <>
      <Outlet />
      <ContainedPage>
        <PageTitle fullWidth>
          { t('pages.admin.companiesBranchesRatings.title') }
        </PageTitle>

        <PageContent fullWidth className="py-8">
          <DataGrid
            name="companiesBranchesRatings"
            count={companiesBranchesRatings?.count ?? 0}
            onQueryUpdate={onQueryUpdate}
            initialQuery={{ sortField: 'createdAt', sortDirection: SortDirection.DESC }}
            customFilters={CustomFilters}
            saveCustomFilterValues={[APPROVED_STATUS_FILTER]}
            columns={(
              <>
                <Column name="createdAt" sortable>
                  { t('pages.admin.companiesBranchesRatings.table.columns.createdAt') }
                </Column>

                <Column name="reviewApprobation" sortable>
                  { t('pages.admin.companiesBranchesRatings.table.columns.reviewApprobation') }
                </Column>

                <Column name="source" defaultVisible={false}>
                  { t('pages.admin.companiesBranchesRatings.table.columns.source') }
                </Column>

                <Column name="customerName" sortable>
                  { t('pages.admin.companiesBranchesRatings.table.columns.customerName') }
                </Column>

                <Column name="companyName" sortable>
                  { t('pages.admin.companiesBranchesRatings.table.columns.companyName') }
                </Column>

                <Column name="overallRating" sortable className="!text-center">
                  { t('pages.admin.companiesBranchesRatings.table.columns.overallRating') }
                </Column>

                <Column name="professionalismRating" sortable className="!text-center" defaultVisible={false}>
                  { t('pages.admin.companiesBranchesRatings.table.columns.professionalismRating') }
                </Column>

                <Column name="punctualityRating" sortable className="!text-center" defaultVisible={false}>
                  { t('pages.admin.companiesBranchesRatings.table.columns.punctualityRating') }
                </Column>

                <Column name="communicationRating" sortable className="!text-center" defaultVisible={false}>
                  { t('pages.admin.companiesBranchesRatings.table.columns.communicationRating') }
                </Column>

                <Column name="review" className="w-[300px]">
                  { t('pages.admin.companiesBranchesRatings.table.columns.review') }
                </Column>

                <Column name="ownerResponse" className="w-[300px]">
                  { t('pages.admin.companiesBranchesRatings.table.columns.ownerResponse') }
                </Column>
              </>
            )}
          >
            { companiesBranchesRatings?.data?.map(companyBranchRating => (
              <Row
                key={companyBranchRating.id}
                id={companyBranchRating.id}
                onClick={handleRowClick}
              >
                <Cell><SmallValue><DateValue date={companyBranchRating.createdAt} /></SmallValue></Cell>
                <Cell>
                  <ReviewApprobationLabel reviewApprobation={companyBranchRating.reviewApprobation} />
                </Cell>
                <Cell>
                  <SmallValue>
                    { companyBranchRating.externalId ? 'SmartMoving' : 'MovingWaldo' }
                  </SmallValue>
                </Cell>
                <Cell>
                  { renderCustomer(companyBranchRating.customerName, companyBranchRating.moveId) }
                </Cell>
                <Cell>{ companyBranchRating.companyName }</Cell>
                <Cell center>
                  <Rating rating={companyBranchRating.overallRating} />
                </Cell>
                <Cell center>
                  <Rating rating={companyBranchRating.professionalismRating} />
                </Cell>
                <Cell center>
                  <Rating rating={companyBranchRating.punctualityRating} />
                </Cell>
                <Cell center>
                  <Rating rating={companyBranchRating.communicationRating} />
                </Cell>
                <Cell>
                  <ReviewMessage message={companyBranchRating.review} />
                </Cell>
                <Cell>
                  <ReviewMessage message={companyBranchRating.ownerResponse} />
                </Cell>
              </Row>
            )) }
          </DataGrid>
        </PageContent>
      </ContainedPage>
    </>
  )
}

export default protectAdminPage(CompaniesRatingsPage)
