import { Children, useEffect } from 'react'
import clsx from 'clsx'
import { styled, lighten, darken } from '@mui/material/styles'

import { useDataGridContext } from '../DataGridContext'
import Cell, { LargeScreenCell } from './Cell'
import StaticCheckbox from './StaticCheckbox'
import Button from './Button'
import { getIndividualActions, getBulkActions } from '../DataGrid.util'
import { useVisibleColumns } from '../useToggleColumns'

const TableRow = styled('tr')(({ theme }) => ({
  verticalAlign: 'center',
  '> td': {
    borderBottom: 'solid 1px #e5e7eb',
  },
  '&:hover > td': {
    background: '#f9fafb',
  },

  '&:first-of-type > td': {
    paddingTop: '1.2rem',
  },
  '&.disabled > td': {
    color: theme.palette.mode === 'dark' ? lighten(theme.palette.error.main, 0.5) : darken(theme.palette.error.main, 0.5),
  },
  '&.disabled:nth-of-type(odd) > td': {
    background: theme.palette.mode === 'dark' ? darken(theme.palette.error.main, 0.7) : lighten(theme.palette.error.main, 0.8),
  },
  '&.disabled:nth-of-type(odd):hover > td': {
    background: theme.palette.mode === 'dark' ? darken(theme.palette.error.main, 0.75) : lighten(theme.palette.error.main, 0.75),
  },
  '&.disabled:nth-of-type(even) > td': {
    background: theme.palette.mode === 'dark' ? darken(theme.palette.error.main, 0.6) : lighten(theme.palette.error.main, 0.7),
  },
  '&.disabled:nth-of-type(even):hover > td': {
    background: theme.palette.mode === 'dark' ? darken(theme.palette.error.main, 0.65) : lighten(theme.palette.error.main, 0.65),
  },
  '&.warning > td': {
    color: theme.palette.mode === 'dark' ? lighten(theme.palette.warning.main, 0.5) : darken(theme.palette.warning.main, 0.35),
  },
  '&.warning:nth-of-type(odd) > td': {
    background: theme.palette.mode === 'dark' ? darken(theme.palette.warning.main, 0.7) : lighten(theme.palette.warning.main, 0.9),
  },
  '&.warning:nth-of-type(odd):hover > td': {
    background: theme.palette.mode === 'dark' ? darken(theme.palette.warning.main, 0.75) : lighten(theme.palette.warning.main, 0.87),
  },
  '&.warning:nth-of-type(even) > td': {
    background: theme.palette.mode === 'dark' ? darken(theme.palette.warning.main, 0.6) : lighten(theme.palette.warning.main, 0.85),
  },
  '&.warning:nth-of-type(even):hover > td': {
    background: theme.palette.mode === 'dark' ? darken(theme.palette.warning.main, 0.65) : lighten(theme.palette.warning.main, 0.83),
  },
  '&.hasCheckBox > td:nth-of-type(1)': {
    position: 'sticky',
    left: 0,
    zIndex: 3,
  },
  '&.hasActions > td:nth-of-type(1)': {
    position: 'sticky',
    left: 0,
    zIndex: 3,
  },
  '&.hasCheckBox.hasActions > td:nth-of-type(2)': {
    position: 'sticky',
    left: 62,
    zIndex: 3,
  },
  [theme.breakpoints.down('md')]: {
    '&.hasCheckBox.hasActions > td:nth-of-type(2)': {
      left: 0,
    },
  },
  '&.clickable': {
    cursor: 'pointer',
  },
}))

const RowCheckbox = styled(StaticCheckbox)(({ theme }) => ({
  marginLeft: 10,
}))

export type RowProps = {
  children: React.ReactNode[]
  id?: string
  disabled?: boolean
  warning?: boolean
  onClick?: (id: string, newTab?: boolean) => void
}

const Row: React.FC<RowProps> = ({
  children,
  id,
  disabled = false,
  warning = false,
  onClick,
}) => {
  const {
    registerRow,
    unRegisterRow,
    selectedRows,
    toggleRowSelection,
    columns,
    actions,
    disabled: dataGridDisabled,
  } = useDataGridContext()

  const showAllColumns = Object.keys(columns).length === 0
  const visibleColumns = useVisibleColumns()
  const individualActions = getIndividualActions(actions)
  const hasIndividualActions = individualActions.length > 0
  const hasBulkActions = getBulkActions(actions).length > 0

  const getColumnNameByIndex = (index: number) => {
    return Object.keys(columns)[index]
  }

  useEffect(() => {
    if (!id) {
      return
    }
    registerRow(id)
    return () => {
      unRegisterRow(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const clickable = onClick && id && !dataGridDisabled

  const handleRowMouseUp = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, id: string) => {
    if (!onClick) {
      return
    }
    const button = event.button
    const leftClick = button === 0
    const middleClick = button === 1

    if (!leftClick && !middleClick) {
      return
    }

    onClick(id, middleClick)
  }

  const handleRowMouseDown = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    const button = event.button
    const middleClick = button === 1
    if (middleClick) {
      event.preventDefault()
      return false
    }
  }

  return (
    <TableRow
      className={clsx(
        hasBulkActions && 'hasCheckBox',
        hasIndividualActions && 'hasActions',
        disabled && 'disabled',
        warning && 'warning',
        clickable && 'clickable',
      )}
      onMouseUp={clickable ? (event) => { handleRowMouseUp(event, id) } : undefined}
      onMouseDown={clickable ? (event) => { handleRowMouseDown(event) } : undefined}
    >
      { hasBulkActions && id && (
        <LargeScreenCell preventClick>
          { typeof id !== 'undefined' && (
            <RowCheckbox
              checked={selectedRows.includes(id)}
              onClick={() => { toggleRowSelection(id) }}
              danger={disabled}
              disabled={dataGridDisabled}
            />
          ) }
        </LargeScreenCell>
      ) }

      { hasIndividualActions && id && (
        <Cell
          preventClick
        >
          { id && individualActions
            .filter(({ filter }) => !filter || filter([id]))
            .map(({ label, icon: Icon, callback, danger }) => (
              <Button
                key={label}
                tooltip={label}
                onClick={(event) => { event.stopPropagation(); callback?.(id) }}
                danger={danger}
                disabled={dataGridDisabled}
              >
                <Icon />
              </Button>
            )) }
        </Cell>
      ) }

      { Children.map(children, (cell, index) => {
        const columnsName = getColumnNameByIndex(index)
        if (
          (!columnsName || !visibleColumns.includes(columnsName)) &&
          !showAllColumns
        ) {
          return null
        }
        return cell
      }) }
    </TableRow>
  )
}

export default Row
