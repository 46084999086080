import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'

import { type FullMove } from '../../../../modules/adminMoves'
import { useMoveState } from '../../../../modules/moves'
import { type PaymentPayload } from '../../../../modules/payment'
import { ExternalSource } from '../../../../modules/payment/core/payment.enums'
import {
  Form,
  DateTime,
  Label,
  PriceInput,
  Select,
  SubmitButton,
} from '../../../form'
import { type PaymentFormValues } from './PaymentForm.type'
import { formatFormOutput } from './formDataBuilder'
import useValidationSchema from './validationSchema'

type PaymentFormProps = {
  move: FullMove
  onSubmit: (data: PaymentPayload) => Promise<void> | void
  onCancel?: () => Promise<void> | void
}

const PaymentForm: React.FC<PaymentFormProps> = ({
  move,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation()
  const {
    currency,
  } = useMoveState(move)

  const schema = useValidationSchema()
  const form = useForm<PaymentFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      paymentDate: new Date(),
    },
  })

  const externalSourceOptions = Object.values(ExternalSource).map((externalSource: string) => ({
    value: externalSource,
    label: t(`payment.externalSource.${externalSource}`),
  }))

  /**
   * format data and submit form
   */
  const handleSubmit = async (values: PaymentFormValues) => {
    await onSubmit(formatFormOutput(values, currency))
  }

  return (
    <Form
      form={form}
      onSubmit={handleSubmit}
    >
      <div className="my-4 flex flex-col gap-y-4">
        <Label label={`Amount (${currency})`}>
          <PriceInput
            name="amount"
            currency={currency}
            allowDecimal
            size="medium"
          />
        </Label>

        <div className="flex flex-wrap gap-x-4">
          <div className="flex-1 shrink-0">
            <Label label="Payment date">
              <DateTime
                name="paymentDate"
                type="date"
                size="large"
                disableFuture
              />
            </Label>
          </div>

          <div className="flex-1 shrink-0">
            <Label label="Source">
              <Select
                name="externalSource"
                options={externalSourceOptions}
                allowEmpty
              />
            </Label>
          </div>
        </div>
      </div>

      <div className="mb-1 mt-8 flex justify-end gap-3">
        { onCancel && (
          <Button
            variant="contained"
            color="secondary"
            onClick={onCancel}
          >
            { t('actions.cancel') }
          </Button>
        ) }
        <SubmitButton size="large">
          { t('actions.add') }
        </SubmitButton>
      </div>
    </Form>
  )
}

export default PaymentForm
