import { useTranslation } from 'react-i18next'
import CalendarBusyIcon from '@mui/icons-material/EventBusy'

import {
  type AvailabilityRule,
  RuleTemporalityRange,
  useTemporalityRuleHandler,
} from '../../../../modules/companies'

type AvailabilityRuleItemProps = {
  rule: AvailabilityRule
}

const AvailabilityRuleItem: React.FC<AvailabilityRuleItemProps> = ({
  rule,
}) => {
  const { t } = useTranslation()
  const getRuleHandler = useTemporalityRuleHandler()
  const handler = getRuleHandler(rule.temporality)
  return (
    <>
      <div className="flex items-center gap-1">
        <CalendarBusyIcon fontSize="small" className="text-red-800" />
      </div>
      <div className="flex grow items-center gap-2 text-sm">
        <span>
          { t('pages.companyBranch.availability.ruleList.unavailable') }
          { ' ' }
          <span className="lowercase">
            { handler?.getTitle() }
          </span>
          { rule.range === RuleTemporalityRange.Am && ` ${t('pages.companyBranch.availability.ruleList.am')}` }
          { rule.range === RuleTemporalityRange.Pm && ` ${t('pages.companyBranch.availability.ruleList.pm')}` }
        </span>
      </div>
    </>
  )
}

export default AvailabilityRuleItem
