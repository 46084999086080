import {
  type ChargeUnit,
  type ChargeDescription,
} from '../../../../modules/invoices'

export enum ChargeEditability {
  AlwaysEditable,
  EditableIfPresent,
  NotEditable,
}

export type ChargeDefinition = {
  editable: ChargeEditability
  unitPriceEditable?: boolean
  collection?: boolean
}

export type ChargesDefinition = Partial<Record<ChargeDescription, ChargeDefinition>>

export type ChargeRow = {
  chargeRefIndex?: number
  categoryAndDescription: string
  overrideDescription?: string
  quantity: string
  unit?: ChargeUnit | 'units'
  unitPrice: string
  item?: string
}

export type CloseJobFormValues = {
  charges: ChargeRow[]
}

export type AddChargeOptions = {
  item: string
  price: string
}
