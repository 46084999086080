/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Checkbox from '@mui/material/Checkbox'
import DeleteIcon from '@mui/icons-material/Delete'

import { type File } from '../../common/file/file.type'
import Link, { LinkVariant } from '../Link'
import FileIcon from './FileIcon'
import ConfirmationModal from '../ConfirmationModal'

type FilesProps = {
  files?: File[]
  getUrl?: (file: File) => string | undefined
  onDelete?: (files: string[]) => Promise<void> | void
}

const Files: React.FC<FilesProps> = ({
  files,
  getUrl,
  onDelete,
}) => {
  const { t } = useTranslation()
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const deletable = !!onDelete

  const onSelect = (id: string) => {
    const index = selectedIds.indexOf(id)
    if (index === -1) {
      setSelectedIds([...selectedIds, id])
    } else {
      const newSelectedIds = [...selectedIds]
      newSelectedIds.splice(index, 1)
      setSelectedIds(newSelectedIds)
    }
  }

  const deleteSelected = async () => {
    await onDelete?.(selectedIds)?.catch(console.error)
    setSelectedIds([])
  }

  const deleteOne = async (id: string) => {
    await onDelete?.([id])?.catch(console.error)
    setSelectedIds([])
  }

  if (!files?.length) {
    return null
  }

  return (
    <div className="pt-6">
      { deletable && (
        <div className="mb-3">
          <ConfirmationModal
            question={t(
              'components.files.deleteMultipleFilesConfirmation',
              {
                count: selectedIds.length,
              },
            )}
            onConfirm={deleteSelected}
            disabled={selectedIds.length === 0}
            className="inline"
            danger
          >
            <Button
              variant="contained"
              color="error"
              disabled={selectedIds.length === 0}
              size="small"
              startIcon={<DeleteIcon />}
            >
              { t('actions.deletedSelected') }
            </Button>
          </ConfirmationModal>
        </div>
      ) }
      <table className="w-full">
        <tbody
          className="[&>*:nth-child(odd)]:bg-gray-100"
        >
          { files.map(file => {
            const url = getUrl ? getUrl(file) : undefined
            return (
              <tr key={file.id}>
                { deletable && (
                  <td>
                    <Checkbox
                      checked={selectedIds.includes(file.id)}
                      onChange={() => { onSelect(file.id) }}
                    />
                  </td>
                ) }
                <td
                  className="py-1 pl-1"
                >
                  <FileIcon
                    filename={file.filename}
                    className="block w-[35px]"
                  />
                </td>
                <td
                  className="w-full max-w-px truncate pl-2 text-neutral-600"
                >
                  { url
                    ? (
                      <Link
                        to={url}
                        target="_blank"
                        variant={LinkVariant.Underlined}
                        className="truncate"
                        title={file.filename}
                      >
                        { file.filename }
                      </Link>
                      )
                    : file.filename }

                </td>
                { deletable && (
                  <td className="whitespace-nowrap pl-2 text-right">
                    <ConfirmationModal
                      question={t(
                        'components.files.deleteSingleFileConfirmation',
                        {
                          filename: file.filename,
                        },
                      )}
                      onConfirm={async () => { await deleteOne(file.id) }}
                      danger
                    >
                      <IconButton
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ConfirmationModal>
                  </td>
                ) }
              </tr>
            )
          }) }
        </tbody>
      </table>
    </div>
  )
}

export default Files
