import { useData, type DataOptions } from '../../../graphQl'
import * as queries from './permissions.queries'
import { type Role } from './permissions.models'
import { useAuthenticationState, useSuperAdmin } from '..'

/**
 * returns all roles
 */
export const useRoles = (dataOptions?: DataOptions) => {
  const isSuperAdmin = useSuperAdmin()
  const { isAuthenticated } = useAuthenticationState()

  return useData<Role[]>(
    queries.getRoles,
    {
      ...dataOptions,
      skip: !!dataOptions?.skip || !isAuthenticated,
      adapter: (roles: any): Role[] => {
        if (!isSuperAdmin) {
          return roles?.filter((role: any) => role.key !== 'superAdmin')
        }
        return roles
      },
    },
  )
}
