import { userFieldsForPartner } from '../../users/core/users.queries'

const placeFields = `
address {
  placeId
  placeTextLine1
  placeTextLine2
  country
  street
  apartment
  city
  region
  postalCode
}
nbStairs
elevatorReservationRequired
`

export const commonMoveDetailsFields = `
language
quoteAccepted {
  date
  movingTime
}
origin {
  ${placeFields}
}
destination {
  ${placeFields}
}
stops {
  ${placeFields}
  description
  stopDuration
}
movingDate
preferredTime
residenceType
residenceRooms {
  key
  value
}
residenceRoomsPrefilled
specialItemsPrefilled
nbBoxes
furnitureRatio
volumeCubicFeet
weightPounds
estimatedMovingLabourTime
estimatedPackingLabourTime
nbMen
nbTrucks {
  key
  value
}
specialServices {
  key
  value
}
specialItems {
  key
  value
}
protectiveMaterial {
  key
  value
}
overnightFees
moveLeg {
  origin {
    type
    coordinates
  }
  destination {
    type
    coordinates
  }
  distanceMeters
  durationSeconds
  durationInTrafficSeconds
}
originTimezone
timeline {
  originArrivalTime
  finishPackingTime
  finishLoadingTruckTime
  originDepartureTime
  stops
  destinationArrivalTime
  finishUnloadingTruckTime
  destinationDepartureTime
}
moveDurationSeconds
requiredMovingService
promoCode {
  code
  type
  value
}
appliedPromoCode {
  code
  type
  value
}
`

export const moveNoteFields = `
note
attachments {
  id
  createdAt
  filename
  mimeType
  url
}
`

export const moveFieldsForPartner = `
${commonMoveDetailsFields}
user {
  ${userFieldsForPartner}
}
notes {
  fromPartner {
    companyBranchId
    note {
      ${moveNoteFields}
    }
  }
  toPartner {
    ${moveNoteFields}
  }
  fromCustomer {
    ${moveNoteFields}
  }
}
`
