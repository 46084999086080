import { useLazyData } from '../../graphQl'
import { type DateRange } from './adminAccounting.types'
import * as queries from './adminAccounting.queries'

/**
 * return sales report (base64 csv file content)
 */
export const useSalesReportExport = () => {
  const { fetch, ...data } = useLazyData<string>(
    queries.exportSalesReport,
    {
      reload: true,
    },
  )
  return {
    exportReport: async (dateRange: DateRange) => await fetch({ dateRange }),
    ...data,
  }
}

/**
 * return customers balance report (base64 csv file content)
 */
export const useCustomerBalanceReportExport = () => {
  const { fetch, ...data } = useLazyData<string>(
    queries.exportCustomersBalanceReport,
    {
      reload: true,
    },
  )
  return {
    exportReport: async (dateRange: DateRange) => await fetch({ dateRange }),
    ...data,
  }
}
