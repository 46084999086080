import { useTranslation } from 'react-i18next'
import MessageIcon from '@mui/icons-material/ChatOutlined'

import {
  type JobForPartner,
  useDeleteJobNoteAttachmentAction,
  useUpdateJobNoteAction,
  useUploadJobNoteAttachmentAction,
} from '../../../modules/jobs'
import { useCurrentFullCompanyBranch } from '../../../modules/companies'
import Paper from '../../../components/Paper'
import MoveNote,
{
  AuthorSource,
  MoveNotes,
} from '../../../components/MoveNote'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { errorNotification, successNotification } from '../../../components/ToastNotifications'

type NotesProps = {
  job: JobForPartner
}

const Notes: React.FC<NotesProps> = ({
  job,
}) => {
  const { t } = useTranslation()
  const { updateJobNote } = useUpdateJobNoteAction()
  const { uploadJobNoteAttachment } = useUploadJobNoteAttachmentAction()
  const { deleteJobNoteAttachment } = useDeleteJobNoteAttachmentAction()
  const formatError = useErrorFormatter()

  const currentCompanyBranch = useCurrentFullCompanyBranch()

  if (!currentCompanyBranch) {
    return null
  }

  const note = job.move.notes?.fromPartner?.find(note => note.companyBranchId === currentCompanyBranch.id)?.note

  const handleUpdateNote = async (note: string | null) => {
    try {
      await updateJobNote(job.id, { note })
      successNotification(t('pages.companyBranch.job.notes.updateSuccess'))
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const handleUpload = async (file: File) => {
    try {
      await uploadJobNoteAttachment(job.id, { file })
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const handleDeleteFile = async (fileId: string) => {
    try {
      await deleteJobNoteAttachment(job.id, { fileId })
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  return (
    <Paper>
      <h2 className="mb-4 font-body2 text-2xl font-semibold text-slate-700">
        <MessageIcon />
        { ' ' }
        { t('pages.companyBranch.job.sections.notes') }
      </h2>

      <MoveNotes>
        <MoveNote
          note={job.move.notes?.toPartner}
          author={{
            source: AuthorSource.MovingWaldo,
          }}
        />
        <MoveNote
          note={job.move.notes?.fromCustomer}
          author={{
            source: AuthorSource.Customer,
            user: job.move.user,
          }}
        />
        <div className="mb-2">
          <h3 className="font-body2 text-xl font-semibold text-slate-700">
            { t('pages.companyBranch.job.notes.internalNote') }
          </h3>
          <em className="text-sm text-neutral-500">
            { t('pages.companyBranch.job.notes.internalNotePrivate') }
          </em>
        </div>
        <MoveNote
          note={note}
          author={{
            source: AuthorSource.Partner,
            companyBranch: currentCompanyBranch,
          }}
          isAuthor
          onEdit={handleUpdateNote}
          onUpload={handleUpload}
          onDeleteFile={handleDeleteFile}
        />
      </MoveNotes>
    </Paper>
  )
}

export default Notes
