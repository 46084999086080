import {
  type DataOptions,
  useData,
  useLazyData,
  usePaginatedData,
} from '../../graphQl'
import { type FullCompanyBranch, type CompanyBranchCoordinates } from './adminCompanies.models'
import * as queries from './adminCompanies.queries'

/**
 * returns all companies branches coordinates to display them on a map
 */
export const useCompaniesBranchesCoordinates = (options?: DataOptions) => {
  return useData<[CompanyBranchCoordinates]>(queries.getCompaniesBranchesCoordinates, options)
}

/**
 * return base64 csv file containing all companies formatted for the blog
 */
export const useCompaniesExportForBlog = () => {
  const { fetch: exportCompaniesForBlog, ...data } = useLazyData<string>(queries.exportCompaniesForBlog, { reload: true })
  return {
    exportCompaniesForBlog,
    ...data,
  }
}

/**
 * returns all companies branches
 * query optimized for data table
 */
export const useCompaniesBranches = () => {
  const {
    fetch: fetchCompaniesBranches,
    ...data
  } = usePaginatedData<FullCompanyBranch>(queries.getCompaniesBranches)

  return {
    fetchCompaniesBranches,
    ...data,
  }
}
