import { Permission, usePermission } from '../permissions'

/**
 * helper hook to know if use is admin or partner
 */
export const useCurrentUserAccountType = () => {
  return {
    isAdmin: usePermission(Permission.adminAccess),
    isPartner: usePermission(Permission.partnerAccess),
  }
}
