import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import Spinner from '../../../Spinner'
import { useCurrentUser } from '../../../../modules/users'
import {
  Row,
  Cell,
  Label,
  Value,
} from '../../../cell'
import { formatPhoneNumber } from '../../../../common/phoneNumber'

const PLACEHOLDER = '—'

const DisplayPersonalInformations: React.FC = () => {
  const { t } = useTranslation()
  const { data: user } = useCurrentUser()

  if (!user) {
    return <Spinner />
  }

  return (
    <>
      <Row>
        <Cell>
          <Label>{ t('forms.fields.firstName.label') }</Label>
          <Value>{ user?.firstName ?? PLACEHOLDER }</Value>
        </Cell>
        <Cell>
          <Label>{ t('forms.fields.lastName.label') }</Label>
          <Value>{ user?.lastName ?? PLACEHOLDER }</Value>
        </Cell>
      </Row>

      <Row>
        <Cell>
          <Label>{ t('forms.fields.phoneNumber.label') }</Label>
          <Value>{ user?.phoneNumber ? formatPhoneNumber(user?.phoneNumber) : PLACEHOLDER }</Value>
        </Cell>
        <Cell>
          <Label>{ t('forms.fields.position.label') }</Label>
          <Value>{ user?.position ?? PLACEHOLDER }</Value>
        </Cell>
      </Row>

      <Row>
        <Cell>
          <Label>{ t('forms.fields.email.label') }</Label>
          <Value>{ user?.email }</Value>
          { !user?.externalProvider && (
            <Box mt={1}>
              { user?.emailVerified
                ? (
                  <Chip color="success" label={t('status.verified')} size="small" icon={<CheckIcon />} />
                  )
                : (
                  <Chip label={t('status.unverified')} size="small" icon={<CloseIcon />} />
                  ) }
            </Box>
          ) }
        </Cell>
      </Row>
    </>
  )
}

export default DisplayPersonalInformations
