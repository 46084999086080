import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import CopyIcon from '@mui/icons-material/ContentCopy'

import { mergeClassName } from '../../utils/mergeClassName'

const COPIED_DURATION = 1000

type CopyableTextProps = {
  text?: string | undefined | null
  children?: React.ReactNode
  className?: string
}

const CopyableText: React.FC<CopyableTextProps> = ({
  text,
  children,
  className,
}) => {
  const { t } = useTranslation()
  const [displayCopied, setDisplayCopied] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplayCopied(false)
    }, COPIED_DURATION)

    return () => { clearTimeout(timeout) }
  }, [setDisplayCopied, displayCopied])

  if (!text) {
    return null
  }

  const onClick = () => {
    navigator.clipboard.writeText(text).catch(console.error)
    setDisplayCopied(true)
  }

  return (
    <div className={mergeClassName(
      'flex items-center gap-x-2 border p-2 rounded-md',
      className,
    )}
    >
      <div className="text-neutral-400">
        { children ?? text }
      </div>
      <div className="min-w-[85px] shrink-0 text-right">
        <Button
          endIcon={<CopyIcon />}
          size="small"
          color="secondary"
          className={mergeClassName(
            displayCopied && '!text-green-700',
          )}
          onClick={onClick}
        >
          { displayCopied ? `${t('status.copied')}!` : t('actions.copy') }
        </Button>
      </div>
    </div>
  )
}

export default CopyableText
