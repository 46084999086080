import Language from '../../app/Language.enum'
import { useLanguage } from '../../modules/i18n'
import { mergeClassName } from '../../utils/mergeClassName'
import { capitalizeFirstLetter } from '../../utils/string'
import { AuthorSource, type Author } from './MoveNote.types'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

type AuthorNameProps = {
  author: Author
} & ReactDiv

const AuthorName: React.FC<AuthorNameProps> = ({
  author,
  className,
  ...props
}) => {
  const language = useLanguage()

  const name = (() => {
    switch (author.source) {
      case AuthorSource.MovingWaldo:
        return 'MovingWaldo'
      case AuthorSource.Customer:
      {
        return [
          capitalizeFirstLetter(author.user.firstName),
          capitalizeFirstLetter(author.user.lastName),
        ].join(' ')
      }

      case AuthorSource.Partner:
      {
        return author.companyBranch.company?.identification?.name?.[language as Language] ??
        author.companyBranch.company?.identification?.name?.[Language.En]
      }
    }
  })()

  if (!name) {
    return null
  }

  return (
    <div
      className={mergeClassName(
        'text-lg font-bold text-neutral-800',
        className,
      )}
      {...props}
    >
      { name }
    </div>
  )
}

export default AuthorName
