import { getCurrencyForCountry } from '../../../../utils/money'
import { type CompanyBranch } from '../companies.models'
import { useCurrentCompanyBranch } from '..'

/**
 * lazy function to get the currency of a branch (defaults to CAD)
 * currency is based on trucks origin location
 */
export const useGetCompanyBranchCurrency = () => {
  return (companyBranch?: CompanyBranch) => {
    const trucksOriginCountry = companyBranch?.tripAndTravel?.trucksOriginAddress?.country
    return getCurrencyForCountry(trucksOriginCountry)
  }
}

/**
 * returns company branch currency
 */
export const useCompanyBranchCurrency = (companyBranch?: CompanyBranch) => {
  return useGetCompanyBranchCurrency()(companyBranch)
}

/**
 * returns currently selected company branch currency
 */
export const useCurrentCompanyBranchCurrency = () => {
  const { data: currentBranch } = useCurrentCompanyBranch()
  return useCompanyBranchCurrency(currentBranch)
}
