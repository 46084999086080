import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import Button from '@mui/material/Button'

import Tooltip, { type TooltipProps } from '../Tooltip'
import { mergeClassName } from '../../utils/mergeClassName'
import {
  Modal,
  ModalDescription,
  ModalHeader,
  ModalTitle,
  ModalFooter,
} from '../Modal'

type InfoTooltipProps = {
  tooltip: React.ReactNode
  modal?: boolean
  className?: string
  tooltipProps?: Omit<TooltipProps, 'tooltip'>
  modalTitle?: React.ReactNode
  modalWidth?: number
}

const InfoTooltip: React.FC<InfoTooltipProps> = ({
  tooltip,
  modal = false,
  className,
  tooltipProps,
  modalTitle,
  modalWidth = 800,
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const handleTooltipIconClick = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setOpen(true)
  }

  if (modal) {
    return (
      <>
        <InfoIcon
          color="info"
          className={mergeClassName('cursor-pointer -mt-1', className)}
          onClick={handleTooltipIconClick}
        />
        <Modal
          open={open}
          onClose={() => { setOpen(false) }}
          style={{ maxWidth: modalWidth }}
        >
          { modalTitle && (
            <ModalHeader>
              <ModalTitle>
                { modalTitle }
              </ModalTitle>
            </ModalHeader>
          ) }
          <ModalDescription>
            <div className="font-body prose max-w-none prose-headings:font-body2">
              { tooltip }
            </div>
          </ModalDescription>
          <ModalFooter>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => { setOpen(false) }}
            >
              { t('actions.close') }
            </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }

  return (
    <Tooltip
      tooltip={(
        <div className="font-sm prose max-w-[90vw] p-2 font-sans prose-headings:text-base prose-headings:leading-tight prose-li:my-1 prose-li:text-sm lg:max-w-xl">
          { tooltip }
        </div>
      )}
      offset={10}
      {...tooltipProps}
    >
      <InfoIcon
        color="info"
        className={mergeClassName('-mt-1', className)}
      />
    </Tooltip>
  )
}

export default InfoTooltip
