import { mergeClassName } from '../../utils/mergeClassName'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

type MoveNotesProps = {
  children?: React.ReactNode
} & ReactDiv

const MoveNotes: React.FC<MoveNotesProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={mergeClassName(
        'flex flex-col gap-y-6 py-4',
        className,
      )}
      {...props}
    >
      { children }
    </div>
  )
}

export default MoveNotes
