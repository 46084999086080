import { differenceInDays } from 'date-fns/differenceInDays'

import {
  type Quote,
  type QuoteAlternativeDate,
  type QuoteForPartner,
} from '../quotes.models'
import { type FullMove } from '../../../adminMoves'
import { type FullCompanyBranch } from '../../../adminCompanies'

/**
 * returns the cheapest quote price
 * this take into account the availabilities and whether or not the date is flexible
 */
export const getCheapestQuotePrice = (move: FullMove) => {
  const cheapest = move.quotes?.reduce((cheapest, quote) => {
    if (move.flexibleDate) {
      const cheapestPrice = getCheapestAlternativeDate(quote, move)?.subtotal.price
      if (!cheapestPrice) {
        return cheapest
      }
      return Math.min(cheapest, cheapestPrice)
    }
    return Math.min(cheapest, quote.subtotal.price)
  }, Infinity)

  if (
    cheapest === Infinity ||
    cheapest === undefined ||
    !move.quotes?.[0].subtotal.currency
  ) {
    return undefined
  }
  return { price: cheapest, currency: move.quotes?.[0].subtotal.currency }
}

/**
 * returns cheapest price for an alternative date (if any)
 */
export const getCheaperQuotePrice = (quote: Quote) => {
  return getCheapestAlternativeDate(quote)?.subtotal
}

/**
 * returns cheapest alternative date (if any)
 */
export const getCheaperQuoteDate = (quote: Quote, move: FullMove) => {
  return getCheapestAlternativeDate(quote, move)?.date
}

/**
 * this returns the cheapest alternative date
 * if a move is provided, we make sure to select the closest date to the moving date
 */
export const getCheapestAlternativeDate = (quote: Quote, move?: FullMove): QuoteAlternativeDate | undefined => {
  if (!quote.alternativeDates) {
    return
  }
  const cheaperDates: Array<[QuoteAlternativeDate, number]> = []

  quote.alternativeDates.forEach((alternativeDate) => {
    if (!alternativeDate.dateAvailable) {
      return
    }
    if (alternativeDate.subtotal.price < quote.subtotal.price) {
      cheaperDates.push([
        alternativeDate,
        move ? Math.abs(differenceInDays(alternativeDate.date, move.movingDate)) : 0,
      ])
    }
  })

  if (cheaperDates.length === 0) {
    return
  }

  cheaperDates.sort(([,diffDaysA], [,diffDaysB]) => {
    return diffDaysA - diffDaysB
  })
  cheaperDates.sort(([alternativeDateA], [alternativeDateB]) => {
    return alternativeDateA.subtotal.price - alternativeDateB.subtotal.price
  })

  return cheaperDates[0][0]
}

/**
 * convert a partner quote into a full quote
 */
export const convertPartnerQuoteToFullQuote = (quote: QuoteForPartner, companyBranch: FullCompanyBranch): Quote => {
  return {
    customized: false,
    companyBranch,
    ...quote,
  }
}
