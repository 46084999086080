import { Trans, useTranslation } from 'react-i18next'
import WarningIcon from '@mui/icons-material/WarningAmber'
import CalendarIcon from '@mui/icons-material/CalendarMonthOutlined'
import ClockIcon from '@mui/icons-material/AccessTimeOutlined'
import TruckIcon from '@mui/icons-material/LocalShippingOutlined'
import ServiceIcon from '@mui/icons-material/HandymanOutlined'
import ScaleIcon from '@mui/icons-material/ScaleOutlined'

import { type Quote, QuoteConstraint, useQuoteConstraints } from '../../../../modules/quotes'
import { useMove } from '../../../../modules/adminMoves'
import { useDateFormatter } from '../../../../utils/date'
import Tooltip from '../../../Tooltip'
import { useParams } from '../../../../modules/navigation'

type QuoteConstraintsProps = {
  quote: Quote
  compact?: boolean
}

const QuoteConstraints: React.FC<QuoteConstraintsProps> = ({
  quote,
  compact = false,
}) => {
  const { t } = useTranslation()
  const { moveId } = useParams()
  const { data: move } = useMove(moveId)
  const constraints = useQuoteConstraints(quote)
  const formatDate = useDateFormatter()

  if (!move) {
    return null
  }

  const nbConstraints = constraints?.length ?? 0
  if (!constraints || nbConstraints === 0) {
    return null
  }

  const getConstraintIcon = (constraint: QuoteConstraint) => {
    switch (constraint) {
      case QuoteConstraint.unavailableDate:
        return <CalendarIcon className="!text-[22px]" />
      case QuoteConstraint.unavailablePreferredTime:
        return <ClockIcon className="!text-[22px]" />
      case QuoteConstraint.notEnoughTrucks:
        return <TruckIcon className="!text-[22px]" />
      case QuoteConstraint.additionalServicesNotOffered:
      case QuoteConstraint.specialServicesNotOffered:
        return <ServiceIcon className="!text-[22px]" />
      case QuoteConstraint.specialItemsNotSupported:
        return <ScaleIcon className="!text-[19px]" />
      default:
        return <WarningIcon className="!text-[22px]" />
    }
  }

  const getConstraintDescription = (constraint: QuoteConstraint) => {
    switch (constraint) {
      case QuoteConstraint.unavailableDate: {
        const MovingDate = () => (
          <strong>
            { formatDate(move.movingDate, { showYear: false, shortMonth: true }) }
          </strong>
        )
        const QuoteDate = () => (
          <strong>
            { formatDate(quote.date, { showYear: false, shortMonth: true }) }
          </strong>
        )
        return (
          <div>
            { move
              ? (
                <Trans i18nKey="quotes.constraints.unavailableDate">
                  <MovingDate />
                  <QuoteDate />
                </Trans>
                )
              : (
                <Trans i18nKey="quotes.constraints.unavailableDateLab">
                  <QuoteDate />
                </Trans>
                ) }
          </div>
        )
      }
      default:
        return t(`quotes.constraints.${constraint}`)
    }
  }

  if (compact) {
    return (
      <Tooltip
        tooltip={(
          <ul
            className={nbConstraints > 1 ? 'list-disc pl-2' : ''}
          >
            { constraints.map((constraint) => (
              <li key={constraint}>
                { getConstraintDescription(constraint) }
              </li>
            )) }
          </ul>
        )}
        className="bg-yellow-700 text-white"
      >
        <div className="flex items-center gap-1 rounded bg-orange-100 px-3 py-1 text-sm text-yellow-700">
          { nbConstraints > 1
            ? (
              <>
                { nbConstraints } <WarningIcon className="!text-[22px]" />
              </>
              )
            : (
                getConstraintIcon(constraints[0])
              ) }

        </div>
      </Tooltip>
    )
  }

  return (
    <div className="mb-3 flex flex-col gap-2 2xl:mb-6">
      { constraints?.map(constraint => {
        return (
          <div key={constraint} className="flex items-center gap-1 rounded bg-orange-100 px-3 py-1 text-sm text-yellow-700">
            { getConstraintIcon(constraint) }
            { ' ' }
            { getConstraintDescription(constraint) }
          </div>
        )
      }) }
    </div>
  )
}

export default QuoteConstraints
