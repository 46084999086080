import { useMutation } from '@apollo/client'

import * as mutations from './externalEvents.mutations'
import { type UpdateImportedCalendarsPayload } from './externalEvents.types'
import { useCurrentCompanyBranch } from '..'

/**
 * enable / disable calendar export
 */
export const useSetCalendarExportEnabledAction = () => {
  const [setCalendarExportEnabled, { data, loading, ...mutationProps }] = useMutation(mutations.setCalendarExportEnabled)
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()

  return {
    setCalendarExportEnabled: async (enabled: boolean) => {
      const { data } = await setCalendarExportEnabled({
        variables: {
          companyBranchId: currentCompanyBranch?.id,
          enabled,
        },
      })

      return data.setCalendarExportEnabled
    },
    data: data?.setCalendarExportEnabled,
    loading,
    ...mutationProps,
  }
}

/**
 * update calendar secret url
 */
export const useUpdateCalendarUrlAction = () => {
  const [updateCalendarUrl, { data, loading, ...mutationProps }] = useMutation(mutations.updateCalendarUrl)
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()

  return {
    updateCalendarUrl: async () => {
      const { data } = await updateCalendarUrl({
        variables: {
          companyBranchId: currentCompanyBranch?.id,
        },
      })

      return data.updateCalendarUrl
    },
    data: data?.updateCalendarUrl,
    loading,
    ...mutationProps,
  }
}

/**
 * update calendar secret url
 */
export const useSetImportedCalendarsAction = () => {
  const [setImportedCalendars, { data, loading, ...mutationProps }] = useMutation(mutations.setImportedCalendars)
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()

  return {
    setImportedCalendars: async (payload: UpdateImportedCalendarsPayload) => {
      const { data } = await setImportedCalendars({
        variables: {
          companyBranchId: currentCompanyBranch?.id,
          payload,
        },
      })

      return data.setImportedCalendars
    },
    data: data?.setImportedCalendars,
    loading,
    ...mutationProps,
  }
}
