import { useTranslation } from 'react-i18next'
import { differenceInMilliseconds } from 'date-fns/differenceInMilliseconds'
import DownloadIcon from '@mui/icons-material/SimCardDownloadOutlined'
import Button from '@mui/material/Button'
import VoidIcon from '@mui/icons-material/CancelOutlined'

import { type FullMove } from '../../../../../modules/adminMoves'
import { Permission, usePermission } from '../../../../../modules/users'
import { useConfig } from '../../../../../modules/config'
import { useGetInvoiceLink, useVoidMoveInvoiceAction } from '../../../../../modules/invoices'
import Language from '../../../../../app/Language.enum'
import { mergeClassName } from '../../../../../utils/mergeClassName'
import { useDateFormatter } from '../../../../../utils/date'
import { formatPrice } from '../../../../../utils/money'
import Table, {
  Column,
  Row,
  Cell,
} from '../../../../../components/Table'
import Link, {
  LinkVariant,
} from '../../../../../components/Link'
import { useErrorFormatter } from '../../../../../components/errors/useErrorFormatter'
import { errorNotification, successNotification } from '../../../../../components/ToastNotifications'
import ConfirmationModal from '../../../../../components/ConfirmationModal'

type InvoicesTableProps = {
  move: FullMove
}

const InvoicesTable: React.FC<InvoicesTableProps> = ({
  move,
}) => {
  const { t } = useTranslation()
  const formatDate = useDateFormatter()
  const getInvoiceLink = useGetInvoiceLink()
  const [voidInvoice] = useVoidMoveInvoiceAction()
  const formatError = useErrorFormatter()
  const canChargeCustomer = usePermission(Permission.canChargeCustomers)
  const invoiceDateMaxDelay = useConfig<number>('instantBooking.invoice.invoiceDateMaxDelay')
  const now = new Date()

  const handleVoidInvoice = async (invoiceId: string) => {
    try {
      await voidInvoice(move.id, invoiceId)
      successNotification(t('invoice.voidedSuccess'))
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  if (move.invoices.length === 0) {
    return (
      <div className="pb-4 text-center text-neutral-500">
        { t('invoice.table.empty') }
      </div>
    )
  }

  return (
    <Table
      columns={(
        <>
          <Column
            className="min-w-[275px]"
          >
            { t('invoice.table.columns.invoiceDate') }
          </Column>

          <Column expand>
            { t('invoice.table.columns.object') }
          </Column>

          <Column
            right
            className="min-w-[200px]"
          >
            { t('invoice.table.columns.subtotal') }
          </Column>

          <Column
            right
            className="min-w-[200px]"
          >
            { t('invoice.table.columns.total') }
          </Column>

          { canChargeCustomer && (
            <Column />
          ) }
        </>
      )}
      className="lg:max-w-[calc(100vw-200px)]"
    >
      { move.invoices.map(invoice => {
        const invoiceAge = differenceInMilliseconds(now, invoice.invoiceDate)
        const withinRange = invoiceAge < invoiceDateMaxDelay
        const canBeVoided = !invoice.voided && withinRange

        return (
          <Row
            key={invoice.id}
            className={mergeClassName(
              invoice.voided && 'bg-red-50 child:text-red-400',
            )}
            disableHover={invoice.voided}
          >
            <Cell noWrap>
              { formatDate(invoice.invoiceDate) }
            </Cell>

            <Cell noWrap>
              <div className="flex gap-2">
                <div className={mergeClassName(
                  invoice.voided && 'line-through',
                )}
                >
                  <strong>
                    { invoice.invoiceNumber }
                  </strong>
                  <span className="text-sm text-neutral-500">
                    { ' ( ' }
                    { t(`invoice.table.category.${invoice.category}`) }
                    { ' )' }
                  </span>
                </div>
                { invoice.voided && (
                  <div className="font-bold uppercase text-red-600">
                    { t('invoice.table.voided') }
                  </div>
                ) }
              </div>

              <div className="flex gap-x-8 text-sm text-neutral-400">
                <Link
                  to={getInvoiceLink(invoice, { moveId: move.id, language: Language.En })}
                  variant={LinkVariant.Underlined}
                  target="_blank"
                  className="inline-flex gap-x-1"
                >
                  { t('invoice.table.downloadInvoiceEn') }
                  <DownloadIcon className="!text-[20px]" />
                </Link>

                <Link
                  to={getInvoiceLink(invoice, { moveId: move.id, language: Language.Fr })}
                  variant={LinkVariant.Underlined}
                  target="_blank"
                  className="inline-flex gap-x-1"
                >
                  { t('invoice.table.downloadInvoiceFr') }
                  <DownloadIcon className="!text-[20px]" />
                </Link>
              </div>
            </Cell>

            <Cell
              right
              noWrap
            >
              <div className={mergeClassName(
                invoice.subtotal.price < 0 && 'text-red-400',
              )}
              >
                { formatPrice(invoice.subtotal) }
              </div>
            </Cell>

            <Cell
              right
              noWrap
            >
              <div className={mergeClassName(
                'font-bold',
                invoice.total.price < 0 && 'text-red-400',
              )}
              >
                { formatPrice(invoice.total, { showCurrency: true }) }
              </div>
            </Cell>

            { canChargeCustomer && (
              <Cell>
                <ConfirmationModal
                  question={t('invoice.voidConfirmation')}
                  onConfirm={async () => { await handleVoidInvoice(invoice.id) }}
                  disabled={!canBeVoided}
                >
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    className="!normal-case"
                    startIcon={<VoidIcon />}
                    disabled={!canBeVoided}
                  >
                    { t('actions.void') }
                  </Button>
                </ConfirmationModal>
              </Cell>
            ) }
          </Row>
        )
      }) }
    </Table>
  )
}

export default InvoicesTable
