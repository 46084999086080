/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { useTranslation } from 'react-i18next'

import { type JobRequestForPartner, type LightJobRequest } from './jobRequests.models'
import { useDateFormatter } from '../../../utils/date'
import { type ArrivalWindow } from '../../companies'
import { calculateNumberOfTrucks } from '../../quotes'
import { getMoveDate } from '../../moves'

type SummaryData = {
  movingDate: Date
  movingTime?: ArrivalWindow
  nbMen: number
  nbTrucks: number
}

/**
 * this returns a one line summary for a job request
 * ex: September 27th, 2024 @ 8/9 am - 4 men + 1 truck
 */
export const useGetJobRequestSummary = () => {
  const { t } = useTranslation()
  const formatDate = useDateFormatter()
  const getJobRequestData = useGetJobRequestData()
  const getLightJobRequestData = useGetLightJobRequestData()

  // eslint-disable-next-line react/display-name
  return (jobRequest: JobRequestForPartner | LightJobRequest) => {
    const data = 'move' in jobRequest ? getJobRequestData(jobRequest) : getLightJobRequestData(jobRequest)
    const {
      movingDate,
      movingTime,
      nbMen,
      nbTrucks,
    } = data

    /* when using compact mode, mimic the email subjects */
    const date = formatDate(movingDate)
    const time = movingTime
      ? `@ ${t(`arrivalWindows.${movingTime}.shortTitle`, { defaultValue: movingTime })}`
      : ''
    const men = t('nbMen', { count: nbMen })
    const truck = t('nbTrucks', { count: nbTrucks })

    return <>{ date } { time } - { men } + { truck }</>
  }
}

/**
 * get data from full job request models
 */
const useGetJobRequestData = () => {
  return (jobRequest: JobRequestForPartner): SummaryData => {
    return {
      movingDate: getMoveDate(jobRequest.quote.date, jobRequest.move.originTimezone),
      movingTime: jobRequest.move.quoteAccepted?.movingTime,
      nbMen: jobRequest.quote.resolvedNbMen,
      nbTrucks: calculateNumberOfTrucks(jobRequest.quote.resolvedNbTrucks),
    }
  }
}

/**
 * get data from light job request object
 * light objects are used when fetching a collection
 */
const useGetLightJobRequestData = () => {
  return (jobRequest: LightJobRequest): SummaryData => {
    return {
      movingDate: jobRequest.movingDate,
      movingTime: jobRequest?.movingTime,
      nbMen: jobRequest.nbMen,
      nbTrucks: calculateNumberOfTrucks(jobRequest.nbTrucks),
    }
  }
}
