import { type Amount } from '../../../../common/amount'
import { type Invoice } from '../invoices.types'

/**
 * returns the total amount from a collection of invoices
 */
export const useGetInvoicesTotalCharge = () => {
  return (invoices?: Invoice[]) => {
    if (!invoices) {
      return
    }
    return invoices
      .filter(invoice => !invoice.voided)
      .reduce<Amount | undefined>((acc, curr) => {
      if (!acc) {
        return curr.total
      }
      return {
        price: acc.price + curr.total.price,
        currency: acc.currency,
      }
    }, undefined)
  }
}
