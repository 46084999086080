import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import RestoreIcon from '@mui/icons-material/RestoreFromTrash'

import { Permission, usePermission } from '../../../modules/users'
import { useNavigate } from '../../../modules/navigation'
import {
  useDeletePromoCodesAction,
  useRestorePromoCodesAction,
  type FullPromoCode,
} from '../../../modules/adminPromoCodes'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { useConfirmation } from '../../../components/ConfirmationModal'
import Route from '../../../app/Route.enum'

/**
 * returns the list of actions available inside of the data grid
 */
export const useActions = (promoCodes?: FullPromoCode[]) => {
  const { t } = useTranslation()
  const { navigate } = useNavigate()
  const formatError = useErrorFormatter()
  const { deletePromoCodes } = useDeletePromoCodesAction()
  const { restorePromoCodes } = useRestorePromoCodesAction()
  const { confirm, modalProps } = useConfirmation()
  const canManagePromoCodes = usePermission(Permission.canManagePromoCodes)

  if (!canManagePromoCodes) {
    return {}
  }

  /**
   * delete promo codes
   *  - show confirmation modal
   *  - show results in a toast
   */
  const handleDelete = async (ids: string | string[]) => {
    ids = Array.isArray(ids) ? ids : [ids]
    const count = ids.length

    const title = t('pages.admin.promoCodes.actions.delete.confirmation.title', { count })
    const question = t('pages.admin.promoCodes.actions.delete.confirmation.question', { count })

    if (!await confirm({ title, question, danger: true })) {
      return false
    }

    const pending = t('pages.admin.promoCodes.actions.delete.pending', { count })
    const success = t('pages.admin.promoCodes.actions.delete.success', { count })

    await toast.promise(
      deletePromoCodes(ids),
      {
        pending,
        success,
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  /**
   * restore promo codes
   *  - show confirmation modal
   *  - show results in a toast
   */
  const handleRestore = async (ids: string | string[]) => {
    ids = Array.isArray(ids) ? ids : [ids]
    const count = ids.length

    const title = t('pages.admin.promoCodes.actions.restore.confirmation.title', { count })
    const question = t('pages.admin.promoCodes.actions.restore.confirmation.question', { count })

    if (!await confirm({ title, question, danger: true })) {
      return false
    }

    const pending = t('pages.admin.promoCodes.actions.restore.pending', { count })
    const success = t('pages.admin.promoCodes.actions.restore.success', { count })

    await toast.promise(
      restorePromoCodes(ids),
      {
        pending,
        success,
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  /**
   * edit promo code
   */
  const handleEdit = (id: string) => {
    navigate(Route.EditPromoCode, { promoCodeId: id })
  }

  /* format actions for data grid */

  const editAction = {
    label: t('actions.edit'),
    icon: EditIcon,
    callback: handleEdit,
  }

  const deleteAction = {
    label: t('actions.delete'),
    icon: DeleteIcon,
    danger: true,
    callback: handleDelete,
    bulkCallback: handleDelete,
    filter: (ids: string[]) => {
      const selected = promoCodes?.filter(promoCode => ids.includes(promoCode.id)) ?? []
      const elligibles = selected?.filter(promoCode => {
        return !promoCode.deletedAt
      })
      return elligibles?.length > 0 && selected?.length === elligibles?.length
    },
  }

  const restoreAction = {
    label: t('actions.restore'),
    icon: RestoreIcon,
    danger: true,
    callback: handleRestore,
    bulkCallback: handleRestore,
    filter: (ids: string[]) => {
      const selected = promoCodes?.filter(promoCode => ids.includes(promoCode.id)) ?? []
      const elligibles = selected?.filter(promoCode => {
        return !!promoCode.deletedAt
      })
      return elligibles?.length > 0 && selected?.length === elligibles?.length
    },
  }

  /* returns actions and the props needed to render the confirmation modal */

  return {
    actions: [
      editAction,
      deleteAction,
      restoreAction,
    ],
    modalProps,
  }
}
