import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Chip from '@mui/material/Chip'

import { ReviewApprobation } from '../../../modules/companyRatings'
import { mergeClassName } from '../../../utils/mergeClassName'

type ReviewApprobationLabelProps = {
  reviewApprobation?: ReviewApprobation
}

const ReviewApprobationLabel: React.FC<ReviewApprobationLabelProps> = ({
  reviewApprobation,
}) => {
  const { t } = useTranslation()

  const labelClassName = useMemo(() => {
    switch (reviewApprobation) {
      case ReviewApprobation.Approved:
        return '!bg-green-800 !text-white'

      case ReviewApprobation.Rejected:
        return '!bg-red-800 !text-white'

      case ReviewApprobation.Pending:
        return '!bg-orange-300'
    }
  }, [reviewApprobation])

  if (!reviewApprobation) {
    return null
  }

  const label = t(`reviewApprobation.${reviewApprobation}`, { defaultValue: reviewApprobation })

  return (
    <Chip
      className={mergeClassName(
        labelClassName,
        '!text-xs',
      )}
      label={label}
      size="small"
    />
  )
}

export default ReviewApprobationLabel
