import {
  type DataOptions,
  usePaginatedData,
  useData,
} from '../../graphQl'
import * as queries from './promoCodes.queries'
import { type FullPromoCode } from './promoCodes.models'

/**
 * returns all promo codes
 * query optimized for data table
 */
export const usePromoCodes = () => {
  const { fetch: fetchPromoCodes, ...data } = usePaginatedData<FullPromoCode>(queries.getPromoCodes)
  return { fetchPromoCodes, ...data }
}

/**
 * returns promocode detail
 */
export const usePromoCode = (promoCodeId: string, options?: DataOptions) => {
  return useData<FullPromoCode>(queries.getPromoCode, {
    ...options,
    variables: {
      promoCodeId,
    },
  })
}
