import * as Yup from 'yup'

import { useFloatValidator } from '../../../../utils/number'

const useChargesValidation = () => {
  const floatValidator = useFloatValidator()

  return Yup.array()
    .of(
      Yup.object({
        quantity: floatValidator({ min: -99999, max: 99999 }),
        unitPrice: floatValidator({ min: 0, max: 99999 }),
        item: Yup.string().required(),
      }),
    )
}

export default useChargesValidation
