/* eslint-disable @typescript-eslint/no-misused-promises */
import { Fragment } from 'react'
import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { type FullMove } from '../../../../modules/adminMoves'
import { mergeClassName } from '../../../../utils/mergeClassName'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from '../../../../components/DropDownMenu'
import { useActions } from '../useActions'
import ConfirmationModal from '../../../../components/ConfirmationModal'
import MoveActionButton from '../MoveActionButton'
import { useNextAction } from '../useNextAction'

type ActionsDropdownProps = {
  move: FullMove
}

const ActionsDropdown: React.FC<ActionsDropdownProps> = ({
  move,
}) => {
  const { actions, modalProps } = useActions(move)
  const nextAction = useNextAction(move)
  const displayActions = Object.entries(actions).filter(([, action]) => !!action.global && !action.hidden)

  return (
    <>
      <DropdownMenu>
        <ButtonGroup
          variant="contained"
          color="secondary"
          className="mt-[-10px] min-h-[50px] !rounded-3xl !normal-case"
          fullWidth
          disableFocusRipple
          size="large"
        >
          <MoveActionButton
            move={move}
            className="!justify-start !rounded-l-3xl !bg-slate-500 !pl-6 !text-lg !normal-case disabled:!bg-gray-200 xl:!text-[1.1rem]"
            actionName={nextAction}
          />
          <DropdownMenuTrigger asChild>
            <Button
              size="small"
              className="!w-[50px] !rounded-r-3xl !bg-slate-500/90 !normal-case"
            >
              <KeyboardArrowDownIcon className="mr-1" />
            </Button>
          </DropdownMenuTrigger>
        </ButtonGroup>

        <DropdownMenuContent
          align="end"
          collisionPadding={20}
          className="ml-[5px] w-[calc(100vw-50px)] overflow-auto shadow-2xl lg:ml-[80px] lg:w-[calc(100vw-120px)] 2xl:w-auto"
          style={{
            maxHeight: 'var(--radix-dropdown-menu-content-available-height)',
          }}
        >

          { displayActions.map(([name, action]) => {
            const Icon = action.Icon

            return (
              <Fragment key={name}>
                { action.addSeparator && <DropdownMenuSeparator /> }
                <DropdownMenuItem
                  className={mergeClassName(
                    action.enabled && 'cursor-pointer',
                    action.enabled && action.danger && 'child:text-red-700',
                  )}
                  disabled={!action.enabled}
                  onClick={action.callback}
                >
                  <div className="flex w-full items-center gap-x-2 px-2 py-1 font-sans text-base text-neutral-800">
                    <div className="w-[25px]">
                      { Icon && <Icon className="max-w-[20px] !text-[20px]" /> }
                    </div>
                    { action.label }
                  </div>
                </DropdownMenuItem>
              </Fragment>
            )
          }) }

        </DropdownMenuContent>
      </DropdownMenu>
      <ConfirmationModal {...modalProps} />
    </>
  )
}

export default ActionsDropdown
