import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'

import {
  Form,
  Input,
  Label,
  SubmitButton,
} from '../../../form'
import { type EditUtmFormValues } from './EditUtmForm.type'
import { type FullMove, type MoveUtmPayload } from '../../../../modules/adminMoves'
import { formatFormOutput } from './formDataBuilder'

type EditUtmFormProps = {
  onSubmit: (payload: MoveUtmPayload) => Promise<void>
  onCancel?: () => void
  data?: Pick<FullMove, 'utmSource' | 'utmMedium' | 'utmCampaign'>
}

const EditUtmForm: React.FC<EditUtmFormProps> = ({
  onSubmit,
  onCancel,
  data,
}) => {
  const { t } = useTranslation()

  const form = useForm<EditUtmFormValues>({
    defaultValues: data,
  })

  const handleSubmit = async (values: EditUtmFormValues) => { await onSubmit(formatFormOutput(values)) }

  return (
    <Form form={form} onSubmit={handleSubmit}>

      <div className="my-2 flex flex-col gap-y-2">
        <Label label={t('forms.fields.utmSource.label')}>
          <Input
            name="utmSource"
            size="small"
          />
        </Label>

        <Label label={t('forms.fields.utmMedium.label')}>
          <Input
            name="utmMedium"
            size="small"
          />
        </Label>

        <Label label={t('forms.fields.utmCampaign.label')}>
          <Input
            name="utmCampaign"
            size="small"
          />
        </Label>
      </div>

      <div className="mt-8 flex justify-end gap-3">
        { onCancel && (
          <Button
            variant="contained"
            color="secondary"
            onClick={onCancel}
            size="small"
          >
            { t('actions.cancel') }
          </Button>
        ) }
        <SubmitButton size="small">
          { t('actions.update') }
        </SubmitButton>
      </div>
    </Form>
  )
}

export default EditUtmForm
