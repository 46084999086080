import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '@mui/material/Alert'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import NoteIcon from '@mui/icons-material/DescriptionOutlined'
import UserIcon from '@mui/icons-material/PersonOutlined'
import QuoteIcon from '@mui/icons-material/RequestQuoteOutlined'
import TruckIcon from '@mui/icons-material/LocalShippingOutlined'
import PhoneIcon from '@mui/icons-material/PhoneOutlined'

import Route from '../../../app/Route.enum'
import { type JobRequestForPartner, JobRequestStatus, useGetJobRequestSummary } from '../../../modules/jobRequests'
import { capitalizeFirstLetter } from '../../../utils/string'
import PageContent from '../../../components/layout/PageContent'
import PageTitle from '../../../components/layout/PageTitle'
import Link from '../../../components/Link'
import { JobRequestStatusTag } from '../../../components/jobRequest'
import Notes from './Notes'
import Customer from './Customer'
import Estimate from './Estimate'
import Jobs from './Jobs'
import SidebarItem from './SidebarItem'
import JobRequestCta from './JobRequestCta'

type JobRequestPageContentProps = {
  jobRequest: JobRequestForPartner
}

const JobRequestPageContent: React.FC<JobRequestPageContentProps> = ({
  jobRequest,
}) => {
  const { t } = useTranslation()
  const summary = useGetJobRequestSummary()(jobRequest)

  const hasAgentNote = !!jobRequest.move.notes?.toPartner?.note
  const hasCustomerNote = !!jobRequest.move.notes?.fromCustomer
  const hasNotes = hasAgentNote || hasCustomerNote

  const isPending = jobRequest.status === JobRequestStatus.Pending

  const notesSection = useRef<HTMLDivElement>(null)
  const estimateSection = useRef<HTMLDivElement>(null)
  const customerSection = useRef<HTMLDivElement>(null)
  const jobsSection = useRef<HTMLDivElement>(null)

  const scrollToSection = (object: React.RefObject<HTMLDivElement>) => {
    if (!object.current) {
      return
    }
    object.current.scrollIntoView({ behavior: 'smooth' })
  }

  const customerFullName = [
    capitalizeFirstLetter(jobRequest.move.user.firstName),
    capitalizeFirstLetter(jobRequest.move.user.lastName),
  ].join(' ')

  return (
    <>
      <PageTitle>
        <div className="mb-4 flex flex-col items-center justify-between gap-y-8 lg:mb-0 lg:flex-row">

          <div>
            <Link to={Route.JobRequests} className="hidden lg:inline">
              <span className="ml-[-5px] text-base/loose underline">
                <ChevronLeftIcon />{ t('pages.companyBranch.jobRequests.title') }
              </span>
            </Link>
            <br />
            { customerFullName }
            <div className="mt-2 font-sans text-lg text-neutral-500">
              { summary }
            </div>
          </div>

          <div className="flex items-center justify-end gap-x-4">
            { isPending
              ? (
                <JobRequestCta jobRequest={jobRequest} />
                )
              : (
                <JobRequestStatusTag
                  jobRequestStatus={jobRequest.status}
                  className="!p-4 child:text-xl"
                />
                ) }
          </div>

        </div>
      </PageTitle>

      <PageContent className="pt-4 lg:pt-0">

        { isPending && (
          <Alert severity="info" className="my-4 !p-4 lg:my-6 [&>.MuiAlert-message]:w-full">

            <div className="flex flex-col justify-between gap-4 lg:flex-row lg:items-center">

              <div className="-mt-1 font-[Inter] text-lg">
                <strong>{ t('pages.companyBranch.jobRequest.info.weBookedAJob') }</strong>
                { ' ' }
                { t('pages.companyBranch.jobRequest.info.pleaseReview') }
                <ul className="list mt-2 list-disc text-sm child:ml-8">
                  <li>{ t('pages.companyBranch.jobRequest.info.dataIsReviewed') }</li>
                  <li>{ t('pages.companyBranch.jobRequest.info.youMustAccept') }</li>
                  <li>
                    { t('pages.companyBranch.jobRequest.info.customerPayRealTime') }
                  </li>

                </ul>
              </div>

              <div className="flex lg:shrink-0 lg:justify-end">
                <div className="rounded-lg bg-white/70 p-4 text-base">
                  <div className="text-lg">
                    { t('pages.companyBranch.jobRequest.info.haveQuestions') }
                  </div>
                  <PhoneIcon className="mr-2 !text-[18px]" />
                  <Link to="tel://18442586683">
                    1 (844) 258-6683
                  </Link>
                </div>
              </div>

            </div>
          </Alert>
        ) }

        <div className="flex gap-x-6">
          <div className="grow child:-mt-4 child:pt-4">
            { hasNotes && (
              <div ref={notesSection}>
                <Notes jobRequest={jobRequest} />
              </div>
            ) }

            <div ref={customerSection}>
              <Customer jobRequest={jobRequest} />
            </div>

            <div ref={estimateSection}>
              <Estimate jobRequest={jobRequest} />
            </div>

            <div ref={jobsSection}>
              <Jobs jobRequest={jobRequest} />
            </div>

            { isPending && (
              <div className="mb-3 flex justify-center gap-x-4 lg:justify-end">
                <JobRequestCta jobRequest={jobRequest} />
              </div>
            ) }
          </div>

          <div className="shink-0 hidden lg:block">
            <div className="sticky top-6 flex flex-col gap-y-6 child:cursor-pointer">

              { hasNotes && (
                <div onClick={() => { scrollToSection(notesSection) }}>
                  <SidebarItem icon={<NoteIcon />}>
                    { t('pages.companyBranch.jobRequest.sidebar.notes') }
                  </SidebarItem>
                </div>
              ) }

              <div onClick={() => { scrollToSection(customerSection) }}>
                <SidebarItem icon={<UserIcon />}>
                  { t('pages.companyBranch.jobRequest.sidebar.customer') }
                </SidebarItem>
              </div>

              <div onClick={() => { scrollToSection(estimateSection) }}>
                <SidebarItem icon={<QuoteIcon />}>
                  { t('pages.companyBranch.jobRequest.sidebar.estimate') }
                </SidebarItem>
              </div>

              <div onClick={() => { scrollToSection(jobsSection) }}>
                <SidebarItem icon={<TruckIcon />}>
                  { t('pages.companyBranch.jobRequest.sidebar.move') }
                </SidebarItem>
              </div>
            </div>
          </div>
        </div>

      </PageContent>
    </>
  )
}

export default JobRequestPageContent
