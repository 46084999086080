import { Fragment, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { type CompanyResponsePayload, useCompanyBranchRatingsforPartner, useUpdateRatingResponseAction } from '../../../modules/companyRatings'
import PageTitle from '../../../components/layout/PageTitle'
import PageContent from '../../../components/layout/PageContent'
import {
  DataGrid,
  type GridQueryVariables,
} from '../../../components/dataGrid'
import ServerError from '../../../components/errors/ServerError'
import Paper from '../../../components/Paper'
import { useCurrentFullCompanyBranch } from '../../../modules/companies'
import { Rating } from '../../../components/CompanyBranchRatings'
import { SortDirection } from '../../../modules/graphQl'
import RatingCta from './RatingCta'
import Sidebar from '../../../components/CompanyBranchRatings/Sidebar'
import JobSidepanel from '../job/JobSidepanel'
import { errorNotification, successNotification } from '../../../components/ToastNotifications'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'

const JobRequestsPageContent: React.FC = () => {
  const { t } = useTranslation()
  const currentCompanyBranch = useCurrentFullCompanyBranch()
  const [editingRatingId, setEditingRatingId] = useState<string>()
  const [selectedJobId, setSelectedJobId] = useState<string>()
  const [updateRatingResponse] = useUpdateRatingResponseAction()
  const {
    fetchCompanyRatings,
    data: companyRatings,
    error: companyRatingsErrors,
  } = useCompanyBranchRatingsforPartner()
  const formatError = useErrorFormatter()

  const totalResults = companyRatings?.count ?? 0

  const noResult = totalResults === 0

  const onQueryUpdate = useCallback(async (query: GridQueryVariables) => {
    return await fetchCompanyRatings(query.query)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (companyRatingsErrors) {
    return <ServerError error={companyRatingsErrors} />
  }

  if (!currentCompanyBranch) {
    return null
  }

  const handleResponse = async (payload: CompanyResponsePayload) => {
    try {
      await updateRatingResponse(payload.companyRatingId, payload.response)
      successNotification(t('pages.companyBranch.ratings.responseUpdateSuccess'))
    } catch (error) {
      errorNotification(formatError(error))
    }
    setEditingRatingId(undefined)
  }

  return (
    <>
      <PageTitle>
        { t('pages.companyBranch.ratings.title') }
      </PageTitle>
      <PageContent>
        <div className="pt-2 lg:pt-0">
          <div className="flex grow flex-col-reverse gap-x-4 xl:flex-row">
            <div className="grow">
              <DataGrid
                name="companyBranchRatings"
                count={companyRatings?.count ?? 0}
                onQueryUpdate={onQueryUpdate}
                searchEnabled={false}
                initialQuery={{
                  sortField: 'createdAt',
                  sortDirection: SortDirection.DESC,
                  nbResultsPerPage: 25,
                }}
                disabled={!!editingRatingId}
              >
                { companyRatings?.data?.map((companyRating) => {
                  const isEditing = companyRating.id === editingRatingId
                  const disabled = !isEditing && !!editingRatingId
                  return (
                    <div
                      key={companyRating.id}
                      className="my-4 rounded-lg border p-4 xl:my-6 xl:p-6 [&>div]:mb-0"
                    >
                      <Rating
                        rating={companyRating}
                        companyBranch={currentCompanyBranch}
                        hideOwnerResponse={isEditing}
                        onClick={companyRating.jobId && !editingRatingId
                          ? () => { setSelectedJobId(companyRating.jobId) }
                          : undefined}
                      >
                        <RatingCta
                          rating={companyRating}
                          companyBranch={currentCompanyBranch}
                          isEditing={isEditing}
                          onEdit={(isEditing) => {
                            setEditingRatingId(isEditing ? companyRating.id : undefined)
                          }}
                          onSubmit={handleResponse}
                          disabled={disabled}
                        />
                      </Rating>
                    </div>
                  )
                }) }

                { noResult && (
                  <Paper className="mt-6">
                    <em>
                      { t('pages.companyBranch.ratings.noRatings') }
                    </em>
                  </Paper>
                ) }

              </DataGrid>
            </div>
            <div className="shrink-0 px-4 xl:w-[400px] xl:p-8 xl:pt-20">
              <div className="top-[100px] child:mb-0 xl:sticky">
                <Sidebar
                  companyBranch={currentCompanyBranch}
                />
              </div>
            </div>
          </div>
        </div>
      </PageContent>
      { selectedJobId && (
        <JobSidepanel
          open
          jobId={selectedJobId}
          onClose={() => { setSelectedJobId(undefined) }}
        />
      ) }
    </>
  )
}

export default JobRequestsPageContent
