import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'

import { type CompanyResponsePayload, type CompanyRating } from '../../../../modules/companyRatings'
import { Form, SubmitButton, Textarea } from '../../../form'

type RatingResponseFormProps = {
  rating: CompanyRating
  onSubmit: (payload: CompanyResponsePayload) => void | Promise<void>
  onCancel?: () => void
}

const RatingResponseForm: React.FC<RatingResponseFormProps> = ({
  rating,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation()
  const form = useForm({
    defaultValues: {
      response: rating.ownerResponse,
    },
  })

  const handleSubmit = async (values: { response: string }) => {
    const response = String(values.response).trim()
    await onSubmit({
      companyRatingId: rating.id,
      response: response || null,
    })
  }

  return (
    <Form
      form={form}
      onSubmit={handleSubmit}
    >
      <label>
        <Textarea
          name="response"
          placeholder={t('forms.fields.ratingResponse.placeholder')}
          minRows={3}
          inputProps={{
            maxLength: 4096,
          }}
          className="bg-white"
          autoFocus
        />
      </label>
      { !rating.ownerResponse && (
        <div className="pt-1 text-sm text-neutral-400">
          { t('forms.companyBranch.ratingResponse.customerWillBeNotified') }
        </div>
      ) }

      <div className="flex justify-end gap-3 py-4">
        { onCancel && (
          <Button
            variant="contained"
            color="secondary"
            onClick={onCancel}
          >
            { t('actions.cancel') }
          </Button>
        ) }
        <SubmitButton>
          { t('actions.reply') }
        </SubmitButton>
      </div>
    </Form>
  )
}

export default RatingResponseForm
