import { type MoveUtmPayload } from '../../../../modules/adminMoves'
import { type EditUtmFormValues } from './EditUtmForm.type'

const replaceEmptyValueWithNull = (value?: string) => {
  const isEmpty = typeof value === 'undefined' || value === ''
  return isEmpty ? null : value
}

export const formatFormOutput = (data: EditUtmFormValues): MoveUtmPayload => {
  return {
    utmSource: replaceEmptyValueWithNull(data.utmSource),
    utmMedium: replaceEmptyValueWithNull(data.utmMedium),
    utmCampaign: replaceEmptyValueWithNull(data.utmCampaign),
  }
}
