import { type Currency, type Amount } from '../../../../../../common/amount'

export const getUnitPrice = (unitPrice?: string, currency?: Currency): Amount | undefined => {
  if (!unitPrice || !currency) {
    return
  }
  const amount = {
    price: parseFloat(unitPrice),
    currency,
  }
  if (isNaN(amount.price)) {
    amount.price = 0
  }
  return amount
}

export const getSubtotal = (unitPrice?: Amount, quantity: string = '0'): Amount | undefined => {
  if (!unitPrice) {
    return
  }
  const amount = {
    price: unitPrice.price * parseFloat(quantity),
    currency: unitPrice.currency,
  }
  if (isNaN(amount.price)) {
    amount.price = 0
  }
  return amount
}
