import { gql } from '@apollo/client'

import { getTranslationGql } from '../../../common/translation'

export const companyFields = `
id
createdAt
updatedAt
deletedAt
identification {
  ${getTranslationGql('name')}
}
logo {
  en
}
`

export const companyRatingFields = `
ratings {
  movingWaldo {
    rating
    nbRatings
    professionalism
    punctuality
    communication
  }
  google {
    rating
    nbRatings
  }
}`

export const companyBranchFields = `
id
createdAt
updatedAt
deletedAt
identification {
  ${getTranslationGql('name')}
}
approved
active
setupProgress
legal {
  legalName
  companyNumber
  dateOfIncorporation
  companyAddress {
    country
    street
    apartment
    city
    region
    postalCode
  }
  companyPhoneNumber {
    number
    extension
  }
  taxes {
    key
    value
  }
  owner {
    firstName
    lastName
    phoneNumber {
      number
      extension
    }
  }
  insurances {
    commercialLiabilityCoverageLimit
    cargoCoverageLimit
  }
  accreditations
  bbbRating
}
services {
  services
  additionalServices
  nbTrucks {
    key
    value
  }
  arrivalWindows
}
seasons {
  low {
    enabled
    from {
      day
      month
    }
    to {
      day
      month
    }
  }
  high {
    enabled
    from {
      day
      month
    }
    to {
      day
      month
    }
  }
}
labour {
  minLabourTime
  movingLabour {
    basePrice
    low
    high
  }
  movingLabourExtraMen {
    basePrice
    low
    high
  }
  movingLabourSingleMan {
    basePrice
    low
    high
  }
  
  packingLabour {
    basePrice
    low
    high
  }
  packingLabourExtraMen {
    basePrice
    low
    high
  }
  packingLabourSingleMan {
    basePrice
    low
    high
  }
}
tripAndTravel {
  trucksOriginAddress {
    country
    street
    apartment
    city
    region
    postalCode
  }
  maxDistance
  maxPickupRadius
  rules {
    to
    travel {
      chargeLabour
      minLabourTime
      pricePerKm {
        basePrice
        low
        high
      }
    }
    transport {
      chargeLabour
      pricePerKm {
        basePrice
        low
        high
      }
    }
    trucksFee {
      key
      value {
        basePrice
        low
        high
      }
    }
  },
  trucksOriginCoordinates {
    coordinates
  }
}
additionalCharges {
  overnight {
    basePrice
    low
    high
  }
  stairs {
    basePrice
    low
    high
  }
  specialServices {
    key
    value {
      basePrice
      low
      high
    }
  }
  specialItems {
    key
    value {
      basePrice
      low
      high
    }
  }
}
protectiveMaterial {
  products {
    key
    value {
      basePrice
      low
      high
    }
  }
}
communication {
  sendBookingEmailsToPrimaryContact
  bookingEmailCC
  sendRatingEmails
  sendRatingEmailsToPrimaryContact
  ratingEmailCC
}
pricingRules {
  id
  title
  temporality {
    type
    date
    from
    to
    fromDay
    toDay
    days
    months
  }
  priority
  movingLabour
  movingLabourExtraMen
  movingLabourSingleMan
  packingLabour
  packingLabourExtraMen
  packingLabourSingleMan
}
availabilityRules {
  id
  title
  temporality {
    type
    date
    from
    to
    fromDay
    toDay
    days
    months
  }
  range
}
legalFiles {
  id
  createdAt
  filename
  mimeType
}
externalEvents {
  exportEnabled
  calendarUrl
  importedCalendars
}
${companyRatingFields}
`

export const fullCompanyBranchFields = `
${companyBranchFields}
company {
  ${companyFields}
}
primaryContact {
  id
  deletedAt
  firstName
  lastName
  position
}
`

export const companyAndBranchesFields = `
${companyFields}
branches {
  ${companyBranchFields}
}
`

export const getCurrentCompany = gql`
query GetCurrentCompany($onBehalfCompanyId: String) {
  getCurrentCompany(onBehalfCompanyId: $onBehalfCompanyId) {
    ${companyAndBranchesFields}
  }
}
`
