import { type JobForPartner } from '../../../modules/jobs'
import { getMoveDate } from '../../../modules/moves'
import JobsTabs from '../../../components/move/JobsTabs'
import Job from './Job'

type JobsProps = {
  job: JobForPartner
}

const Jobs: React.FC<JobsProps> = ({
  job,
}) => {
  return (
    <>
      <JobsTabs
        move={job.move}
        date={getMoveDate(job.date, job.move.originTimezone)}
      />
      <Job job={job} />
    </>
  )
}

export default Jobs
