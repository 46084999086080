import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import NoteIcon from '@mui/icons-material/DescriptionOutlined'

import { type FullMove } from '../../../../modules/adminMoves'
import { useMoveState } from '../../../../modules/moves'
import { Form, NoteField, SubmitButton } from '../../../form'
import { ModalDescription, ModalFooter } from '../../../Modal'
import { type SendQuoteEmailFormValues } from './SendQuoteEmailConfirmationForm.types'

type SendQuoteEmailConfirmationFormProps = {
  move: FullMove
  onConfirm: (payload: SendQuoteEmailFormValues) => void
  onDecline: () => void
}

const SendQuoteEmailConfirmationForm: React.FC<SendQuoteEmailConfirmationFormProps> = ({
  move,
  onConfirm,
  onDecline,
}) => {
  const { t } = useTranslation()
  const { selectedQuote, isAcceptedByCustomer } = useMoveState(move)
  const form = useForm({
    defaultValues: {
      note: move?.notes?.toCustomer?.note,
    },
  })
  const onSubmit = async (values: SendQuoteEmailFormValues) => {
    onConfirm(values)
  }

  const question = (() => {
    if (!selectedQuote) {
      return t('pages.admin.move.actions.sendQuoteEmail.confirmation.questionNoQuoteSelected')
    }
    if (isAcceptedByCustomer) {
      return t('pages.admin.move.actions.sendQuoteEmail.confirmation.questionQuoteAccepted')
    }
    return t('pages.admin.move.actions.sendQuoteEmail.confirmation.questionQuoteNotAccepted')
  })()

  return (
    <Form form={form} onSubmit={onSubmit}>
      <ModalDescription>
        <div className="mb-4 text-base">
          <div className="mb-4">
            { question }
          </div>

          <div className="mb-2 mt-4 flex items-center gap-x-2 text-base font-bold text-neutral-500">
            <NoteIcon className="!text-[16px]" />
            { t('pages.admin.move.actions.sendQuoteEmail.confirmation.note') }
          </div>

          <div className="rounded-md border p-2">
            <NoteField
              name="note"
              files={move.notes?.toCustomer?.attachments}
              className="border-none [&_.field-wrapper]:p-[2px]"
              autoFocus
            />
          </div>
        </div>
      </ModalDescription>
      <ModalFooter>
        <div>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onDecline}
            size="medium"
          >
            { t('actions.cancel') }
          </Button>
        </div>
        <div>
          <SubmitButton
            variant="contained"
            size="medium"
          >
            { t('actions.confirm') }
          </SubmitButton>
        </div>
      </ModalFooter>
    </Form>
  )
}

export default SendQuoteEmailConfirmationForm
