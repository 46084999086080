import { type Interval } from 'date-fns'
import { eachDayOfInterval } from 'date-fns/eachDayOfInterval'
import { set } from 'date-fns/set'
import { isWithinInterval } from 'date-fns/isWithinInterval'
import { subMilliseconds } from 'date-fns/subMilliseconds'
import ArrowUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDownIcon from '@mui/icons-material/ArrowDropDown'

import {
  type CalendarAvailability,
  type CalendarAvailabilityRange,
  type CalendarContent,
  type CalendarRule,
} from '../../../../components/calendar'
import { type AvailabilityRule, type PricingRule, RuleTemporalityRange } from './availabilityAndPricing.models'
import { getFirstDayOfCalendar, getLastDayOfCalendar, getUnavailableState } from './date.util'
import { useTemporalityRuleHandler } from './temporalityRules/temporalityRules.hooks'
import { type CompanyBranch } from '../companies.models'
import { useLabourPricing } from '../price'
import { mergeClassName } from '../../../../utils/mergeClassName'
import Price from '../../../../components/Price'
import { useGetCompanyBranchCurrency } from '..'

const useCalendarInterval = () => (period: Interval): Interval => ({
  start: getFirstDayOfCalendar(period.start as Date),
  end: getLastDayOfCalendar(period.end as Date),
})

const useAvailabilityRulesConverter = () => {
  const getRuleHandler = useTemporalityRuleHandler()

  return (rules: AvailabilityRule[], pediod: Interval) => {
    const calendarRules: CalendarAvailability[] = []

    rules
      .filter(rule => !!getRuleHandler(rule.temporality)?.filter(pediod))
      .forEach(rule => {
        const dates = getRuleHandler(rule.temporality)?.getCalendarEntries(pediod) ?? []
        dates.forEach((entry) => {
          const start = entry instanceof Date ? entry : entry.start as Date
          const end = entry instanceof Date ? undefined : entry.end as Date

          calendarRules.push({
            id: rule.id,
            title: getRuleHandler(rule.temporality)?.getTitle() ?? '',
            start,
            end,
            range: rule.range as unknown as CalendarAvailabilityRange,
          })
        })
      })

    return calendarRules
  }
}

const usePricingRulesConverter = () => {
  const getRuleHandler = useTemporalityRuleHandler()

  return (rules: PricingRule[], pediod: Interval) => {
    const calendarRules: CalendarRule[] = []

    rules
      .filter(rule => !!getRuleHandler(rule.temporality)?.filter(pediod))
      .forEach(rule => {
        const dates = getRuleHandler(rule.temporality)?.getCalendarEntries(pediod) ?? []
        dates.forEach((entry) => {
          const start = entry instanceof Date ? entry : entry.start as Date
          const end = entry instanceof Date ? undefined : entry.end as Date

          calendarRules.push({
            id: rule.id,
            title: getRuleHandler(rule.temporality)?.getTitle() ?? '',
            start,
            end,
            color: getRuleHandler(rule.temporality)?.getColor() ?? '',
            priority: rule.priority,
          })
        })
      })

    return calendarRules
  }
}

const useDateAvailability = () => {
  return (availabilities: CalendarAvailability[], date: Date) => {
    const ranges = availabilities.filter(({ start, end }) => {
      try {
        return isWithinInterval(
          date,
          {
            start,
            end: end ? subMilliseconds(set(end, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }), 1) : start,
          },
        )
      } catch (error) {
        return false
      }
    }).map(({ range }) => range)

    return {
      unavailable: ranges.length > 0 ? getUnavailableState(ranges as any) : undefined,
    }
  }
}

type UseIsDateUnavailableOptions = {
  companyBranch: CompanyBranch
  date: Date
}

export const useIsDateUnavailable = () => {
  const getRuleHandler = useTemporalityRuleHandler()

  return ({
    companyBranch,
    date,
  }: UseIsDateUnavailableOptions) => {
    if (!companyBranch.availabilityRules) {
      return
    }

    const unavailabilities = new Set()
    companyBranch.availabilityRules
      .filter(rule => !!getRuleHandler(rule.temporality)?.isRuleActiveForDate(date))
      .forEach(rule => {
        unavailabilities.add(rule.range)
      })

    if (
      unavailabilities.has(RuleTemporalityRange.Day) ||
      (unavailabilities.has(RuleTemporalityRange.Pm) && unavailabilities.has(RuleTemporalityRange.Am))
    ) {
      return RuleTemporalityRange.Day
    }

    if (unavailabilities.has(RuleTemporalityRange.Am)) {
      return RuleTemporalityRange.Am
    }
    if (unavailabilities.has(RuleTemporalityRange.Pm)) {
      return RuleTemporalityRange.Pm
    }
  }
}

type UseRuleCalendarDataBuilder = {
  companyBranch: CompanyBranch
  period: Interval
  availabilityRules: AvailabilityRule[]
  pricingRules: PricingRule[]
}

export const useRuleCalendarDataBuilder = () => {
  const getCurrency = useGetCompanyBranchCurrency()
  const getCalendarInterval = useCalendarInterval()
  const convertAvailabilityRules = useAvailabilityRulesConverter()
  const convertPricingRules = usePricingRulesConverter()
  const getLabourPricing = useLabourPricing()
  const getDateAvailability = useDateAvailability()

  return ({
    companyBranch,
    period,
    availabilityRules,
    pricingRules,
  }: UseRuleCalendarDataBuilder): [
    CalendarAvailability[],
    CalendarRule[],
    CalendarContent[],
  ] => {
    const currency = getCurrency(companyBranch)
    const interval = getCalendarInterval(period)
    const availabilities = convertAvailabilityRules(availabilityRules, interval)
    const referencePrice = companyBranch.labour?.movingLabour?.[0]?.basePrice

    const content = eachDayOfInterval(interval).map(date => {
      const otherMonth = date < period.start || date > period.end
      const pricing = getLabourPricing({ companyBranch, date })
      const { unavailable } = getDateAvailability(availabilities, date)

      const unavailableAllDay = unavailable === RuleTemporalityRange.Day

      const priceTwoMen = pricing.movingLabour[0]
      const priceThreeMen = pricing.movingLabour[1] ?? priceTwoMen + pricing.movingLabourExtraMen

      if (!priceTwoMen) {
        return { date, content: null }
      }

      const cheaper = !otherMonth && !unavailableAllDay && !!referencePrice && priceTwoMen < referencePrice
      const pricier = !otherMonth && !unavailableAllDay && !!referencePrice && priceTwoMen > referencePrice

      return {
        date,
        content: (
          <div className={mergeClassName(
            'absolute left-0 top-0 flex h-full w-full items-center justify-center overflow-hidden',
            cheaper && 'shadow-[0_0_10px_inset] shadow-green-600/10',
            pricier && 'shadow-[0_0_10px_inset] shadow-red-600/10',
          )}
          >
            <div className={mergeClassName(
              'flex flex-col text-center leading-tight text-neutral-400 pt-3',
              cheaper && 'text-green-700 font-bold',
              pricier && 'text-red-700 font-bold',
            )}
            >
              <span className={mergeClassName(
                'font-body2 text-sm sm:text-base md:text-lg lg:leading-tight',
                unavailableAllDay && 'child:line-through',
              )}
              >
                { (cheaper || pricier) && (
                  <div className="absolute ml-[-25px] mt-[-2px] hidden sm:block">
                    { cheaper && <ArrowDownIcon /> }
                    { pricier && <ArrowUpIcon /> }
                  </div>
                ) }

                <Price
                  amount={{
                    price: priceTwoMen,
                    currency,
                  }}
                  showDecimals={false}
                  compact
                />

              </span>
              <span className={mergeClassName(
                'hidden font-body2 text-base md:text-xs lg:inline',
                unavailableAllDay && 'child:line-through',
              )}
              >
                <Price
                  amount={{
                    price: priceThreeMen,
                    currency,
                  }}
                  showDecimals={false}
                  compact
                />
              </span>
            </div>
          </div>
        ),
      }
    })

    return [
      availabilities,
      convertPricingRules(pricingRules, interval),
      content,
    ]
  }
}

export const usePricingRules = () => {
  const getRuleHandler = useTemporalityRuleHandler()

  return (companyBranch: CompanyBranch, date: Date) => {
    return companyBranch.pricingRules
      ?.filter((rule) => getRuleHandler(rule.temporality)?.isRuleActiveForDate(date))
  }
}

export const usePricingRule = () => {
  const getPricingRules = usePricingRules()

  return (companyBranch: CompanyBranch, date: Date) => {
    const activesRules =
    getPricingRules(companyBranch, date)
      ?.sort((a, b) => b.priority - a.priority) ?? []
    return activesRules?.[0]
  }
}
