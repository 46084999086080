import { type PaginatedQuery, useInfiniteScrollData, usePaginatedData } from '../../graphQl'
import { type CompanyRatingForPartner, type CompanyRating } from './companyRatings.models'
import * as queries from './companyRatings.queries'
import { RatingSorting } from './companyRatings.enums'
import { getCompanyRatingQuery } from './companyRatings.utils'
import { useCurrentCompanyBranch } from '../../companies'

/**
 * return company branch ratings
 */
export const useCompanyBranchRatings = (
  companyBranchId: string,
  sorting: RatingSorting = RatingSorting.MostRecent,
) => {
  const {
    fetchMore: fetchMoreCompanyBranchRatings,
    ...data
  } = useInfiniteScrollData<CompanyRating>(
    queries.getCompanyBranchRatings, {
      companyBranchId,
      query: getCompanyRatingQuery(sorting),
    })

  return {
    fetchMoreCompanyBranchRatings,
    ...data,
  }
}

/**
 * return company branch ratings (for partner)
 * this include a job id so the partner can open the customer detail
 */
export const useCompanyBranchRatingsforPartner = (
  companyBranchId?: string,
) => {
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()
  const { fetch, ...data } = usePaginatedData<CompanyRatingForPartner>(queries.getCompanyBranchRatingsForPartner)

  const fetchCompanyRatings = async (query?: PaginatedQuery) => {
    return await fetch({
      companyBranchId: companyBranchId ?? currentCompanyBranch?.id,
      query,
    })
  }

  return { fetchCompanyRatings, ...data }
}
