import { useAction, useActionWithPayload } from '../../graphQl'
import { type CompanyRatingForPartner } from './companyRatings.models'
import * as mutations from './companyRatings.mutations'

export const useUpdateRatingResponseAction = () => {
  return useActionWithPayload(
    useAction<CompanyRatingForPartner>(mutations.updateRatingResponse),
    (companyRatingId: string, response: string | null) => ({
      companyRatingId,
      payload: { response },
    }),
  )
}
