import { type FullMove } from '../../../../../modules/adminMoves'
import NotesBox from './NotesBox'

type NotesTabProps = {
  move: FullMove
}

const NotesTab: React.FC<NotesTabProps> = ({
  move,
}) => {
  return (
    <NotesBox move={move} />
  )
}

export default NotesTab
