import { type DetailedQuoteRequest } from '../../adminMoves'
import { ignoreTimezone } from '../../../utils/date'

const QUOTE_REQUEST_KEY = 'ib:quotesRequestV2'

/**
 * save quotes request for the current session
 */
export const useSaveQuotesRequestAction = () => {
  return (quotesRequest: Omit<DetailedQuoteRequest, 'residenceType'>) => {
    sessionStorage.setItem(
      QUOTE_REQUEST_KEY,
      JSON.stringify({
        ...quotesRequest,
        residenceType: undefined,
      }))
  }
}

/**
 * get the current quotes request if any
 */
export const useQuotesRequest = (): DetailedQuoteRequest | undefined => {
  try {
    const request = sessionStorage.getItem(QUOTE_REQUEST_KEY)
    if (request) {
      const parsedRequest = JSON.parse(request)
      if (parsedRequest.movingDate) {
        parsedRequest.movingDate = ignoreTimezone(parsedRequest.movingDate)
      }
      return parsedRequest
    }
  } catch (error) {
    console.error(error)
  }
}
