import Route from './app/Route.enum'
import localeEn from './locales/app.en.json'
import localeFr from './locales/app.fr.json'

const configByEnv = <T>(configs: Record<string, T>, defaultValue?: T) => {
  return configs[env] ?? (defaultValue as T)
}

const nodeEnv = process.env.NODE_ENV ?? 'production'
const defaultAppEnv = nodeEnv === 'production' ? 'production' : 'local'
const env = process.env.REACT_APP_ENV ?? defaultAppEnv
const isProduction = env === 'production'
const isStaging = env === 'staging'
const isProductionOrStaging = isProduction || isStaging
const isDevelopment = !isProductionOrStaging

const THIRTY_SEC_MS = 30 * 1000

const ONE_MINUTE = 60
const TEN_MINUTES = 60 * 10

const ONE_DAYS_MS = 60 * 60 * 24 * 1000
const TWO_DAYS_MS = ONE_DAYS_MS * 2
const NINE_DAYS_MS = ONE_DAYS_MS * 9

const config = {
  env,
  isProduction,
  isStaging,
  isDevelopment,
  app: {
    fullCompanyName: 'MovingWaldo inc.',
    companyEmail: 'people@movingwaldo.com',
    appFriendlyUrl: 'partners.movingwaldo.com',
  },
  api: {
    url: configByEnv({
      production: 'https://ib-api.movingwaldo.com',
      staging: 'https://staging.ib-api.movingwaldo.com',
    }, 'http://localhost:4000'),
    includeCredentials: true,
  },
  i18n: {
    locales: {
      en: { app: localeEn },
      fr: { app: localeFr },
    },
    defaultLanguage: 'en',
    defaultNs: 'app',
  },
  whiteListed401Paths: [
    Route.Login,
    Route.ProfileSecurity,
    Route.ProfileDeleteAccount,
  ] as string[],
  users: {
    authentication: {
      authenticatedCookieName: isProduction ? 'ib-authenticated' : `ib-authenticated_${env}`,
      autoRenewThresholdSeconds: ONE_MINUTE,
      emailVerificationRequired: true,
      defaultPage: Route.Dashboard,
      loginRedirectionUrlKey: 'loginRedirectionUrl',
    },
    onlineThresholdSeconds: TEN_MINUTES,
  },
  companies: {
    legalFilesServerPath: 'legal',
    logoSize: 250,
  },
  jobRequests: {
    ttl: TWO_DAYS_MS,
    fetchFrequency: THIRTY_SEC_MS,
    pendingPreviewLimit: 3,
  },
  monitoring: {
    recordScreenRate: 10.0,
    recordScreenRateError: 100,
  },
  newRelic: {
    licenseKey: 'NRJS-b77793230625f4d2d74',
    accountId: '3447401',
    applicationId: configByEnv({
      production: '1386201889',
      staging: '1386201887',
    }, null),
  },
  googleAnalytics: {
    measurementId: isProductionOrStaging ? 'G-2GGKNFZRE3' : undefined,
  },
  googleMaps: {
    apiKey: 'AIzaSyCvdu2K9_H9ifL6XzYkVCL4g-trS6Jr9js',
  },
  stripe: {
    dashboardUrl: isProduction
      ? 'https://dashboard.stripe.com'
      : 'https://dashboard.stripe.com/test',
  },
  instantBooking: {
    notes: {
      imageMaxSize: 1500,
    },
    invoice: {
      invoiceDateMaxDelay: NINE_DAYS_MS,
    },
  },
}

export default config
