import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  useQuotes,
  useQuotesRequest,
  useSaveQuotesRequestAction,
} from '../../../../modules/adminQuotes'
import { protectAdminPage } from '../../../../modules/users'
import { type QuotesRequestPayload } from '../../../../modules/adminQuotes'
import { type MoveDetailsPayload } from '../../../../modules/adminMoves'
import { scrollToTop } from '../../../../utils/scroll'
import ContainedPage from '../../../../components/layout/ContainedPage'
import PageContent from '../../../../components/layout/PageContent'
import PageTitle from '../../../../components/layout/PageTitle'
import {
  Quotes,
  QuotesSidebar,
  QuotesSpinner,
  QuotesTopBar,
} from '../../../../components/quotes'
import { useErrorFormatter } from '../../../../components/errors/useErrorFormatter'
import { errorNotification } from '../../../../components/ToastNotifications'
import QuotesRequestForm from '../../../../components/forms/quotes/QuotesRequestForm'
import {
  quotesRequestToFormData,
  formatFormOutput,
} from '../../../../components/forms/quotes/QuotesRequestForm/formDataBuilder'

const GetQuotePage: React.FC = () => {
  const { t } = useTranslation()
  const savedQuotesRequest = useQuotesRequest()
  const [quotesRequest, setQuotesRequest] = useState(savedQuotesRequest)
  const saveQuotesRequest = useSaveQuotesRequestAction()
  const {
    fetchQuotes,
    data: quotesResponse,
    loading: loadingQuotes,
    error: quotesError,
    called,
  } = useQuotes()
  const formatError = useErrorFormatter()

  const { quotes = [], resolvedRequest } = quotesResponse ?? {}
  const noResults = !loadingQuotes && called && !quotesError && quotes.length === 0

  const hasQuotes = quotes.length > 0
  const [editing, setEditing] = useState(!quotesRequest || !hasQuotes)

  const onQuotesRequestChange = async ([quoteRequest, details]: [QuotesRequestPayload, MoveDetailsPayload]) => {
    const fullRequest = { ...quoteRequest, ...details }

    saveQuotesRequest(fullRequest)
    setQuotesRequest(fullRequest)
    setEditing(false)
    try {
      await fetchQuotes(quoteRequest)
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const handleGetQuotes = async () => {
    if (!quotesRequest) {
      return
    }
    const [request] = formatFormOutput(quotesRequestToFormData(quotesRequest))
    try {
      await fetchQuotes(request)
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const handleEdit = () => {
    if (document) {
      scrollToTop()
    }
    setEditing(true)
  }

  return (
    <ContainedPage>
      <PageTitle
        className="lg:mb-0"
      >
        { t('pages.admin.quotes.title') }
      </PageTitle>

      { !editing && (
        <QuotesTopBar
          quotesRequest={quotesRequest}
          onGetQuotes={editing ? undefined : handleGetQuotes}
          onEdit={editing ? undefined : handleEdit}
          ctaLabel={t('actions.getQuotes')}
        />
      ) }

      { editing && (
        <div className="border-b border-zinc-200 bg-zinc-50 py-6">
          <PageContent>
            <QuotesRequestForm
              onSubmit={onQuotesRequestChange}
              onCancel={hasQuotes ? () => { setEditing(false) } : undefined}
              data={quotesRequest}
              allowOverride
            />
          </PageContent>
        </div>
      ) }

      <PageContent className="px-3 py-8 pr-4">
        { loadingQuotes && <QuotesSpinner /> }

        { !loadingQuotes && quotes.length > 0 && resolvedRequest && (
          <div className="flex w-full flex-col gap-6 lg:flex-row">
            <div className="grow">

              <Quotes quotes={quotes} />

            </div>
            <div>
              <QuotesSidebar
                move={resolvedRequest}
              />
            </div>
          </div>
        ) }

        { noResults && (
          <div className="py-12 text-center italic">
            { t('pages.admin.quotes.noResults') }
          </div>
        ) }

      </PageContent>
    </ContainedPage>
  )
}

export default protectAdminPage(GetQuotePage)
