import { useEffect } from 'react'

import { useLocation } from '..'
import { scrollToTop } from '../../../utils/scroll'

/**
 * this scroll to top when we change page (like regular routing)
 */
const ScrollRestauration: React.FC = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    scrollToTop()
  }, [pathname])

  return null
}

export default ScrollRestauration
