import { type DataOptions, useData } from '../../../graphQl'
import * as queries from './externalEvents.queries'
import { type ExternalEvent } from './externalEvents.types'
import { useCurrentCompanyBranch } from '..'

/**
 * returns external events (Outlook, Google Calendar, etc) to display in calendar
 */
export const useExternalEvents = (companyBranchId?: string, dataOptions?: DataOptions) => {
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()

  companyBranchId ??= currentCompanyBranch?.id

  return useData<ExternalEvent[]>(queries.getExternalEvents, {
    ...dataOptions,
    skip: !companyBranchId,
    variables: { companyBranchId },
  })
}
