import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PaymentIcon from '@mui/icons-material/PaidOutlined'
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import Button from '@mui/material/Button'

import { type FullMove } from '../../../../../modules/adminMoves'
import { useMoveState } from '../../../../../modules/moves'
import Paper from '../../../../../components/Paper'
import PaymentsTable from './PaymentsTable'
import CreatePaymentModal from './CreatePaymentModal'

type PaymentsBoxProps = {
  move: FullMove
}

const PaymentsBox: React.FC<PaymentsBoxProps> = ({
  move,
}) => {
  const { t } = useTranslation()
  const {
    canCreatePayment,
  } = useMoveState(move)
  const [creatingPayment, setCreatingPayment] = useState(false)

  return (
    <>
      <CreatePaymentModal
        move={move}
        open={creatingPayment}
        onClose={() => { setCreatingPayment(false) }}
      />
      <Paper>
        <div className="flex justify-between gap-x-4">

          <h2 className="mb-4 flex items-center gap-x-2 font-body2 text-2xl font-semibold text-slate-700 ">
            <PaymentIcon />
            <div>
              { t('pages.admin.move.accounting.payments.title') }
            </div>
          </h2>

          <div>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => { setCreatingPayment(true) }}
              className="!normal-case"
              disabled={!canCreatePayment}
            >
              { t('actions.addPayment') }
            </Button>
          </div>
        </div>

        <div className="mb-6">
          <PaymentsTable move={move} />
        </div>
      </Paper>
    </>
  )
}

export default PaymentsBox
