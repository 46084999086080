type ReviewMessageProps = {
  message?: string
}

const ReviewMessage: React.FC<ReviewMessageProps> = ({ message }) => {
  const simpleMessage = message?.replace(/\n/gm, ' ')?.trim().substring(0, 50)

  if (!simpleMessage) {
    return null
  }

  return (
    <div
      className="w-[300px] truncate text-sm text-neutral-400"
      title={message}
    >
      { simpleMessage }
    </div>
  )
}

export default ReviewMessage
