import { v4 as uuid } from 'uuid'

import { type AvailabilityRule } from '../../../../modules/companies'
import { type AvailabilityRuleFormValues } from './AvailabilityRuleForm.type'
import { formatFormOutput as formatRuleOutput, ruleTemporalityToFormData } from '../TemporalityForm/formDataBuilder'

export const availabilityRulesToFormData = (availabilityRule: AvailabilityRule): AvailabilityRuleFormValues => {
  return {
    id: availabilityRule.id,
    temporality: ruleTemporalityToFormData(availabilityRule.temporality),
    range: availabilityRule.range,
  }
}

export const formatFormOutput = (formData: AvailabilityRuleFormValues): AvailabilityRule => {
  return {
    id: formData.id ?? uuid(),
    temporality: formatRuleOutput(formData.temporality),
    range: formData.range,
  }
}
