import { useMutation } from '@apollo/client'

import * as mutations from './invoices.mutations'
import {
  type InvoiceEstimate,
  type CalculateInvoicePayload,
  type InvoicePayload,
  type CreateInvoiceResponse,
} from './invoices.types'
import { useAction, useActionWithPayload } from '../../graphQl'
import { type FullMove } from '../../adminMoves'

/**
 * calculate taxes and total (from MW to customer)
 */
export const useCalculateInvoiceAction = () => {
  const [calculateInvoice, { data, loading, ...mutationProps }] = useMutation(mutations.calculateInvoice)

  return {
    calculateInvoice: async (moveId: string, payload: CalculateInvoicePayload): Promise<InvoiceEstimate> => {
      const { data } = await calculateInvoice({
        variables: {
          moveId,
          payload,
        },
      })

      return data.calculateInvoice
    },
    data: data?.calculateInvoice as InvoiceEstimate | undefined,
    loading,
    ...mutationProps,
  }
}

/**
 * create a move invoice (from MW to customer)
 */
export const useCreateMoveInvoiveAction = () => {
  const [createMoveInvoice, { data, loading, ...mutationProps }] = useMutation(mutations.createMoveInvoice)

  return {
    createMoveInvoice: async (moveId: string, { autoCapture, ...payload }: InvoicePayload): Promise<CreateInvoiceResponse> => {
      const { data } = await createMoveInvoice({
        variables: {
          moveId,
          payload,
          autoCapture,
        },
      })

      return data.admin_createMoveInvoice
    },
    data: data?.admin_createMoveInvoice as CreateInvoiceResponse | undefined,
    loading,
    ...mutationProps,
  }
}

/**
 * void an existing invoice
 */
export const useVoidMoveInvoiceAction = () => {
  return useActionWithPayload(
    useAction<FullMove>(mutations.voidMoveInvoice),
    (moveId: string, invoiceId: string) => ({ moveId, invoiceId }),
  )
}
