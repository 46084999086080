import { ProtectiveMaterial } from '../../../../../modules/companies'
import { ChargeDescription } from '../../../../../modules/invoices'
import { type JobForPartner } from '../../../../../modules/jobs'
import { chargesDefinition } from '../chargesDefinition.const'
import { ChargeEditability, type ChargeRow } from '../CloseJobForm.types'
import { useGetCategoryAndDescription } from './charges.hooks'
import { useCompanyApplicableCharges } from './companyPricing.hooks'

/**
 * returns a list of charges that can be added and are not already present
 */
export const useAvailableChargesToAdd = (
  job: JobForPartner,
  charges: ChargeRow[] = [],
) => {
  const relatedCharges = useCompanyApplicableCharges(new Date(job.date))
  const getCategoryAndDescription = useGetCategoryAndDescription()
  const usedCharges = charges.map((charge) => {
    return getCategoryAndDescription(charge.categoryAndDescription).description
  })

  return Object.entries(chargesDefinition)
    .filter(([chargeDescription, charge]) => {
      return charge.editable === ChargeEditability.AlwaysEditable &&
      relatedCharges.includes(chargeDescription as ChargeDescription) &&
      !usedCharges.includes(chargeDescription as ChargeDescription)
    })
    .map(([chargeDescription]) => chargeDescription as ChargeDescription)
}

/**
 * return items that can be added for collections
 */
export const useAvailableCollectionItemsToAdd = (
  description: ChargeDescription,
  itemsPrice: Record<string, number>,
  usedItems: string[],
) => {
  switch (description) {
    case ChargeDescription.additionalChargesSpecialServices:
    case ChargeDescription.additionalChargesSpecialItems:
      return Object.keys(itemsPrice).filter(item => !usedItems.includes(item))
    case ChargeDescription.protectiveMaterialProduct:
      return Object.values(ProtectiveMaterial).filter(item => !usedItems.includes(item))
  }
}
