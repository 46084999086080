import { useParams } from '../../../navigation'
import { useCurrentUserAccountType } from '../../../users'

/**
 * return true if admin is currently acting on behalf of a partner
 */
export const useOnBehalfState = () => {
  const { companyId } = useParams()
  const { isAdmin } = useCurrentUserAccountType()
  const onBehalf = isAdmin && !!companyId

  return {
    onBehalf,
    onBehalfCompanyId: onBehalf ? companyId : undefined,
  }
}
