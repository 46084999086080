import { useTranslation } from 'react-i18next'

import { type FullMove } from '../../../../../modules/adminMoves'
import { useMoveState } from '../../../../../modules/moves'
import { useDateFormatter } from '../../../../../utils/date'
import Steps from '../../../../../components/Steps'
import TimeAgo from '../../../../../components/TimeAgo'

type MoveJourneyProps = {
  move: FullMove
}

const MoveJourney: React.FC<MoveJourneyProps> = ({
  move,
}) => {
  const { t } = useTranslation()
  const formatDate = useDateFormatter()
  const {
    moveDate,
    nbQuotes,
    selectedQuote,
    selectedCompanyName,
    isAcceptedByCustomer,
    isApprovedByAgent,
    isAcceptedByPartner,
    missingDeposit,
    isJobCompleted,
    hasMoveInvoice,
    movePaid,
  } = useMoveState().forMove(move)

  const stage1Steps = []
  stage1Steps.push({
    name: t('pages.admin.move.details.moveJourney.receivedQuotes'),
    details: t('pages.admin.move.details.moveJourney.receivedQuotesDetails', { count: nbQuotes }),
    completed: nbQuotes > 0,
  })

  stage1Steps.push({
    name: t('pages.admin.move.details.moveJourney.selectedquote'),
    details: selectedCompanyName,
    completed: !!selectedQuote,
  })

  if (missingDeposit) {
    stage1Steps.push({
      name: t('pages.admin.move.details.moveJourney.acceptedByCustomer'),
      details: 'Deposit not paid',
      completed: isAcceptedByCustomer,
      warning: true,
    })
  } else {
    stage1Steps.push({
      name: t('pages.admin.move.details.moveJourney.acceptedByCustomer'),
      details: move.quoteAccepted?.date ? formatDate(move.quoteAccepted.date) : undefined,
      completed: isAcceptedByCustomer,
    })
  }

  stage1Steps.push({
    name: t('pages.admin.move.details.moveJourney.approvedByAgent'),
    completed: isApprovedByAgent,
  })

  const stage2Steps = [
    {
      name: t('pages.admin.move.details.moveJourney.acceptedByPartner'),
      details: move.latestJobRequest ? t(`jobRequest.status.${move.latestJobRequest.status}`) : undefined,
      completed: isAcceptedByPartner,
    },
    {
      name: t('pages.admin.move.details.moveJourney.jobCompleted'),
      details: moveDate ? (<TimeAgo date={moveDate} />) : undefined,
      completed: isJobCompleted,
    },
    {
      name: t('pages.admin.move.details.moveJourney.invoiceCreated'),
      completed: isJobCompleted && hasMoveInvoice,
    },
    {
      name: t('pages.admin.move.details.moveJourney.invoicePaid'),
      completed: isJobCompleted && movePaid,
    },
  ]

  return (
    <>
      <Steps
        steps={stage1Steps}
        className="rounded-t-md border-b-0 lg:border-b"
        compact
      />
      <Steps
        steps={stage2Steps}
        className="border-t-0"
        compact
      />
    </>
  )
}

export default MoveJourney
