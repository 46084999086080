import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CheckIcon from '@mui/icons-material/CheckCircleOutlineOutlined'

import Link, { type LinkProps } from '../Link'
import { mergeClassName } from '../../utils/mergeClassName'

type MobileContentMenuLinkProps = LinkProps & {
  showChevron?: boolean
  completed?: boolean
}

const MobileContentMenuLink: React.FC<MobileContentMenuLinkProps> = ({
  children,
  showChevron = true,
  completed = false,
  disabled = false,
  className,
  ...linkProps
}) => {
  return (
    <Link
      className={mergeClassName(
        'relative flex border-b px-8 py-6',
        completed && 'text-green-900 font-bold',
        className,
      )}
      disabled={disabled}
      {...linkProps}
    >
      { children }
      { completed && <CheckIcon className="ml-2 text-green-600" /> }
      { showChevron && (
        <ChevronRightIcon
          className={mergeClassName(
            'absolute right-8 top-[calc(50%-12px)] text-gray-400',
            disabled && 'text-gray-200',
          )}
        />
      ) }
    </Link>
  )
}

export default MobileContentMenuLink
