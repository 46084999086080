import { type DataOptions, useData, usePaginatedData } from '../../graphQl'
import * as queries from './adminCompanyRatings.queries'
import { type FullCompanyRating } from './adminCompanyRatings.models'

/**
 * returns all companies branches ratings
 * query optimized for data table
 */
export const useCompaniesBranchesRatings = () => {
  const { fetch: fetchCompaniesBranchesRatings, ...data } = usePaginatedData<FullCompanyRating>(queries.getCompaniesBranchesRatings)
  return { fetchCompaniesBranchesRatings, ...data }
}

/**
 * returns a single rating detail
 */
export const useCompanyBranchRating = (companyBranchRatingId: string, options?: DataOptions) => {
  return useData<FullCompanyRating>(queries.getCompanyBranchRating, {
    ...options,
    variables: { companyBranchRatingId },
  })
}
