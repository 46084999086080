import { type ChargeCategory } from '../../../../../modules/invoices'
import { type Step, steps } from '../../../../companyBranch'

type ChargeCategoryIconProps = {
  category: ChargeCategory
  className?: string
}

const ChargeCategoryIcon: React.FC<ChargeCategoryIconProps> = ({
  category,
  className,
}) => {
  const Icon = steps[category as unknown as Step]?.Icon

  if (!Icon) {
    return null
  }

  return (
    <Icon className={className} />
  )
}

export default ChargeCategoryIcon
