import clsx from 'clsx'
import { styled } from '@mui/material/styles'
import Box, { type BoxProps } from '@mui/material/Box'
import UncheckedIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const Container = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  '&.disabled': {
    opacity: 0.2,
  },
}))

const CheckedIcon = styled(CheckBoxIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&.danger': {
    color: theme.palette.error.main,
  },
}))

type StaticCheckboxProps = {
  checked: boolean
  onClick: () => void
  danger?: boolean
  disabled?: boolean
} & BoxProps

const StaticCheckbox: React.FC<StaticCheckboxProps> = ({
  checked,
  onClick,
  danger = false,
  disabled = false,
  className,
  ...boxProps
}) => {
  const Icon = checked ? CheckedIcon : UncheckedIcon
  return (
    <Container
      {...boxProps}
      className={clsx(
        className,
        disabled && 'disabled',
      )}
    >
      <Icon
        onClick={disabled ? undefined : onClick}
        className={clsx(danger && 'danger')}
      />
    </Container>
  )
}

export default StaticCheckbox
