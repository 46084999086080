import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '@mui/material/Button'

import { type AvailabilityRule } from '../../../../modules/companies'
import { Form, Label, Select, SubmitButton } from '../../../form'
import {
  ModalHeader,
  ModalTitle,
  ModalDescription,
  ModalFooter,
} from '../../../Modal'
import TemporalityForm from '../TemporalityForm'
import { useDefaultTemporalityValues } from '../TemporalityForm/useDefaultTemporalityValues'
import { CalendarAvailabilityRange } from '../../../calendar'
import { type AvailabilityRuleFormValues } from './AvailabilityRuleForm.type'
import { availabilityRulesToFormData, formatFormOutput } from './formDataBuilder'
import useValidationSchema from './validationSchema'

type AvailabilityRuleFormProps = {
  onClose: () => void
  onSubmit: (payload: AvailabilityRule) => Promise<any>
  data?: AvailabilityRule
  selectedDates?: { start: Date, end?: Date }
}

const AvailabilityRuleForm: React.FC<AvailabilityRuleFormProps> = ({
  onClose,
  onSubmit,
  data,
  selectedDates,
}) => {
  const { t } = useTranslation()
  const schema = useValidationSchema()
  const temporality = useDefaultTemporalityValues(selectedDates)

  const editing = !!data

  const defaultValues = {
    temporality,
  }

  const form = useForm<AvailabilityRuleFormValues>({
    resolver: yupResolver(schema),
    defaultValues: data ? availabilityRulesToFormData(data) : defaultValues,
  })

  const formatBeforeSubmit = async (values: AvailabilityRuleFormValues) => await onSubmit(formatFormOutput(values))

  return (
    <div className="w-full">
      <Form form={form} onSubmit={formatBeforeSubmit}>
        <ModalHeader>
          <ModalTitle>
            { editing
              ? t('forms.companyBranch.availability.availabilityRule.editTitle')
              : t('forms.companyBranch.availability.availabilityRule.createTitle') }
          </ModalTitle>
        </ModalHeader>
        <ModalDescription className="my-4 max-h-[calc(100dvh-165px)] px-0">

          <div className="-mb-px ml-4 mt-2 inline-block rounded-t border-x border-t bg-zinc-50 px-3 pt-1">
            <Label label={t('forms.companyBranch.availability.temporality.title')} />
          </div>

          <div className="mb-5 border-y bg-zinc-50 p-6 px-4 text-gray-600 lg:px-6">
            <TemporalityForm
              prefixName="temporality"
            />
          </div>

          <div className="mb-2 px-2 md:px-4 lg:px-6">
            <Label label={t('forms.companyBranch.availability.availabilityRule.label')}>
              <Select
                name="range"
                options={[
                  {
                    value: CalendarAvailabilityRange.Day,
                    label: t('forms.companyBranch.availability.availabilityRule.day'),
                  },
                  {
                    value: CalendarAvailabilityRange.Am,
                    label: t('forms.companyBranch.availability.availabilityRule.am'),
                  },
                  {
                    value: CalendarAvailabilityRange.Pm,
                    label: t('forms.companyBranch.availability.availabilityRule.pm'),
                  },
                ]}
              />
            </Label>
          </div>

        </ModalDescription>
        <ModalFooter>
          <Button variant="contained" color="secondary" onClick={onClose}>
            { t('actions.cancel') }
          </Button>
          <SubmitButton>
            <div className="leading-tight">
              { editing
                ? t('actions.update')
                : t('forms.companyBranch.availability.availabilityRule.createTitle') }
            </div>
          </SubmitButton>
        </ModalFooter>
      </Form>
    </div>
  )
}

export default AvailabilityRuleForm
