import { useTranslation } from 'react-i18next'
import SalesIcon from '@mui/icons-material/RequestQuoteOutlined'
import AccountBalanceIcon from '@mui/icons-material/AccountBalanceOutlined'

import { protectAdminPage } from '../../../modules/users'
import {
  type DateRange,
  useCustomerBalanceReportExport,
  useGetReportInterval,
  useReportInterval,
  useSalesReportExport,
} from '../../../modules/adminAccounting'
import ContainedPage from '../../../components/layout/ContainedPage'
import PageContent from '../../../components/layout/PageContent'
import PageTitle from '../../../components/layout/PageTitle'
import DatesSelector from './DatesSelector'
import ReportButton from './ReportButton'
import ReportSpinner from './ReportSpinner'
import { useState } from 'react'
import { downloadBase64Data } from '../../../utils/file'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { errorNotification, warningNotification } from '../../../components/ToastNotifications'
import { format } from 'date-fns'

const AccountingPage: React.FC = () => {
  const { t } = useTranslation()
  const [intervalValue, setIntervalValue] = useReportInterval()
  const interval = useGetReportInterval()(intervalValue)
  const [loading, setLoading] = useState(false)
  const formatError = useErrorFormatter()
  const { exportReport: exportSalesReport } = useSalesReportExport()
  const { exportReport: exportCustomersBalanceReport } = useCustomerBalanceReportExport()

  /**
   * download a report in CSV
   */
  const downloadReport = async (callback: (dateRange: DateRange) => Promise<string>, filenamePrefix: string) => {
    const filename = [
      filenamePrefix,
      format(interval.start, 'yyyy-MM-dd'),
      format(interval.end, 'yyyy-MM-dd'),
    ].join('-') + '.csv'
    try {
      setLoading(true)
      const data = await callback({
        from: interval.start,
        to: interval.end,
      })
      if (!data) {
        warningNotification(t('pages.admin.accounting.emptyReport'))
        return
      }
      downloadBase64Data(
        data,
        filename,
      )
    } catch (error) {
      console.error(error)
      errorNotification(formatError(error))
    } finally {
      setLoading(false)
    }
  }

  /**
   * download sales report
   */
  const downloadSalesReport = async () => {
    await downloadReport(
      exportSalesReport,
      'sales',
    )
  }

  /**
   * download customers balance report
   */
  const downloadCustomersBalanceReport = async () => {
    await downloadReport(
      exportCustomersBalanceReport,
      'customers-balance',
    )
  }

  return (
    <ContainedPage>

      { loading && <ReportSpinner /> }

      <PageTitle className="lg:mb-0">
        <div className="flex flex-col justify-between gap-4 lg:flex-row">
          { t('pages.admin.accounting.title') }
        </div>
      </PageTitle>
      <DatesSelector
        value={intervalValue}
        onChange={setIntervalValue}
      />
      <PageContent className="px-3 pr-4 lg:py-8">

        <div className="my-8 flex w-full flex-wrap gap-8">

          <ReportButton
            reportName={t('pages.admin.accounting.reports.sales.title')}
            reportDescription={t('pages.admin.accounting.reports.sales.description')}
            Icon={SalesIcon}
            interval={interval}
            onClick={downloadSalesReport}
          />

          <ReportButton
            reportName={t('pages.admin.accounting.reports.customerBalances.title')}
            reportDescription={t('pages.admin.accounting.reports.customerBalances.description')}
            Icon={AccountBalanceIcon}
            interval={interval}
            onClick={downloadCustomersBalanceReport}
          />
        </div>

      </PageContent>
    </ContainedPage>
  )
}

export default protectAdminPage(AccountingPage)
