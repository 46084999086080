import { gql } from '@apollo/client'
import { moveFields, notesFields } from './adminMoves.queries'

export const createMove = gql`
mutation Admin_createMove($payload: CreateMoveDto!) {
  admin_createMove(payload: $payload) {
    ${moveFields}
  }
}
`

export const updateMove = gql`
mutation Admin_updateMove($moveId: String!, $payload: UpdateMoveDto!) {
  admin_updateMove(moveId: $moveId, payload: $payload) {
    ${moveFields}
  }
}
`

export const assignAgentToMove = gql`
mutation Admin_assignAgentToMove($moveId: String!) {
  admin_assignAgentToMove(moveId: $moveId) {
    ${moveFields}
  }
}
`

export const unassignAgentToMove = gql`
mutation Admin_unassignAgentToMove($moveId: String!) {
  admin_unassignAgentToMove(moveId: $moveId) {
    ${moveFields}
  }
}
`

export const sendMoveToSmartMoving = gql`
mutation Admin_sendMoveToSmartMoving($moveId: String!) {
  admin_sendMoveToSmartMoving(moveId: $moveId)
}
`

export const refreshQuotes = gql`
mutation Admin_refreshQuotes($moveId: String!) {
  admin_refreshQuotes(moveId: $moveId) {
    ${moveFields}
  }
}
`

export const cancelSelectedQuote = gql`
mutation Admin_cancelSelectedQuote($moveId: String!) {
  admin_cancelSelectedQuote(moveId: $moveId) {
    ${moveFields}
  }
}
`

export const applyPromoCode = gql`
mutation Admin_applyPromoCode($moveId: String!, $promoCode: String) {
  admin_applyPromoCode(moveId: $moveId, promoCode: $promoCode) {
    ${moveFields}
  }
}
`

export const selectBranch = gql`
mutation Admin_selectBranch($moveId: String!, $branchId: String!) {
  admin_selectBranch(moveId: $moveId, branchId: $branchId) {
    ${moveFields}
  }
}
`

export const editQuote = gql`
mutation Admin_editQuote($moveId: String!, $payload: EditQuoteDto!) {
  admin_editQuote(moveId: $moveId, payload: $payload) {
    ${moveFields}
  }
}
`

export const sendQuoteByEmail = gql`
mutation Admin_sendQuoteByEmail($moveId: String!, $payload: SendQuoteByEmailDto!) {
  admin_sendQuoteByEmail(moveId: $moveId, payload: $payload) {
    ${moveFields}
  }
}
`

export const approveMove = gql`
mutation Admin_approveMove($moveId: String!, $payload: ApproveMoveDto) {
  admin_approveMove(moveId: $moveId, payload: $payload) {
    ${moveFields}
  }
}
`

export const cancelJobRequest = gql`
mutation Admin_cancelJobRequest($moveId: String!) {
  admin_cancelJobRequest(moveId: $moveId) {
    ${moveFields}
  }
}
`

export const cancelJob = gql`
mutation Admin_cancelJob($moveId: String!, $jobId: String!) {
  admin_cancelJob(moveId: $moveId, jobId: $jobId) {
    ${moveFields}
  }
}
`

export const collectTotalBalance = gql`
mutation Admin_collectTotalBalance($moveId: String!) {
  admin_collectTotalBalance(moveId: $moveId) {
    ${moveFields}
  }
}
`

export const sendPaymentRequestEmail = gql`
mutation Admin_sendPaymentRequestEmail($moveId: String!) {
  admin_sendPaymentRequestEmail(moveId: $moveId)
}
`

export const updateMoveNote = gql`
mutation Admin_updateMoveNote($moveId: String!, $payload: UpdateNoteAdminDto!) {
  admin_updateMoveNote(moveId: $moveId, payload: $payload) {
    id
    ${notesFields}
  }
}
`

export const uploadMoveNoteAttachment = gql`
mutation Admin_uploadMoveNoteAttachment($moveId: String!, $payload: UploadAttachmentAdminDto!) {
  admin_uploadMoveNoteAttachment(moveId: $moveId, payload: $payload) {
    id
    ${notesFields}
  }
}
`

export const deleteMoveNoteAttachment = gql`
mutation Admin_deleteMoveNoteAttachment($moveId: String!, $payload: DeleteAttachmentAdminDto!) {
  admin_deleteMoveNoteAttachment(moveId: $moveId, payload: $payload) {
    id
    ${notesFields}
  }
}
`

export const shareNoteFromCustomer = gql`
mutation Admin_shareNoteFromCustomer($moveId: String!, $shareNoteFromCustomer: Boolean!) {
  admin_shareNoteFromCustomer(moveId: $moveId, shareNoteFromCustomer: $shareNoteFromCustomer) {
    id
    ${notesFields}
  }
}
`
