import { protectPage } from '../../../modules/users'
import { useDirectAccess, useNavigate, useParams } from '../../../modules/navigation'
import Route from '../../../app/Route.enum'
import JobSidepanel from './JobSidepanel'

const JobPage: React.FC = () => {
  const { jobId } = useParams()
  const { replace } = useNavigate()
  const { accessedDirectly } = useDirectAccess()

  const closeSidebar = () => {
    replace(Route.Jobs)
  }

  return (
    <JobSidepanel
      open
      onClose={closeSidebar}
      jobId={jobId}
      appear={!accessedDirectly}
    />
  )
}

export default protectPage(JobPage)
