import { ChargeDescription } from '../../../../modules/invoices'
import { ChargeEditability, type ChargesDefinition } from './CloseJobForm.types'

export const chargesDefinition: ChargesDefinition = {

  [ChargeDescription.movingLabour]: { editable: ChargeEditability.AlwaysEditable },
  [ChargeDescription.packingLabour]: { editable: ChargeEditability.EditableIfPresent },
  [ChargeDescription.transportLabour]: { editable: ChargeEditability.AlwaysEditable },

  [ChargeDescription.travelLabour]: { editable: ChargeEditability.EditableIfPresent },
  [ChargeDescription.travelTruckFeePerKilometer]: { editable: ChargeEditability.EditableIfPresent },
  [ChargeDescription.transportTruckFeePerKilometer]: { editable: ChargeEditability.EditableIfPresent },
  [ChargeDescription.fixedTruckFee]: { editable: ChargeEditability.NotEditable },

  [ChargeDescription.additionalChargesOvernight]: { editable: ChargeEditability.EditableIfPresent },
  [ChargeDescription.additionalChargesStairs]: { editable: ChargeEditability.AlwaysEditable },
  [ChargeDescription.additionalChargesSpecialServices]: {
    editable: ChargeEditability.AlwaysEditable,
    collection: true,
  },
  [ChargeDescription.additionalChargesSpecialItems]: {
    editable: ChargeEditability.AlwaysEditable,
    collection: true,
  },
  [ChargeDescription.customCharge]: { editable: ChargeEditability.NotEditable },
  [ChargeDescription.customChargeMw]: { editable: ChargeEditability.NotEditable },

  [ChargeDescription.protectiveMaterialProduct]: {
    editable: ChargeEditability.AlwaysEditable,
    unitPriceEditable: true,
    collection: true,
  },
}
