import { Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { type Charge, type ChargeCategory } from '../../modules/invoices'
import { mergeClassName } from '../../utils/mergeClassName'
import { type Step, steps } from '../companyBranch'
import Price from '../Price'
import ChargeRow from './ChargeRow'

type RowsByCategories = Partial<Record<ChargeCategory, Charge[]>>

type ChargesProps = {
  charges: Charge[]
  showDetails?: boolean
}

const Charges: React.FC<ChargesProps> = ({
  charges,
  showDetails = true,
}) => {
  const { t } = useTranslation()

  const rowsByCategories = useMemo(() => {
    const rows: RowsByCategories = {}
    charges.forEach((charge) => {
      const category = charge.pricingCategory
      if (!rows[category]) {
        rows[category] = []
      }
      rows[category]?.push(charge)
    })
    return rows
  }, [charges])

  /**
   * return category subtotal for simplified view
   */
  const getCategorySubtotal = (rows: Charge[] | undefined) => {
    if (!rows) {
      return 0
    }
    const currency = rows?.[0]?.subtotal?.currency
    if (!currency) {
      return
    }
    const categorySubtotal = rows.reduce((acc, curr) => {
      return acc + (curr.subtotal.price ?? 0)
    }, 0)
    return { price: categorySubtotal, currency }
  }

  return (
    <>
      { Object.entries(rowsByCategories).map(([category, rows], index) => {
        const CategoryIcon = steps[category as Step]?.Icon
        const categoryName = t(`quotes.pricing.categories.${category}`)
        const showHeaders = showDetails && index === 0
        const categoryColSpan = showDetails ? 4 : undefined
        const categorySubtotal = getCategorySubtotal(rows)

        return (
          <Fragment key={category}>
            <tr className={mergeClassName(
              !showDetails && 'border-b border-dashed align-bottom sm:align-middle',
            )}
            >
              <td colSpan={showHeaders ? undefined : categoryColSpan}>
                <div className={mergeClassName(
                  'pt-3 text-sm font-bold text-gray-600',
                  showDetails && 'pt-4',
                )}
                >
                  { CategoryIcon && <CategoryIcon /> }
                  { ' ' }
                  { categoryName }
                </div>
              </td>

              { !showDetails && (
                <td className="whitespace-nowrap text-right text-gray-500">
                  { categorySubtotal && <Price amount={categorySubtotal} compact /> }
                </td>
              ) }

              { showHeaders && (
                <>
                  <td className="w-0 opacity-0 sm:w-auto sm:opacity-100">
                    <div className="pt-4 text-right text-xs text-gray-400 ">
                      { t('quotes.quote.columns.quantity') }
                    </div>
                  </td>
                  <td className="w-0 opacity-0 sm:w-auto sm:opacity-100">
                    <div className="pt-4 text-right text-xs text-gray-400">
                      { t('quotes.quote.columns.unitPrice') }
                    </div>
                  </td>
                  <td className="w-0 opacity-0 sm:w-auto sm:opacity-100">
                    <div className="pt-4 text-right text-xs text-gray-400">
                      { t('quotes.quote.columns.subtotal') }
                    </div>
                  </td>
                </>
              ) }
            </tr>
            { showDetails && rows.map(row => {
              const key = `${row.description}:${String(row.item)}:${row.quantity}:${String(row.unit)}`
              return <ChargeRow key={key} charge={row} />
            }) }
          </Fragment>
        )
      }) }
    </>
  )
}

export default Charges
