import { useTranslation } from 'react-i18next'
import { type SelectChangeEvent } from '@mui/material/Select'

import { Select } from '../../../form'
import { RuleTemporalityType } from '../../../../modules/companies'
import { useFormContext, useWatch } from 'react-hook-form'
import TemporalityDateForm from './TemporalityDateForm'
import TemporalityDateRangeForm from './TemporalityDateRangeForm'
import TemporalityEveryWeekDayForm from './TemporalityEveryWeekDayForm'
import TemporalityRecurrentRangeForm from './TemporalityRecurrentRangeForm'

type TemporalityFormProps = {
  prefixName: string
}

const TemporalityForm: React.FC<TemporalityFormProps> = ({
  prefixName,
}) => {
  const { t } = useTranslation()
  const ruleTypeName = `${prefixName}.type`

  const form = useFormContext()
  const ruleType = useWatch({ name: ruleTypeName })

  const onChangeType = (event: SelectChangeEvent<unknown>) => {
    form.unregister(prefixName)
    form.setValue(ruleTypeName, event.target.value)
  }

  const renderForm = () => {
    switch (ruleType) {
      case RuleTemporalityType.Date:
        return <TemporalityDateForm prefixName={prefixName} />
      case RuleTemporalityType.DateRange:
        return <TemporalityDateRangeForm prefixName={prefixName} />
      case RuleTemporalityType.EveryWeekDay:
        return <TemporalityEveryWeekDayForm prefixName={prefixName} />
      case RuleTemporalityType.RecurrentRange:
        return <TemporalityRecurrentRangeForm prefixName={prefixName} />
      default:
        return null
    }
  }

  return (
    <div className="flex flex-col flex-wrap gap-2 lg:flex-row">
      <Select
        name={ruleTypeName}
        size="small"
        className="min-w-[225px] bg-white"
        options={[
          {
            group: t('forms.companyBranch.availability.temporality.groups.occuringOnce'),
            options: [
              {
                value: RuleTemporalityType.Date,
                label: t('forms.companyBranch.availability.temporality.options.date'),
              },
              {
                value: RuleTemporalityType.DateRange,
                label: t('forms.companyBranch.availability.temporality.options.dateRange'),
              },
            ],
            props: { className: '!text-sm !font-body2 my-4 !text-slate-500 border-b' },
          },
          {
            group: t('forms.companyBranch.availability.temporality.groups.recurring'),
            options: [
              {
                value: RuleTemporalityType.RecurrentRange,
                label: t('forms.companyBranch.availability.temporality.options.recurrentRange'),
              },
              {
                value: RuleTemporalityType.EveryWeekDay,
                label: t('forms.companyBranch.availability.temporality.options.everyWeekDay'),
              },
            ],
            props: { className: '!text-sm !font-body2 my-4 !text-slate-500 border-b' },
          },
        ]}
        textFieldProps={{ fullWidth: false }}
        onChange={onChangeType}
      />

      { renderForm() }

    </div>
  )
}

export default TemporalityForm
