import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import { type Quote } from '../../../../modules/quotes'
import {
  type PromoCode,
  usePromoCodeRebate,
  useQuoteSubtotalWithPromoCode,
} from '../../../../modules/adminPromoCodes'
import Price from '../../../Price'
import Charges from '../../../Charges'

type QuoteDetailsProps = {
  quote: Quote
  promoCode?: PromoCode
  forceOpen?: boolean
}

const QuoteDetails: React.FC<QuoteDetailsProps> = ({
  quote,
  promoCode,
  forceOpen = false,
}) => {
  const { t } = useTranslation()
  const [showDetails, setShowDetails] = useState(forceOpen)
  const promoCodeRebate = usePromoCodeRebate(quote, promoCode)
  const quoteSubtotalWithPromoCode = useQuoteSubtotalWithPromoCode(quote, promoCode)

  const handleToggleDetailsClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    setShowDetails(!showDetails)
  }

  return (
    <>
      <table className="w-full">
        <tbody>

          <Charges
            charges={quote.details}
            showDetails={showDetails}
          />

          <tr>
            <td colSpan={4}>
              <div className="pt-2 text-right text-lg">

                { promoCodeRebate && quoteSubtotalWithPromoCode && (
                  <>
                    <div className="text-sm text-neutral-400">
                      <Price amount={quote.subtotal} compact />
                    </div>
                    <div className="mb-4 text-sm text-green-800">
                      <Price amount={promoCodeRebate} compact />
                    </div>
                  </>
                ) }

                <Price
                  amount={quoteSubtotalWithPromoCode ?? quote.subtotal}
                  compact
                  showCurrency
                />
              </div>
            </td>
          </tr>

        </tbody>
      </table>

      { !forceOpen && (
        <div>
          { showDetails
            ? (
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                onClick={handleToggleDetailsClick}
                startIcon={<ArrowUpIcon />}
              >
                { t('actions.hideDetails') }
              </Button>
              )
            : (
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                onClick={handleToggleDetailsClick}
                startIcon={<ArrowDownIcon />}
              >
                { t('actions.showDetails') }
              </Button>
              ) }
        </div>
      ) }

    </>
  )
}

export default QuoteDetails
