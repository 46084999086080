import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import UserIcon from '@mui/icons-material/PersonOutlined'
import EditIcon from '@mui/icons-material/Edit'

import {
  type FullMove,
  type MovePayload,
  useUpdateMoveAction,
} from '../../../../../modules/adminMoves'
import { useMoveState } from '../../../../../modules/moves'
import Paper from '../../../../../components/Paper'
import EditMoveCustomerForm from '../../../../../components/forms/moves/EditMoveCustomerForm'
import { errorNotification, successNotification } from '../../../../../components/ToastNotifications'
import { useErrorFormatter } from '../../../../../components/errors/useErrorFormatter'
import MoveJourney from './MoveJourney'
import CustomerDetails from '../../../../../components/move/CustomerDetails'

type CustomerBoxProps = {
  move: FullMove
}

const CustomerBox: React.FC<CustomerBoxProps> = ({
  move,
}) => {
  const { t } = useTranslation()
  const { canEditCustomer } = useMoveState().forMove(move)

  const [updateMove] = useUpdateMoveAction()
  const [editing, setEditing] = useState(false)
  const formatError = useErrorFormatter()

  const handleSubmit = async (payload: Partial<MovePayload>) => {
    try {
      await updateMove(move.id, payload)
      successNotification(t('pages.admin.move.edit.updateSuccess'))
      setEditing(false)
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  return (
    <>
      <MoveJourney move={move} />
      <Paper className="rounded-t-none border-t-0">

        <div className="flex justify-between">
          <h2 className="mb-4 font-body2 text-2xl font-semibold text-slate-700">
            <UserIcon />
            { ' ' }
            { t('pages.admin.move.details.customer.title') }
          </h2>

          { !editing && (
            <div>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<EditIcon />}
                onClick={() => { setEditing(true) }}
                disabled={!canEditCustomer}
                className="!normal-case"
              >
                { t('actions.edit') }
              </Button>
            </div>
          ) }
        </div>

        <div className="pb-4">
          { editing
            ? (
              <EditMoveCustomerForm
                data={{ user: move.user }}
                onSubmit={handleSubmit}
                onCancel={() => { setEditing(false) }}
              />
              )
            : <CustomerDetails move={move} /> }

        </div>
      </Paper>
    </>
  )
}

export default CustomerBox
