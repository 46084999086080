import { type Amount } from '../../../common/amount'
import { useConfig } from '../../config'
import { type Payment } from './payment.models'
import {
  DisputeStatus,
  PaymentGatewayName,
  PaymentStatus,
} from './payment.types'

/**
 * generate a transaction url for a payment gateway
 * if the gateway doesn't support the feature, this returns undefined
 */
export const useGetPaymentGatewayTransactionUrl = () => {
  const stripeUrl = useConfig('stripe.dashboardUrl')

  return (payment: Payment) => {
    if (!payment.gateway || !payment.gatewayId) {
      return
    }

    switch (payment.gateway) {
      case PaymentGatewayName.stripe:
        return `${stripeUrl}/charges/${payment.gatewayId}`
      default:
        return undefined
    }
  }
}

/**
 * returns true if a payment is successful
 * a refund (partial or full) is considered successful
 */
export const useIsPaymentSuccessful = () => {
  return (payment?: Payment) => {
    if (!payment) {
      return false
    }
    if (!([
      PaymentStatus.succeeded,
      PaymentStatus.partiallyRefunded,
      PaymentStatus.refunded,
    ].includes(payment.status))) {
      return false
    }
    if (payment.dispute?.status && payment.dispute.status !== DisputeStatus.won) {
      return false
    }
    return true
  }
}

/**
 * returns the total amount really charged from a single payment
 * this subtracts refounds and ignore failed payments
 */
export const useGetPaymentTotalCharge = () => {
  const isPaymentSuccessful = useIsPaymentSuccessful()
  return (payment?: Payment) => {
    if (!payment || !isPaymentSuccessful(payment)) {
      return
    }
    return {
      price: payment.amount.price - (payment.refundedAmount?.price ?? 0),
      currency: payment.amount.currency,
    }
  }
}

/**
 * returns the total amount really charged from a collection of payments
 * this subtracts refounds and ignore failed payments
 */
export const useGetPaymentsTotalCharge = () => {
  const getPaymentTotalCharge = useGetPaymentTotalCharge()

  return (payments?: Payment[]) => {
    if (!payments) {
      return
    }

    return payments.reduce<Amount | undefined>((acc, curr) => {
      const total = getPaymentTotalCharge(curr)
      if (!total) {
        return acc
      }
      if (!acc) {
        return total
      }
      return {
        price: acc.price + total.price,
        currency: acc.currency,
      }
    }, undefined)
  }
}
