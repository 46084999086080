import sanitizeHtml from 'sanitize-html'

const allowedTags = [
  'p',
  'br',
  'strong',
  'em',
  'u',
  'ul',
  'ol',
  'li',
  'blockquote',
]

export const useSanitizedNote = (note?: string) => {
  if (!note) {
    return
  }
  return sanitizeHtml(note, {
    allowedTags,
    parseStyleAttributes: false,
    allowedAttributes: {},
    selfClosing: ['br'],
  })
}
