import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import CircularProgress from '@mui/material/CircularProgress'

import {
  Modal,
  ModalDescription,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '../../Modal'
import { useExport } from './useExport'

type ExportModalProps = {
  open: boolean
  onClose: () => void
}

const ExportModal: React.FC<ExportModalProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation()
  const {
    rowsToDownload,
    rowsDownloaded,
    total,
    progress,
    completed,
  } = useExport({
    enabled: open,
  })

  return (
    <Modal
      open={open}
      showCloseIcon={false}
      className="lg:max-w-[500px]"
    >
      <ModalHeader>
        <ModalTitle>{ t('actions.export') }</ModalTitle>
      </ModalHeader>
      <ModalDescription>
        <div className="flex items-center gap-x-8">
          <div className="flex size-[100px] items-center justify-center pt-4">
            <CircularProgress
              variant="determinate"
              value={100}
              size={100}
              thickness={1}
              className="absolute opacity-10"
              color="secondary"
            />
            <CircularProgress
              variant="determinate"
              value={progress}
              size={100}
              thickness={1}
              className="absolute"
            />
            <span className="font-sans text-xl">
              { progress } %
            </span>
          </div>
          <div>

            <div className="text-xl">
              Exported <strong>{ rowsDownloaded }</strong> / { rowsToDownload }.
            </div>

            { completed
              ? (
                <Alert severity="success" className="mt-4">
                  Export completed
                </Alert>
                )
              : (
                <div>
                  { rowsToDownload < total && (
                    <Alert severity="warning" className="mt-4">
                      Only exporting { rowsToDownload } records of { total }.
                    </Alert>
                  ) }
                </div>
                ) }

          </div>
        </div>

      </ModalDescription>
      <ModalFooter>
        <div>
          <Button
            variant="contained"
            color={completed ? 'secondary' : 'error'}
            size="medium"
            onClick={onClose}
          >
            { completed ? t('actions.close') : t('actions.cancel') }
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default ExportModal
