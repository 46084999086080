import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  usePreviewCustomerInvoiceForPartner,
  type CloseJobForPartnerPayload,
  type JobForPartner,
} from '../../../../modules/jobs'
import { type FullInvoiceEstimate } from '../../../../modules/invoices'
import { scrollToTop } from '../../../../utils/scroll'
import { Form } from '../../../form'
import { useErrorFormatter } from '../../../errors/useErrorFormatter'
import { errorNotification } from '../../../ToastNotifications'
import Charges from './Charges'
import { type CloseJobFormValues } from './CloseJobForm.types'
import { chargesToFormData, formatFormOutput } from './formDataBuilder'
import { useCollectionCharges } from './hooks'
import useValidationSchema from './validationSchema'
import PreviewConfirmationForm from './PreviewConfirmationForm/PreviewConfirmationForm'

type CloseJobFormProps = {
  job: JobForPartner
  onSubmit: (values: CloseJobForPartnerPayload) => void | Promise<void>
}

const CloseJobForm: React.FC<CloseJobFormProps> = ({
  job,
  onSubmit,
}) => {
  const schema = useValidationSchema()
  const collectionCharges = useCollectionCharges()
  const { getInvoicePreview } = usePreviewCustomerInvoiceForPartner()
  const formatError = useErrorFormatter()
  const [payload, setPayload] = useState<CloseJobForPartnerPayload>()
  const [invoicePreview, setInvoicePreview] = useState<FullInvoiceEstimate>()

  const form = useForm<CloseJobFormValues>({
    resolver: yupResolver(schema),
    defaultValues: chargesToFormData(job.quote.details, collectionCharges),
  })

  const handleFormSubmit = async (values: CloseJobFormValues) => {
    const payload = formatFormOutput(values)
    try {
      const preview = await getInvoicePreview(job.id, payload)
      setPayload(payload)
      setInvoicePreview(preview)
      scrollToTop()
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const handleCancel = () => {
    setInvoicePreview(undefined)
    scrollToTop()
  }

  if (invoicePreview && payload) {
    return (
      <PreviewConfirmationForm
        invoicePreview={invoicePreview}
        job={job}
        onSubmit={async () => { await onSubmit(payload) }}
        onCancel={handleCancel}
      />
    )
  }

  return (
    <Form
      form={form}
      onSubmit={handleFormSubmit}
    >
      <Charges
        job={job}
      />
    </Form>
  )
}

export default CloseJobForm
