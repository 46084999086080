import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import DeleteIcon from '@mui/icons-material/Delete'
import RestoreIcon from '@mui/icons-material/RestoreFromTrash'
import ApproveIcon from '@mui/icons-material/Done'
import UnapproveIcon from '@mui/icons-material/DoDisturbOn'
import LoginIcon from '@mui/icons-material/Login'
import PlayIcon from '@mui/icons-material/PlayCircleFilled'
import PauseIcon from '@mui/icons-material/PauseCircleFilled'

import Route from '../../../app/Route.enum'
import {
  type FullCompanyBranch,
  useApproveCompanyBranchesAction,
  useDeleteCompanyBranchesAction,
  usePauseCompanyBranchesAction,
  useRestoreCompanyBranchesAction,
  useResumeCompanyBranchesAction,
  useUnapproveCompanyBranchesAction,
} from '../../../modules/adminCompanies'
import { Permission, usePermission } from '../../../modules/users'
import { useNavigate } from '../../../modules/navigation'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { useConfirmation } from '../../../components/ConfirmationModal'

/**
 * returns the list of actions available inside of the data grid
 */
export const useActions = (companyBranches?: FullCompanyBranch[]) => {
  const { t } = useTranslation()
  const formatError = useErrorFormatter()
  const [deleteCompanyBranches] = useDeleteCompanyBranchesAction()
  const [restoreCompanyBranches] = useRestoreCompanyBranchesAction()
  const [approveCompanyBranches] = useApproveCompanyBranchesAction()
  const [unapproveCompanyBranches] = useUnapproveCompanyBranchesAction()
  const [pauseCompanyBranches] = usePauseCompanyBranchesAction()
  const [resumeCompanyBranches] = useResumeCompanyBranchesAction()
  const { navigate } = useNavigate()
  const { confirm, modalProps } = useConfirmation()
  const canReadCompanies = usePermission(Permission.canReadCompanies)
  const canManageCompanies = usePermission(Permission.canManageCompanies)

  if (!canReadCompanies && !canManageCompanies) {
    return {}
  }

  /**
   * delete company branche
   *  - show confirmation modal
   *  - show results in a toast
   */
  const handleDelete = async (ids: string | string[]) => {
    ids = Array.isArray(ids) ? ids : [ids]
    const count = ids.length

    const title = t('pages.admin.companies.actions.delete.confirmation.title', { count })
    const question = t('pages.admin.companies.actions.delete.confirmation.question', { count })

    if (!await confirm({ title, question, danger: true })) {
      return false
    }

    const pending = t('pages.admin.companies.actions.delete.pending', { count })
    const success = t('pages.admin.companies.actions.delete.success', { count })

    await toast.promise(
      deleteCompanyBranches({ ids }),
      {
        pending,
        success,
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  /**
   * restore company branche
   *  - show confirmation modal
   *  - show results in a toast
   */
  const handleRestore = async (ids: string | string[]) => {
    ids = Array.isArray(ids) ? ids : [ids]
    const count = ids.length

    const title = t('pages.admin.companies.actions.restore.confirmation.title', { count })
    const question = t('pages.admin.companies.actions.restore.confirmation.question', { count })

    if (!await confirm({ title, question, danger: true })) {
      return false
    }

    const pending = t('pages.admin.companies.actions.restore.pending', { count })
    const success = t('pages.admin.companies.actions.restore.success', { count })

    await toast.promise(
      restoreCompanyBranches({ ids }),
      {
        pending,
        success,
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  /**
   * approve company branche
   *  - show confirmation modal
   *  - show results in a toast
   */
  const handleApprove = async (ids: string | string[]) => {
    ids = Array.isArray(ids) ? ids : [ids]
    const count = ids.length

    const title = t('pages.admin.companies.actions.approve.confirmation.title', { count })
    const question = t('pages.admin.companies.actions.approve.confirmation.question', { count })

    if (!await confirm({ title, question })) {
      return false
    }

    const pending = t('pages.admin.companies.actions.approve.pending', { count })
    const success = t('pages.admin.companies.actions.approve.success', { count })

    await toast.promise(
      approveCompanyBranches({ ids }),
      {
        pending,
        success,
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  /**
   * unapprove company branche
   *  - show confirmation modal
   *  - show results in a toast
   */
  const handleUnapprove = async (ids: string | string[]) => {
    ids = Array.isArray(ids) ? ids : [ids]
    const count = ids.length

    const title = t('pages.admin.companies.actions.unapprove.confirmation.title', { count })
    const question = t('pages.admin.companies.actions.unapprove.confirmation.question', { count })

    if (!await confirm({ title, question, danger: true })) {
      return false
    }

    const pending = t('pages.admin.companies.actions.unapprove.pending', { count })
    const success = t('pages.admin.companies.actions.unapprove.success', { count })

    await toast.promise(
      unapproveCompanyBranches({ ids }),
      {
        pending,
        success,
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  /**
   * pause company branche
   *  - show confirmation modal
   *  - show results in a toast
   */
  const handlePause = async (ids: string | string[]) => {
    ids = Array.isArray(ids) ? ids : [ids]
    const count = ids.length

    const title = t('pages.admin.companies.actions.pause.confirmation.title', { count })
    const question = t('pages.admin.companies.actions.pause.confirmation.question', { count })

    if (!await confirm({ title, question, danger: true })) {
      return false
    }

    const pending = t('pages.admin.companies.actions.pause.pending', { count })
    const success = t('pages.admin.companies.actions.pause.success', { count })

    await toast.promise(
      pauseCompanyBranches({ ids }),
      {
        pending,
        success,
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  /**
   * resume company branche
   *  - show confirmation modal
   *  - show results in a toast
   */
  const handleResume = async (ids: string | string[]) => {
    ids = Array.isArray(ids) ? ids : [ids]
    const count = ids.length

    const title = t('pages.admin.companies.actions.resume.confirmation.title', { count })
    const question = t('pages.admin.companies.actions.resume.confirmation.question', { count })

    if (!await confirm({ title, question })) {
      return false
    }

    const pending = t('pages.admin.companies.actions.resume.pending', { count })
    const success = t('pages.admin.companies.actions.resume.success', { count })

    await toast.promise(
      resumeCompanyBranches({ ids }),
      {
        pending,
        success,
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  /**
   * edit user ; go to user's detail page
   */
  const handleOnBehalf = (id: string) => {
    const companyBranch = companyBranches?.find(companyBranch => companyBranch.id === id)
    if (companyBranch?.company?.id) {
      navigate(Route.BranchSettingsLandingPage, { companyId: companyBranch.company.id })
    }
  }

  /* format actions for data grid */

  const deleteAction = {
    label: t('actions.delete'),
    icon: DeleteIcon,
    danger: true,
    callback: handleDelete,
    bulkCallback: handleDelete,
    filter: (ids: string[]) => {
      if (!canManageCompanies) {
        return false
      }
      const selected = companyBranches?.filter(companyBranch => ids.includes(companyBranch.id)) ?? []
      const elligibles = selected?.filter(companyBranch => {
        return !companyBranch.deletedAt
      })
      return elligibles?.length > 0 && selected?.length === elligibles?.length
    },
  }

  const restoreAction = {
    label: t('actions.restore'),
    icon: RestoreIcon,
    danger: true,
    callback: handleRestore,
    bulkCallback: handleRestore,
    filter: (ids: string[]) => {
      if (!canManageCompanies) {
        return false
      }
      const selected = companyBranches?.filter(companyBranch => ids.includes(companyBranch.id)) ?? []
      const elligibles = selected?.filter(companyBranch => {
        return !!companyBranch.deletedAt
      })
      return elligibles?.length > 0 && selected?.length === elligibles?.length
    },
  }

  const approveAction = {
    label: t('actions.approve'),
    icon: ApproveIcon,
    callback: handleApprove,
    bulkCallback: handleApprove,
    filter: (ids: string[]) => {
      if (!canManageCompanies) {
        return false
      }
      const selected = companyBranches?.filter(companyBranch => ids.includes(companyBranch.id)) ?? []
      const elligibles = selected?.filter(companyBranch => {
        return !companyBranch.approved &&
          !companyBranch.deletedAt &&
          companyBranch.setupProgress === 100
      })
      return elligibles?.length > 0 && selected?.length === elligibles?.length
    },
  }

  const unapproveAction = {
    label: t('actions.unapprove'),
    icon: UnapproveIcon,
    danger: true,
    callback: handleUnapprove,
    bulkCallback: handleUnapprove,
    filter: (ids: string[]) => {
      if (!canManageCompanies) {
        return false
      }
      const selected = companyBranches?.filter(companyBranch => ids.includes(companyBranch.id)) ?? []
      const elligibles = selected?.filter(companyBranch => {
        return !!companyBranch.approved && !companyBranch.deletedAt
      })
      return elligibles?.length > 0 && selected?.length === elligibles?.length
    },
  }

  const pauseAction = {
    label: t('actions.pause'),
    icon: PauseIcon,
    danger: true,
    callback: handlePause,
    bulkCallback: handlePause,
    filter: (ids: string[]) => {
      if (!canManageCompanies) {
        return false
      }
      const selected = companyBranches?.filter(companyBranch => ids.includes(companyBranch.id)) ?? []
      const elligibles = selected?.filter(companyBranch => {
        return !!companyBranch.active &&
          !companyBranch.deletedAt
      })
      return elligibles?.length > 0 && selected?.length === elligibles?.length
    },
  }

  const resumeAction = {
    label: t('actions.resume'),
    icon: PlayIcon,
    callback: handleResume,
    bulkCallback: handleResume,
    filter: (ids: string[]) => {
      if (!canManageCompanies) {
        return false
      }
      const selected = companyBranches?.filter(companyBranch => ids.includes(companyBranch.id)) ?? []
      const elligibles = selected?.filter(companyBranch => {
        return !companyBranch.active && !companyBranch.deletedAt
      })
      return elligibles?.length > 0 && selected?.length === elligibles?.length
    },
  }

  const onBehalfAction = {
    label: t('actions.connectOnDashboard'),
    icon: LoginIcon,
    callback: handleOnBehalf,
    filter: (ids: string[]) => {
      if (!canReadCompanies) {
        return false
      }
      const selected = companyBranches?.filter(companyBranch => ids.includes(companyBranch.id)) ?? []
      const elligibles = selected?.filter(companyBranch => {
        if (companyBranch.deletedAt) {
          return false
        }
        return true
      })
      return elligibles?.length > 0 && selected?.length === elligibles?.length
    },
  }

  /* returns actions and the props needed to render the confirmation modal */

  return {
    actions: [
      deleteAction,
      restoreAction,
      approveAction,
      unapproveAction,
      pauseAction,
      resumeAction,
      onBehalfAction,
    ],
    modalProps,
  }
}
