import { useEffect } from 'react'

import { useLanguage } from '../modules/i18n'
import { useCurrentUser, useUpdateUserLanguageAction } from '../modules/users'

const LanguagePersister: React.FC = () => {
  const { data: user } = useCurrentUser()
  const language = useLanguage()
  const { updateLanguage } = useUpdateUserLanguageAction()

  /**
   * set current app language as user's language
   */
  useEffect(() => {
    if (!user || user.language === language) {
      return
    }
    updateLanguage().catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, language])

  return null
}

export default LanguagePersister
