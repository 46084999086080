import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { type JobForPartner } from '../../../../../modules/jobs'
import { type ChargeDescription, type ChargeCategory } from '../../../../../modules/invoices'
import { SubmitButton } from '../../../../form'
import { type AddChargeOptions, type CloseJobFormValues } from '../CloseJobForm.types'
import {
  useFieldSortedByCategory,
  useGetChargeCollectionState,
  useAvailableChargesToAdd,
  useGetDescriptionParentCategory,
  useGetDefinition,
  useGetCategoryAndDescription,
  useGetChargePrice,
  useGetChargeDefaultItem,
} from '../hooks'
import ChargeCategoryIcon from './ChargeCategoryIcon'
import AddChargeDropdown from './AddChargeDropdown'
import ChargeRow from './ChargeRow'
import CollectionRow from './CollectionRow'

type ChargesProps = {
  job: JobForPartner
}

const Charges: React.FC<ChargesProps> = ({
  job,
}) => {
  const { t } = useTranslation()
  const form = useFormContext<CloseJobFormValues>()
  const {
    fields,
    append,
    remove,
  } = useFieldArray({ control: form.control, name: 'charges' })
  const [autoFocus, setAutoFocus] = useState(false)
  const availableChargesToAdd = useAvailableChargesToAdd(job, fields)
  const rowsByCategory = useFieldSortedByCategory(fields)
  const getChargeCollectionState = useGetChargeCollectionState()
  const getDescriptionParentCategory = useGetDescriptionParentCategory()
  const getDefinition = useGetDefinition()
  const getCategoryAndDescription = useGetCategoryAndDescription()
  const getChargePrice = useGetChargePrice(job)
  const getChargeDefaultItem = useGetChargeDefaultItem(job)
  const nbCharges = fields.length

  /**
   * add a new charge
   */
  const handleAddCharge = (description: ChargeDescription) => {
    const category = getDescriptionParentCategory(description)
    const categoryAndDescription = `${category}.${description}`
    const { collection } = getDefinition(description)

    setAutoFocus(true)
    if (collection) {
      append({
        categoryAndDescription,
        quantity: '',
        unitPrice: '',
      }, {
        shouldFocus: false,
      })
      return
    }

    append({
      categoryAndDescription,
      quantity: '',
      unitPrice: String(getChargePrice(description)),
      item: getChargeDefaultItem(description),
    }, {
      shouldFocus: false,
    })
  }

  /**
   * add new row
   */
  const handleAddCollectionCharge = (description: ChargeDescription, {
    item,
    price,
  }: AddChargeOptions) => {
    const category = getDescriptionParentCategory(description)
    const categoryAndDescription = `${category}.${description}`

    append({
      categoryAndDescription,
      quantity: '1',
      item,
      unitPrice: price,
    }, {
      shouldFocus: false,
    })
  }

  /**
   * remove a charge
   */
  const handleDeleteCharge = (index: number) => {
    if (nbCharges <= 1) {
      return
    }

    const field = fields[index]
    const description = getCategoryAndDescription(field.categoryAndDescription).description
    const { collection } = getDefinition(description)
    const similarItems = fields.filter(f => f.categoryAndDescription === field.categoryAndDescription)
    const isLastItem = similarItems.length <= 2

    if (collection && isLastItem) {
      const indexesToRemove = similarItems.map(item => fields.indexOf(item))
      remove(indexesToRemove)
      return
    }

    remove(index)
  }

  return (
    <>
      <h1 className="mb-8 border-b pb-2 text-center font-body2 text-[1.6rem] leading-7 lg:pb-1 lg:text-left">
        <span className="block text-base lg:inline lg:text-[1.6rem]">
          { t('forms.companyBranch.closeJob.title.step', { step: 1, totalSteps: 2 }) }
          { ' : ' }
        </span>
        <span className="font-bold">
          { t('forms.companyBranch.closeJob.title.step1') }
        </span>
      </h1>

      <div className="flex flex-col gap-y-5">
        { Object.entries(rowsByCategory).map(([rawCategory, rows]) => {
          const category = rawCategory as ChargeCategory
          const categoryName = t(`quotes.pricing.categories.${category}`)

          if (rows.length === 0) {
            return null
          }

          return (
            <Fragment key={category}>
              <div className="my-6 flex items-center justify-center gap-x-2 text-xl text-neutral-400 lg:my-0 lg:justify-normal lg:text-base">
                <ChargeCategoryIcon category={category} />
                { ' ' }
                { categoryName }
              </div>

              { rows.map(([charge, index]) => {
                const {
                  isCollection,
                  collectionItems,
                } = getChargeCollectionState(charge, fields)

                if (collectionItems.length) {
                  return (
                    <CollectionRow
                      key={charge.id}
                      index={index}
                      job={job}
                      charges={collectionItems}
                      onAdd={handleAddCollectionCharge}
                      onDelete={handleDeleteCharge}
                    />
                  )
                }

                if (isCollection) {
                  return null
                }

                return (
                  <ChargeRow
                    key={charge.id}
                    index={index}
                    job={job}
                    onDelete={() => { handleDeleteCharge(index) }}
                    autoFocus={autoFocus}
                  />
                )
              }) }

            </Fragment>
          )
        }) }

        <div className="mt-8 flex items-center justify-between">
          <div>
            <AddChargeDropdown
              options={availableChargesToAdd}
              onSelect={handleAddCharge}
            />
          </div>
          <div>
            <SubmitButton>
              { t('actions.next') }
            </SubmitButton>
          </div>
        </div>
      </div>
    </>
  )
}

export default Charges
