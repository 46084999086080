import { useMutation } from '@apollo/client'

import { type CompanyBranch } from './companies.models'
import * as mutations from './companies.mutations'
import { type CompanyBranchPayload, type CompanyPayload } from './companies.types'
import { useCurrentCompanyBranch, useOnBehalfState } from '..'

/**
 * create a company
 */
export const useCreateCompanyAction = () => {
  const [createCompany, { data, loading, ...mutationProps }] = useMutation(mutations.createCompany)

  return {
    createCompany: async (payload: CompanyPayload) => {
      const { data } = await createCompany({
        variables: {
          payload,
        },
      })

      return data.createCompany
    },
    data: data?.createCompany,
    loading,
    ...mutationProps,
  }
}

/**
 * update a company
 */
export const useUpdateCompanyAction = () => {
  const [updateCompany, { data, loading, ...mutationProps }] = useMutation(mutations.updateCompany)
  const { onBehalfCompanyId } = useOnBehalfState()

  return {
    updateCompany: async (payload: CompanyPayload) => {
      const { data } = await updateCompany({
        variables: {
          payload,
          onBehalfCompanyId,
        },
      })

      return data.updateCompany
    },
    data: data?.updateCompany,
    loading,
    ...mutationProps,
  }
}

/**
 * update a company branch
 */
export const useUpdateCompanyBranchAction = () => {
  const [updateCompanyBranch, { data, loading, ...mutationProps }] = useMutation(mutations.updateCompanyBranch)
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()

  return {
    updateCompanyBranch: async (payload: CompanyBranchPayload, companyBranchId?: string): Promise<CompanyBranch> => {
      const { data } = await updateCompanyBranch({
        variables: {
          branchId: companyBranchId ?? currentCompanyBranch?.id,
          payload,
        },
      })

      return data.updateCompanyBranch
    },
    data: data?.updateCompanyBranch,
    loading,
    ...mutationProps,
  }
}

/**
 * delete legal documents from a company branch
 */
export const useDeleteLegalDocumentsAction = () => {
  const [deleteLegalDocuments, { data, loading, ...mutationProps }] = useMutation(mutations.deleteLegalDocuments)
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()

  return {
    deleteLegalDocuments: async (fileIds: string[]): Promise<CompanyBranch> => {
      const { data } = await deleteLegalDocuments({
        variables: {
          branchId: currentCompanyBranch?.id,
          fileIds,
        },
      })

      return data.deleteLegalDocuments
    },
    data: data?.deleteLegalDocuments,
    loading,
    ...mutationProps,
  }
}
