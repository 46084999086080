import { useAction } from '../../graphQl'
import * as mutations from './adminCompanies.mutations'
import {
  type FullCompany,
  type FullCompanyBranch,
} from './adminCompanies.models'
import {
  type GetInvitationUserIdPayload,
  type InvitePartnerPayload,
} from './adminCompanies.type'

/**
 * delete multiple company branches
 */
export const useDeleteCompanyBranchesAction = () => {
  return useAction<FullCompanyBranch[], { ids: string[] }>(mutations.deleteCompanyBranches)
}

/**
 * restore multiple company branches
 */
export const useRestoreCompanyBranchesAction = () => {
  return useAction<FullCompanyBranch[], { ids: string[] }>(mutations.restoreCompanyBranches)
}

/**
 * approve multiple company branches
 */
export const useApproveCompanyBranchesAction = () => {
  return useAction<FullCompanyBranch[], { ids: string[] }>(mutations.approveCompanyBranches)
}

/**
 * unapprove multiple company branches
 */
export const useUnapproveCompanyBranchesAction = () => {
  return useAction<FullCompanyBranch[], { ids: string[] }>(mutations.unapproveCompanyBranches)
}

/**
 * pause multiple company branches
 */
export const usePauseCompanyBranchesAction = () => {
  return useAction<FullCompanyBranch[], { ids: string[] }>(mutations.pauseCompanyBranches)
}

/**
 * resume multiple company branches
 */
export const useResumeCompanyBranchesAction = () => {
  return useAction<FullCompanyBranch[], { ids: string[] }>(mutations.resumeCompanyBranches)
}

/**
 * get user id for given email (invitation)
 */
export const useGetInvitationUserIdAction = () => {
  return useAction<string, GetInvitationUserIdPayload>(mutations.getInvitationUserId)
}

/**
 * invite a partner
 */
export const useInvitePartnerAction = () => {
  return useAction<FullCompany, InvitePartnerPayload>(mutations.invitePartner)
}

/**
 * cancel a partner invitation
 */
export const useCancelPartnerInvitationAction = () => {
  return useAction<FullCompany, { invitationId: string }>(mutations.cancelPartnerInvitation)
}

/**
 * dissociate a partner from a company
 */
export const useDissociatePartnerAction = () => {
  return useAction<FullCompany, { companyId: string }>(mutations.dissociatePartner)
}
