import { useMemo } from 'react'
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'

import { getTheme } from '../../../theme'
import { useThemeState } from '..'

type ThemeProviderProps = {
  children?: React.ReactNode
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const { darkModeEnabled } = useThemeState()

  const theme = useMemo(() => {
    return getTheme(darkModeEnabled)
  }, [darkModeEnabled])

  return (
    <EmotionThemeProvider theme={theme}>
      { children }
    </EmotionThemeProvider>
  )
}

export default ThemeProvider
