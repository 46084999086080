import { useTranslation } from 'react-i18next'

import { type PaymentPayload } from '../../../../../modules/payment'
import { type FullMove } from '../../../../../modules/adminMoves'
import { useCreatePaymentAction } from '../../../../../modules/payment/core/payments.actions.hooks'
import { mergeClassName } from '../../../../../utils/mergeClassName'
import {
  Modal,
  ModalDescription,
  ModalHeader,
  ModalTitle,
} from '../../../../../components/Modal'
import { useErrorFormatter } from '../../../../../components/errors/useErrorFormatter'
import {
  errorNotification,
  successNotification,
} from '../../../../../components/ToastNotifications'
import PaymentForm from '../../../../../components/forms/payment/PaymentForm'

type CreatePaymentModalProps = {
  move: FullMove
  open: boolean
  onClose: () => void
}

const CreatePaymentModal: React.FC<CreatePaymentModalProps> = ({
  move,
  open,
  onClose,
}) => {
  const { t } = useTranslation()
  const [createPayment] = useCreatePaymentAction()
  const formatError = useErrorFormatter()

  const handleSubmit = async (payload: PaymentPayload) => {
    try {
      await createPayment(move.id, payload)
      successNotification(t('pages.admin.move.accounting.createPaymentModal.createPaymentSuccess'))
      onClose()
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  return (
    <Modal
      open={open}
      className={mergeClassName(
        'lg:max-w-screen-md',
      )}
      showCloseIcon={false}
    >
      <ModalHeader>
        <ModalTitle>
          { t('pages.admin.move.accounting.createPaymentModal.title') }
        </ModalTitle>
      </ModalHeader>
      <ModalDescription>

        <PaymentForm
          move={move}
          onCancel={onClose}
          onSubmit={handleSubmit}
        />

      </ModalDescription>
    </Modal>
  )
}

export default CreatePaymentModal
