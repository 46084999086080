import { type FullCompanyBranch } from '../../modules/adminCompanies'
import { type BasicUser } from '../../modules/users'

export enum AuthorSource {
  Customer,
  Partner,
  MovingWaldo,
}

export type Author = {
  source: AuthorSource.MovingWaldo
} | {
  source: AuthorSource.Partner
  companyBranch: Partial<FullCompanyBranch>
} | {
  source: AuthorSource.Customer
  user: Omit<BasicUser, 'id' | 'email'>
}
