import { useTranslation } from 'react-i18next'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import { useDataGridContext } from '../../DataGridContext'

const DeletedRowToggleSwitch: React.FC = () => {
  const { t } = useTranslation()
  const { query, setQuery, loading, disabled } = useDataGridContext()

  const onClick = () => {
    setQuery({
      ...query,
      excludeDeleted: !query.excludeDeleted,
      page: 1,
    })
  }

  return (
    <FormControlLabel
      control={(
        <Switch
          checked={!query.excludeDeleted}
          onClick={onClick}
          disabled={loading || disabled}
        />
      )}
      label={t('components.dataGrid.header.showDeleted')}
    />
  )
}

export default DeletedRowToggleSwitch
