import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/AddCircleOutline'

import ContainedPage from '../../../components/layout/ContainedPage'
import PageContent from '../../../components/layout/PageContent'
import PageTitle from '../../../components/layout/PageTitle'
import { Permission, protectAdminPage, usePermission } from '../../../modules/users'
import {
  type GridQueryVariables,
  Cell,
  Column,
  DataGrid,
  DateValue,
  Row,
  SmallValue,
} from '../../../components/dataGrid'
import { SortDirection } from '../../../modules/graphQl'
import ServerError from '../../../components/errors/ServerError'
import { usePromoCodes, usePromoCodeLabel } from '../../../modules/adminPromoCodes'
import { useActions } from './useActions'
import ConfirmationModal from '../../../components/ConfirmationModal'
import { useNavigate } from '../../../modules/navigation'
import Route from '../../../app/Route.enum'

const PromoCodesPage: React.FC = () => {
  const { t } = useTranslation()
  const { fetchPromoCodes, data: promoCodes, error: promoCodesErrors } = usePromoCodes()
  const { actions, modalProps } = useActions(promoCodes?.data)
  const canManagePromoCodes = usePermission(Permission.canManagePromoCodes)
  const { navigate } = useNavigate()
  const getLabel = usePromoCodeLabel()

  const onQueryUpdate = useCallback(async ({ ...query }: GridQueryVariables) => {
    return await fetchPromoCodes({ ...query, excludeDeleted: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleRowClick = (id: string, newTab = false) => {
    navigate(Route.EditPromoCode, { promoCodeId: id }, newTab)
  }

  if (promoCodesErrors) {
    return <ContainedPage><ServerError error={promoCodesErrors} /></ContainedPage>
  }

  return (
    <>
      <ContainedPage>
        <PageTitle fullWidth>
          <div className="flex flex-col justify-between gap-4 lg:flex-row">
            { t('pages.admin.promoCodes.title') }

            { canManagePromoCodes && (
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<AddIcon />}
                  onClick={() => { navigate(Route.CreatePromoCode) }}
                >
                  { t('actions.create') }
                </Button>
              </div>
            ) }
          </div>
        </PageTitle>
        <PageContent fullWidth className="px-3 py-8 pr-4">

          <DataGrid
            name="promoCodes"
            count={promoCodes?.count ?? 0}
            onQueryUpdate={onQueryUpdate}
            initialQuery={{ sortField: 'createdAt', sortDirection: SortDirection.DESC }}
            actions={actions}
            columns={(
              <>
                <Column name="createdAt" sortable>
                  { t('pages.admin.promoCodes.table.columns.createdAt') }
                </Column>
                <Column name="deletedAt" sortable>
                  { t('pages.admin.promoCodes.table.columns.deletedAt') }
                </Column>
                <Column name="code" sortable>
                  { t('pages.admin.promoCodes.table.columns.code') }
                </Column>
                <Column name="value" sortable>
                  { t('pages.admin.promoCodes.table.columns.value') }
                </Column>
              </>
            )}
          >
            { promoCodes?.data?.map(promoCode => (
              <Row
                key={promoCode.id}
                id={promoCode.id}
                disabled={!!promoCode.deletedAt}
                onClick={handleRowClick}
              >
                <Cell><SmallValue><DateValue date={promoCode.createdAt} /></SmallValue></Cell>
                <Cell><SmallValue><DateValue date={promoCode.deletedAt} /></SmallValue></Cell>
                <Cell>
                  { promoCode.code }
                </Cell>
                <Cell expand>

                  { getLabel(promoCode) }

                </Cell>
              </Row>
            )) }
          </DataGrid>
        </PageContent>

      </ContainedPage>

      { modalProps && <ConfirmationModal {...modalProps} /> }
    </>
  )
}

export default protectAdminPage(PromoCodesPage)
