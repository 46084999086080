import { type DataOptions, useData } from '../../graphQl'
import { type User } from './users.models'
import * as queries from './users.queries'
import { useAuthenticationState } from './authentication'

/**
 * returns current user profile
 */
export const useCurrentUser = (dataOptions?: DataOptions) => {
  const { isAuthenticated } = useAuthenticationState()

  return useData<User>(queries.getUser, {
    ...dataOptions,
    skip: !!dataOptions?.skip || !isAuthenticated,
  })
}

/**
 * returns a partner invitation
 */
export const usePartnerInvitation = (invitationId?: string, dataOptions?: DataOptions) => {
  return useData<User>(
    queries.getPartnerInvitation,
    {
      ...dataOptions,
      variables: { invitationId },
    },
  )
}
