import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Alert from '@mui/material/Alert'
import AssignIcon from '@mui/icons-material/PersonAddAltOutlined'
import UnassignIcon from '@mui/icons-material/PersonRemoveOutlined'
import RefreshIcon from '@mui/icons-material/Autorenew'
import EmailIcon from '@mui/icons-material/EmailOutlined'
import CancelIcon from '@mui/icons-material/CancelOutlined'
import PromoCodeIcon from '@mui/icons-material/LocalOfferOutlined'
import ApproveMoveIcon from '@mui/icons-material/ThumbUpAltOutlined'
import PayBalanceIcon from '@mui/icons-material/MonetizationOnOutlined'

import {
  type FullMove,
  useApplyPromoCodeAction,
  useApproveMoveAction,
  useAssignAgentToMoveAction,
  useCancelJobRequestAction,
  useCancelJobAction,
  useCancelSelectedQuoteAction,
  useRefreshQuotesAction,
  useSelectBranchAction,
  useSendMoveToSmartMovingAction,
  useSendQuoteByEmailAction,
  useUnassignAgentToMoveAction,
  useCollectTotalBalanceAction,
  useSendPaymentRequestEmailAction,
} from '../../../modules/adminMoves'
import { useMoveState } from '../../../modules/moves'
import { formatPrice } from '../../../utils/money'
import { ReactComponent as SmartMovingLogo } from '../../../assets/providers/SmartMoving.svg'
import { useConfirmation } from '../../../components/ConfirmationModal'
import { useErrorFormatter } from '../../../components/errors/useErrorFormatter'
import { errorNotification } from '../../../components/ToastNotifications'
import ApproveMoveConfirmationForm, {
  type ApproveMoveFormValues,
} from '../../../components/forms/moves/ApproveMoveConfirmationForm'
import SendQuoteEmailConfirmationForm, {
  type SendQuoteEmailFormValues,
} from '../../../components/forms/moves/SendQuoteEmailConfirmationForm'

export enum ActionName {
  AssignAgentToMove,
  UnassignAgentToMove,
  RefreshQuotes,
  CancelSelectedQuote,
  ApproveMove,
  CancelJobRequest,
  CancelJob,
  SendQuoteEmail,
  ApplyPromoCode,
  RemovePromoCode,
  SelectAndCustomizeQuoteAction,
  CollecteTotalBalance,
  AskForPayment,
  SendToSmartMoving,
}

export type Action = {
  label: React.ReactNode
  Icon?: React.FC<{ className?: string }>
  callback: (args?: any) => Promise<void> | void
  enabled: boolean
  global?: boolean
  addSeparator?: boolean
  danger?: boolean
  hidden?: boolean
}

/**
 * returns the list of actions available for a given move
 */
export const useActions = (move: FullMove) => {
  const { t } = useTranslation()
  const { confirm, modalProps } = useConfirmation()
  const formatError = useErrorFormatter()

  const [assignAgentToMove] = useAssignAgentToMoveAction()
  const [unassignAgentToMove] = useUnassignAgentToMoveAction()
  const [refreshQuotes] = useRefreshQuotesAction()
  const [sendQuoteByEmail] = useSendQuoteByEmailAction()
  const [cancelSelectedQuote] = useCancelSelectedQuoteAction()
  const [applyPromoCode] = useApplyPromoCodeAction()
  const [selectbranch] = useSelectBranchAction()
  const [sendMoveToSmartMoving] = useSendMoveToSmartMovingAction()
  const [approveMove] = useApproveMoveAction()
  const [cancelJobRequest] = useCancelJobRequestAction()
  const [cancelJob] = useCancelJobAction()
  const [collectTotalBalance] = useCollectTotalBalanceAction()
  const [sendPaymentRequestEmail] = useSendPaymentRequestEmailAction()

  const {
    selectedQuote,
    nbQuotes,
    isAcceptedByCustomer,
    hasPaymentMethod,
    balance,
    canSendToSmartMoving,
    canAssign,
    canUnassign,
    canRefreshQuotes,
    canCancelSelectedQuote,
    canSelectAndCustomizeQuote,
    canApplyPromoCode,
    canRemovePromoCode,
    canSendQuoteEmail,
    canApproveMove,
    canCancelJobRequest,
    canCancelJob,
    canCollectTotalBalance,
    canAskForPayment,
  } = useMoveState().forMove(move)

  /**
   * assign current agent to a move
   */
  const handleAssignAgentToMove = async () => {
    const hasAgent = !!move?.agent?.id

    if (hasAgent && !await confirm({
      title: t('pages.admin.move.actions.assignAgentToMove.confirmation.title'),
      question: t('pages.admin.move.actions.assignAgentToMove.confirmation.question'),
    })) {
      return
    }

    await toast.promise(
      assignAgentToMove(move.id),
      {
        pending: t('pleaseWait'),
        success: t('pages.admin.move.actions.assignAgentToMove.success'),
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  /**
   * unassign current agent to a move
   */
  const handleUnassignAgentToMove = async () => {
    if (!await confirm({
      title: t('pages.admin.move.actions.unassignAgentToMove.confirmation.title'),
      question: t('pages.admin.move.actions.unassignAgentToMove.confirmation.question'),
    })) {
      return
    }

    await toast.promise(
      unassignAgentToMove(move.id),
      {
        pending: t('pleaseWait'),
        success: t('pages.admin.move.actions.unassignAgentToMove.success'),
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  /**
   * refresh the quotes
   */
  const handleRefreshQuotes = async () => {
    await toast.promise(
      refreshQuotes(move.id),
      {
        pending: t('pleaseWait'),
        success: t('pages.admin.move.actions.refreshQuotes.success'),
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  /**
   * send quote(s) by email
   */
  const handleSendQuoteEmail = async () => {
    const response: SendQuoteEmailFormValues | boolean = await confirm({
      title: t('pages.admin.move.actions.sendQuoteEmail.confirmation.title', {
        count: selectedQuote ? 1 : nbQuotes,
      }),
      render: (props: any) => <SendQuoteEmailConfirmationForm {...props} move={move} />,
    })

    if (response === false) {
      return
    }

    const payload = response === true ? null : response

    await toast.promise(
      sendQuoteByEmail({ moveId: move.id, payload: payload ?? {} }),
      {
        pending: t('pleaseWait'),
        success: t('pages.admin.move.actions.sendQuoteEmail.success'),
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  /**
   * remove selected quote (accepted or not)
   */
  const handleCancelSelectedQuote = async () => {
    if (!await confirm({
      title: t('pages.admin.move.actions.cancelSelectedQuote.confirmation.title'),
      question: t('pages.admin.move.actions.cancelSelectedQuote.confirmation.question'),
      danger: true,
    })) {
      return
    }

    await toast.promise(
      cancelSelectedQuote(move.id),
      {
        pending: t('pleaseWait'),
        success: t('pages.admin.move.actions.cancelSelectedQuote.success'),
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  /**
   * replace selected quote (if any) and customize it
   */
  const handleSelectAndCustomizeQuote = async (quoteId: string) => {
    const changeSelection = !!selectedQuote && selectedQuote.id !== quoteId
    if (changeSelection || isAcceptedByCustomer) {
      const question = isAcceptedByCustomer
        ? t('pages.admin.move.actions.selectAndCustomizeQuote.confirmation.questionWithAcceptedQuote')
        : t('pages.admin.move.actions.selectAndCustomizeQuote.confirmation.question')

      if (!await confirm({
        title: t('pages.admin.move.actions.selectAndCustomizeQuote.confirmation.title'),
        question,
      })) {
        return
      }
    }

    const quote = move.quotes?.find(quote => quote.id === quoteId)
    const branchId = quote?.companyBranch.id

    if (!branchId) {
      errorNotification('Invalid quote')
      return
    }

    await toast.promise(
      selectbranch({ moveId: move.id, branchId }),
      {
        pending: t('pleaseWait'),
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  /**
   * apply promo code to move
   */
  const handleApplyPromoCode = async (promoCode: string) => {
    await toast.promise(
      applyPromoCode({ moveId: move.id, promoCode }),
      {
        pending: t('pleaseWait'),
        success: t('pages.admin.move.actions.applyPromoCode.success'),
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  /**
   * remove promo code from move
   */
  const handleRemovePromoCode = async () => {
    if (!await confirm({
      title: t('pages.admin.move.actions.removePromoCode.confirmation.title'),
      question: t('pages.admin.move.actions.removePromoCode.confirmation.question'),
      danger: true,
    })) {
      return
    }

    await toast.promise(
      applyPromoCode({ moveId: move.id, promoCode: null }),
      {
        pending: t('pleaseWait'),
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  /**
   * send to SmartMoving
   *  - show confirmation modal
   *  - show results in a toast
   */
  const handleSendToSmartMoving = async () => {
    const showWarning = nbQuotes === 0 || isAcceptedByCustomer

    const title = t('pages.admin.move.actions.sendMoveToSmartMoving.confirmation.title')
    const question = (
      <>
        { t('pages.admin.move.actions.sendMoveToSmartMoving.confirmation.question') }
        { showWarning && (
          <div className="my-2">
            <Alert severity="warning">
              { t('pages.admin.move.actions.sendMoveToSmartMoving.confirmation.warningAlreadyExists') }
            </Alert>
          </div>
        ) }
      </>
    )

    if (!await confirm({ title, question })) {
      return
    }

    await toast.promise(
      sendMoveToSmartMoving(move.id),
      {
        pending: t('pleaseWait'),
        success: t('pages.admin.move.actions.sendMoveToSmartMoving.success'),
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  /**
   * approve move
   */
  const handleApproveMove = async () => {
    const response: ApproveMoveFormValues | boolean = await confirm({
      title: t('pages.admin.move.actions.approveMove.confirmation.title'),
      render: (props: any) => <ApproveMoveConfirmationForm {...props} moveId={move.id} />,
    })

    if (response === false) {
      return
    }

    const payload = response === true ? null : response

    await toast.promise(
      approveMove(move.id, payload ?? undefined),
      {
        pending: t('pleaseWait'),
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  /**
   * cancel pending job request
   */
  const handleCancelJobRequest = async () => {
    if (!await confirm({
      title: t('pages.admin.move.actions.cancelJobRequest.confirmation.title'),
      question: t('pages.admin.move.actions.cancelJobRequest.confirmation.question'),
      danger: true,
    })) {
      return
    }

    await toast.promise(
      cancelJobRequest(move.id),
      {
        pending: t('pleaseWait'),
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  /**
   * cancel job
   */
  const handleCancelJob = async (jobId: string) => {
    if (!await confirm({
      title: t('pages.admin.move.actions.cancelJob.confirmation.title'),
      question: t('pages.admin.move.actions.cancelJob.confirmation.question'),
      danger: true,
    })) {
      return
    }

    await toast.promise(
      cancelJob(move.id, jobId),
      {
        pending: t('pleaseWait'),
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  /**
   * collect total customer balance
   */
  const handleCollectTotalBalance = async () => {
    const cardBrand = move.paymentProfile?.paymentMethod?.brand
    if (!cardBrand) {
      return
    }

    if (!await confirm({
      title: t('pages.admin.move.actions.collectTotalBalance.confirmation.title'),
      question: t('pages.admin.move.actions.collectTotalBalance.confirmation.question', {
        amount: formatPrice(balance, { showCurrency: true, compact: true }),
        cardBrand,
      }),
    })) {
      return
    }

    await toast.promise(
      collectTotalBalance(move.id),
      {
        pending: t('pleaseWait'),
        success: t('pages.admin.move.actions.collectTotalBalance.success'),
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  /**
   * send an email to the customer with a link to pay his balance
   */
  const handleSendPaymentRequestEmail = async () => {
    if (!balance || balance.price <= 0) {
      return
    }

    if (!await confirm({
      title: t('pages.admin.move.actions.sendPaymentRequestEmail.confirmation.title'),
      question: t('pages.admin.move.actions.sendPaymentRequestEmail.confirmation.question', {
        amount: formatPrice(balance, { showCurrency: true, compact: true }),
      }),
    })) {
      return
    }

    await toast.promise(
      sendPaymentRequestEmail(move.id),
      {
        pending: t('pleaseWait'),
        success: t('pages.admin.move.actions.sendPaymentRequestEmail.success'),
        error: {
          render ({ data: error }) {
            return formatError(error)
          },
        },
      },
    )
  }

  /* actions list */

  const assignAgentToMoveAction = {
    label: t('actions.assignAgentToMove'),
    Icon: AssignIcon,
    danger: canAssign && !!move.agent,
    callback: handleAssignAgentToMove,
    enabled: canAssign,
    global: true,
    hidden: canUnassign,
  }

  const unassignAgentToMoveAction = {
    label: t('actions.unassignAgentToMove'),
    Icon: UnassignIcon,
    danger: true,
    callback: handleUnassignAgentToMove,
    enabled: canUnassign,
    global: true,
    hidden: canAssign,
  }

  const approveMoveAction = {
    label: t('actions.approveMove'),
    Icon: ApproveMoveIcon,
    callback: handleApproveMove,
    enabled: canApproveMove,
    global: true,
    addSeparator: true,
  }

  const cancelJobRequestAction = {
    label: t('actions.cancelJobRequest'),
    Icon: CancelIcon,
    callback: handleCancelJobRequest,
    enabled: canCancelJobRequest,
    global: true,
    danger: true,
  }

  const cancelJobAction = {
    label: t('actions.cancel'),
    Icon: CancelIcon,
    callback: handleCancelJob,
    enabled: canCancelJob,
    global: false,
    danger: true,
  }

  const refreshQuotesAction = {
    label: t('actions.refreshQuotes'),
    Icon: RefreshIcon,
    callback: handleRefreshQuotes,
    enabled: canRefreshQuotes,
    global: true,
    addSeparator: true,
  }

  const sendQuoteEmailAction = {
    label: t('actions.sendQuoteEmail'),
    Icon: EmailIcon,
    callback: handleSendQuoteEmail,
    enabled: canSendQuoteEmail,
    global: true,
  }

  const cancelSelectedQuoteAction = {
    label: t('actions.cancelEstimate'),
    Icon: CancelIcon,
    callback: handleCancelSelectedQuote,
    enabled: canCancelSelectedQuote,
    global: true,
    danger: true,
  }

  const collectTotalBalanceAction = {
    label: t('actions.collectTotalBalance'),
    Icon: PayBalanceIcon,
    callback: handleCollectTotalBalance,
    enabled: canCollectTotalBalance,
    global: true,
    addSeparator: true,
    hidden: !hasPaymentMethod,
  }

  const askForPaymentAction = {
    label: t('actions.sendPaymentRequest'),
    Icon: PayBalanceIcon,
    callback: handleSendPaymentRequestEmail,
    enabled: canAskForPayment,
    global: true,
    addSeparator: true,
  }

  const removePromoCodeAction = {
    label: t('actions.removePromoCode'),
    Icon: PromoCodeIcon,
    callback: handleRemovePromoCode,
    enabled: canRemovePromoCode,
    global: true,
    danger: true,
  }

  const sendToSmartMovingAction = {
    label: t('actions.sendToSmartMoving'),
    Icon: SmartMovingLogo,
    callback: handleSendToSmartMoving,
    enabled: canSendToSmartMoving,
    global: true,
    addSeparator: true,
  }

  const applyPromoCodeAction = {
    label: null,
    Icon: PromoCodeIcon,
    callback: handleApplyPromoCode,
    enabled: canApplyPromoCode,
    danger: false,
  }

  const selectAndCustomizeQuoteAction = {
    label: t('actions.selectAndCustomize'),
    callback: handleSelectAndCustomizeQuote,
    enabled: canSelectAndCustomizeQuote,
  }

  /* returns actions and the props needed to render the confirmation modal */

  const actions: Record<ActionName, Action> = {
    [ActionName.AssignAgentToMove]: assignAgentToMoveAction,
    [ActionName.UnassignAgentToMove]: unassignAgentToMoveAction,

    [ActionName.ApproveMove]: approveMoveAction,
    [ActionName.CancelJobRequest]: cancelJobRequestAction,
    [ActionName.CancelJob]: cancelJobAction,

    [ActionName.RefreshQuotes]: refreshQuotesAction,
    [ActionName.SendQuoteEmail]: sendQuoteEmailAction,
    [ActionName.CancelSelectedQuote]: cancelSelectedQuoteAction,

    [ActionName.CollecteTotalBalance]: collectTotalBalanceAction,
    [ActionName.AskForPayment]: askForPaymentAction,
    [ActionName.RemovePromoCode]: removePromoCodeAction,

    [ActionName.SendToSmartMoving]: sendToSmartMovingAction,

    /* not global */
    [ActionName.ApplyPromoCode]: applyPromoCodeAction,
    [ActionName.SelectAndCustomizeQuoteAction]: selectAndCustomizeQuoteAction,
  }

  return {
    actions,
    modalProps,
  }
}
