import { Fragment, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import {
  useJobRequests,
  useIncomingRequest,
  JobRequestStatus,
  type LightJobRequest,
} from '../../../modules/jobRequests'
import PageTitle from '../../../components/layout/PageTitle'
import PageContent from '../../../components/layout/PageContent'
import {
  DataGrid,
  type GridQueryVariables,
} from '../../../components/dataGrid'
import ServerError from '../../../components/errors/ServerError'
import { JobRequestItem } from '../../../components/jobRequest'
import Paper from '../../../components/Paper'

const JobRequestsPageContent: React.FC = () => {
  const { t } = useTranslation()

  const latestQuery = useRef<GridQueryVariables | null>(null)
  const {
    fetchJobRequests,
    data: jobRequests,
    error: jobRequestsErrors,
  } = useJobRequests()

  /* make sure we refresh the datagrid if a new job request is received */
  useIncomingRequest(async () => {
    await fetchJobRequests(latestQuery.current ?? {})
  })

  const totalResults = jobRequests?.count ?? 0
  const hasQuery = !!latestQuery.current?.search

  const noFilterResult = totalResults === 0 && hasQuery
  const noResult = totalResults === 0 && !noFilterResult

  const onQueryUpdate = useCallback(async (query: GridQueryVariables) => {
    latestQuery.current = query
    return await fetchJobRequests(query)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (jobRequestsErrors) {
    return <ServerError error={jobRequestsErrors} />
  }

  const renderTitle = (item: LightJobRequest, index: number) => {
    if (!jobRequests) {
      return null
    }
    const isFirst = index === 0
    const isPending = item.status === JobRequestStatus.Pending
    const previousItems = isFirst ? undefined : jobRequests.data[index - 1]

    const previousPending = previousItems ? previousItems.status === JobRequestStatus.Pending : false
    const currentPending = item.status === JobRequestStatus.Pending
    const differentStatus = previousItems ? previousPending !== currentPending : false

    if (isFirst || differentStatus) {
      return (
        <div className="m-2">
          <h2 className="font-body2 text-lg font-semibold">
            { isPending
              ? t('pages.companyBranch.jobRequests.pendingRequests')
              : t('pages.companyBranch.jobRequests.archivedRequests') }
          </h2>
        </div>

      )
    }
    return null
  }

  return (
    <>
      <PageTitle>
        { t('pages.companyBranch.jobRequests.title') }
      </PageTitle>
      <PageContent>
        <div className="pt-2 lg:pt-0">
          <DataGrid
            name="companyBranchJobRequests"
            count={jobRequests?.count ?? 0}
            onQueryUpdate={onQueryUpdate}
          >
            { jobRequests?.data?.map((jobRequest, index) => (
              <Fragment key={jobRequest.id}>
                { renderTitle(jobRequest, index) }
                <JobRequestItem jobRequest={jobRequest} />
              </Fragment>
            )) }

            { noResult && (
              <Paper className="mt-6">
                <em>
                  { t('pages.companyBranch.jobRequests.noRequests') }
                </em>
              </Paper>
            ) }

            { noFilterResult && (
              <Paper className="mt-6">
                <em>
                  { t('pages.companyBranch.jobRequests.searchNoResults') }
                </em>
              </Paper>
            ) }

          </DataGrid>
        </div>
      </PageContent>
    </>
  )
}

export default JobRequestsPageContent
