import { type ChargeDescription } from '../../../../../modules/invoices'
import { chargesDefinition } from '../chargesDefinition.const'
import { type ChargeDefinition, ChargeEditability } from '../CloseJobForm.types'

export const useCollectionCharges = () => {
  return Object.entries(chargesDefinition)
    .filter(([, definition]) => !!definition.collection)
    .map(([description]) => description as ChargeDescription)
}

export const useGetDefinition = () => {
  return (description: ChargeDescription): ChargeDefinition => {
    const definition = chargesDefinition[description]
    return {
      editable: definition?.editable ?? ChargeEditability.NotEditable,
      unitPriceEditable: !!definition?.unitPriceEditable,
      collection: !!definition?.collection,
    }
  }
}
