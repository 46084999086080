import { gql } from '@apollo/client'

import { moveFieldsForPartner } from '../../moves'
import { quotesFieldsForPartner } from '../../quotes/core/quotes.queries'
import { invoiceCharge, invoiceEstimateFields } from '../../invoices/core/invoices.queries'

const lightJobFields = `
id
createdAt
status
date
time
customerName
originCity
originRegion
destinationCity
destinationRegion
nbMen
nbTrucks {
  key
  value
}
moveDurationSeconds
residenceType
volumeCubicFeet
subtotal {
  price
  currency
}
`

export const jobFieldsForPartner = `
id
createdAt
status
date
time
move {
  ${moveFieldsForPartner}
}
quote {
  ${quotesFieldsForPartner}
}
`

export const getJobs = gql`
query GetJobs($companyBranchId: String!, $status: [JobStatus!], $dateRangeFilter: DateRangeFilter) {
  getJobs(companyBranchId: $companyBranchId, status: $status, dateRangeFilter: $dateRangeFilter) {
    ${lightJobFields}
  }
}
`

export const getJob = gql`
query GetJob($jobId: String!) {
  getJob(jobId: $jobId) {
    ${jobFieldsForPartner}
  }
}
`

export const previewCustomerInvoiceForPartner = gql`
query PreviewCustomerInvoiceForPartner($jobId: String!, $payload: CloseJobForPartnerDto!) {
  previewCustomerInvoiceForPartner(jobId: $jobId, payload: $payload) {
    ${invoiceEstimateFields}
    details {
      ${invoiceCharge}
    }
  }
}
`
