import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'

import {
  type CompanyResponsePayload,
  type CompanyRatingForPartner,
} from '../../../modules/companyRatings'
import { type FullCompanyBranch } from '../../../modules/adminCompanies'
import RatingResponseForm from '../../../components/forms/companyBranch/RatingResponseForm'
import OwnerResponse from '../../../components/CompanyBranchRatings/Rating/OwnerResponse'
import ConfirmationModal from '../../../components/ConfirmationModal'

type RatingCtaProps = {
  rating: CompanyRatingForPartner
  companyBranch: FullCompanyBranch
  isEditing: boolean
  onEdit: (editing: boolean) => void
  onSubmit: (payload: CompanyResponsePayload) => void | Promise<void>
  disabled?: boolean
}

const RatingCta: React.FC<RatingCtaProps> = ({
  rating,
  companyBranch,
  isEditing,
  onEdit,
  onSubmit,
  disabled,
}) => {
  const { t } = useTranslation()
  const hasReview = !!rating.review
  const hasResponse = !!rating.ownerResponse

  const handleDelete = async () => {
    await onSubmit({
      companyRatingId: rating.id,
      response: null,
    })
  }

  if (!hasReview) {
    return null
  }

  if (isEditing && !hasResponse) {
    return (
      <OwnerResponse
        rating={rating}
        companyBranch={companyBranch}
        hideContent
      >
        <div className="pt-4">
          <RatingResponseForm
            rating={rating}
            onSubmit={onSubmit}
            onCancel={() => { onEdit(false) }}
          />
        </div>
      </OwnerResponse>
    )
  }

  if (isEditing && hasResponse) {
    return (
      <div className="pt-4">
        <RatingResponseForm
          rating={rating}
          onSubmit={onSubmit}
          onCancel={() => { onEdit(false) }}
        />
      </div>
    )
  }

  if (hasResponse) {
    return (
      <div className="flex gap-x-2 pt-4">
        <div>
          <Button
            variant="contained"
            color="secondary"
            className="!normal-case"
            onClick={() => { onEdit(true) }}
            disabled={disabled}
          >
            { t('actions.edit') }
          </Button>
        </div>

        <div>
          <ConfirmationModal
            question={t('pages.companyBranch.ratings.deleteResponseConfirmation')}
            onConfirm={handleDelete}
            disabled={disabled}
          >
            <Button
              variant="contained"
              color="error"
              className="!normal-case"
              disabled={disabled}
            >
              { t('actions.delete') }
            </Button>
          </ConfirmationModal>
        </div>
      </div>
    )
  }

  return (
    <div className="pt-4">
      <Button
        variant="contained"
        color="secondary"
        className="!normal-case"
        onClick={() => { onEdit(true) }}
        disabled={disabled}
      >
        { t('actions.reply') }
      </Button>
    </div>
  )
}

export default RatingCta
