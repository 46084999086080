import { useTranslation } from 'react-i18next'
import MessageIcon from '@mui/icons-material/ChatOutlined'

import { type JobRequestForPartner } from '../../../modules/jobRequests'
import Paper from '../../../components/Paper'
import MoveNote,
{
  AuthorSource,
  MoveNotes,
} from '../../../components/MoveNote'

type NotesProps = {
  jobRequest: JobRequestForPartner
}

const Notes: React.FC<NotesProps> = ({
  jobRequest,
}) => {
  const { t } = useTranslation()
  const hasAgentNote = !!jobRequest.move.notes?.toPartner?.note
  const hasCustomerNote = !!jobRequest.move.notes?.fromCustomer
  const hasNotes = hasAgentNote || hasCustomerNote

  if (!hasNotes) {
    return null
  }

  return (
    <Paper>
      <h2 className="mb-4 font-body2 text-2xl font-semibold text-slate-700">
        <MessageIcon />
        { ' ' }
        { t('pages.companyBranch.jobRequest.sections.notes') }
      </h2>

      <MoveNotes>
        <MoveNote
          note={jobRequest.move.notes?.toPartner}
          author={{
            source: AuthorSource.MovingWaldo,
          }}
        />
        <MoveNote
          note={jobRequest.move.notes?.fromCustomer}
          author={{
            source: AuthorSource.Customer,
            user: jobRequest.move.user,
          }}
        />
      </MoveNotes>
    </Paper>
  )
}

export default Notes
