import { useMutation } from '@apollo/client'

import * as mutations from './promoCodes.mutations'
import { type PromoCodePayload } from './promoCodes.types'

/**
 * create a new promo code
 */
export const useCreatePromoCodeAction = () => {
  const [createPromoCode, { data, loading, ...mutationProps }] = useMutation(mutations.createPromoCode)

  return {
    createPromoCode: async (payload: PromoCodePayload) => {
      const { data } = await createPromoCode({
        variables: {
          payload,
        },
      })

      return data.admin_createPromoCode
    },
    data: data?.admin_createPromoCode,
    loading,
    ...mutationProps,
  }
}

/**
 * update a promo code
 */
export const useUpdatePromoCodeAction = () => {
  const [updatePromoCode, { data, loading, ...mutationProps }] = useMutation(mutations.updatePromoCode)

  return {
    updatePromoCode: async (id: string, payload: Partial<PromoCodePayload>) => {
      const { data } = await updatePromoCode({
        variables: {
          id,
          payload,
        },
      })

      return data.admin_updatePromoCode
    },
    data: data?.admin_updatePromoCode,
    loading,
    ...mutationProps,
  }
}

/**
 * delete multiple promo codes
 */
export const useDeletePromoCodesAction = () => {
  const [deletePromoCodes, { data, loading, ...mutationProps }] = useMutation(mutations.deletePromoCodes)

  return {
    deletePromoCodes: async (ids: string[]) => {
      const { data } = await deletePromoCodes({
        variables: {
          ids,
        },
      })

      return data.admin_deletePromoCodes
    },
    data: data?.admin_deletePromoCodes,
    loading,
    ...mutationProps,
  }
}

/**
 * restore multiple promo codes
 */
export const useRestorePromoCodesAction = () => {
  const [restorePromoCodes, { data, loading, ...mutationProps }] = useMutation(mutations.restorePromoCodes)

  return {
    restorePromoCodes: async (ids: string[]) => {
      const { data } = await restorePromoCodes({
        variables: {
          ids,
        },
      })

      return data.admin_restorePromoCodes
    },
    data: data?.admin_restorePromoCodes,
    loading,
    ...mutationProps,
  }
}
