import { type FullCompanyBranch } from '../../../../modules/adminCompanies'
import Paper from '../../../../components/Paper'
import CompanyBranchRatings from '../../../../components/CompanyBranchRatings'

type RatingsTabProps = {
  companyBranch: FullCompanyBranch
}

const RatingsTab: React.FC<RatingsTabProps> = ({ companyBranch }) => {
  return (
    <div>
      <Paper compact>
        { companyBranch.approved && companyBranch.active && (
          <div className="mt-4">
            <CompanyBranchRatings
              companyBranch={companyBranch}
              small
            />
          </div>
        ) }
      </Paper>
    </div>
  )
}

export default RatingsTab
