import {
  type DataOptions,
  useData,
  usePaginatedData,
} from '../../graphQl'
import { type AdminUser } from './adminUsers.models'
import { type UsersPaginatedQuery } from './adminUsers.types'
import * as queries from './adminUsers.queries'

/**
 * returns all users
 * query optimized for data grid
 */
export const useUsers = () => {
  const { fetch: fetchUsers, ...data } = usePaginatedData<AdminUser, UsersPaginatedQuery>(
    queries.getUsers,
  )
  return {
    fetchUsers,
    ...data,
  }
}

/**
 * returns user detail
 */
export const useUser = (userId: string, options?: DataOptions) => {
  return useData<AdminUser>(queries.getUser, {
    ...options,
    variables: { userId },
  })
}
