import { startOfYear } from 'date-fns/startOfYear'
import { startOfMonth } from 'date-fns/startOfMonth'
import { startOfDay } from 'date-fns/startOfDay'
import { endOfMonth } from 'date-fns/endOfMonth'
import { endOfDay } from 'date-fns/endOfDay'
import { subMonths } from 'date-fns/subMonths'

import { type ReportIntervalValue, type ReportInterval } from './adminAccounting.types'

const reportIntervalLocalStorageKey = 'accounting:reportIntervalValue'

/**
 * get YTD interval
 */
export const getYearToDateInterval = (): ReportInterval => {
  const now = new Date()
  return {
    start: startOfYear(now),
    end: endOfDay(now),
  }
}

/**
 * get MTD interval
 */
export const getMonthToDateInterval = (): ReportInterval => {
  const now = new Date()
  return {
    start: startOfMonth(now),
    end: endOfDay(now),
  }
}

/**
 * get previous month interval
 */
export const getPreviousMonthInterval = (): ReportInterval => {
  const previousMonth = subMonths(new Date(), 1)
  return {
    start: startOfMonth(previousMonth),
    end: endOfMonth(previousMonth),
  }
}

export const getCustomInterval = (interval: ReportInterval) => {
  return {
    start: startOfDay(interval.start),
    end: endOfDay(interval.end),
  }
}

/**
 * get filter value from localstorage
 */
export const getSavedReportIntervalValue = (): ReportIntervalValue | undefined => {
  try {
    const savedValue = localStorage?.getItem(reportIntervalLocalStorageKey)
    return savedValue ? JSON.parse(savedValue) : undefined
  } catch (error) {
    console.error(error)
  }
}

/**
 * set filter value in localstorage
 */
export const setSavedReportIntervalValue = (value?: ReportIntervalValue) => {
  localStorage?.setItem(reportIntervalLocalStorageKey, JSON.stringify(value))
}
