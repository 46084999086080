import { useMemo } from 'react'

import { mergeClassName } from '../../../utils/mergeClassName'

type RatingProps = {
  rating?: number
}

const Rating: React.FC<RatingProps> = ({
  rating,
}) => {
  const ratingClassName = useMemo(() => {
    switch (rating) {
      case 5:
        return 'bg-green-800/10 text-green-800'

      case 4:
        return 'bg-lime-300/10 text-lime-600'

      case 3:
        return 'bg-yellow-300/10 text-yellow-500'

      case 2:
        return 'bg-orange-600/10 text-orange-600'

      case 1:
        return 'bg-red-800/10 text-red-800'
    }
  }, [rating])

  if (!rating) {
    return null
  }

  return (
    <div className={mergeClassName(
      'inline-flex items-center rounded-full border p-1 px-3 shadow font-bold',
      ratingClassName,
    )}
    >
      { rating }
    </div>
  )
}

export default Rating
