import { useEffect, useState } from 'react'
import { styled, lighten } from '@mui/material/styles'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'

import { useDebounce } from '../../../../utils/useDebounce'
import { useDataGridContext } from '../../DataGridContext'
import { useTranslation } from 'react-i18next'

const SearchInput = styled(OutlinedInput)(({ theme }) => ({
  flexGrow: 1,
  height: 40,
  borderRadius: 50,
  boxShadow: `inset 0 0 5px ${theme.palette.divider}`,
  '& fieldset': {
    border: `solid 1px ${lighten(theme.palette.divider, 0)}`,
  },
  '&.Mui-focused fieldset': {
    border: 'solid 1px #999 !important',
  },
  '& svg.searchIcon': {
    color: `${lighten(theme.palette.divider, 0.2)}`,
  },
}))

const SearchBar: React.FC = () => {
  const { t } = useTranslation()
  const [value, setValue] = useState<string>()
  const searchValue = useDebounce(value, 500)
  const { query, setQuery, disabled } = useDataGridContext()

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => { setValue(event.target.value) }

  const clearSearch = () => { setValue('') }

  useEffect(() => {
    if (typeof searchValue === 'undefined') {
      return
    }
    setQuery({
      ...query,
      search: searchValue,
      page: 1,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  useEffect(() => {
    if (query.search !== value) {
      setValue(query.search)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.search])

  return (

    <SearchInput
      size="small"
      placeholder={t('components.dataGrid.header.search')}
      margin="dense"
      color="secondary"
      value={value}
      onChange={onChange}
      startAdornment={(
        <InputAdornment position="start">
          <SearchIcon className="searchIcon" />
        </InputAdornment>
      )}
      endAdornment={(
        <InputAdornment position="end">
          { value && (
            <IconButton
              onClick={clearSearch}
              edge="end"
            >
              <CloseIcon />
            </IconButton>
          ) }
        </InputAdornment>
      )}
      disabled={disabled}
    />
  )
}

export default SearchBar
