import { v4 as uuid } from 'uuid'
import { set } from 'date-fns/set'
import { fromZonedTime } from 'date-fns-tz'

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { type QuotesRequestFormValues } from './QuotesRequestForm.type'
import {
  type ResidenceAndRoomsType,
  ResidenceType,
  residenceTypeFromRooms,
  estimateWeight,
} from '../../../../modules/quotes'
import {
  type QuotesRequestPayload,
  type QuotesRequestPlacePayload,
} from '../../../../modules/adminQuotes'
import {
  type DetailedQuoteRequest,
  type MoveDetailsPayload,
} from '../../../../modules/adminMoves'
import { keyValueMapToRecord, recordToKeyValueMap } from '../../../../common/keyValueMap'
import { type Address } from '../../../../common/address/address.model'

const placeToFormData = (place: QuotesRequestPlacePayload<number>) => ({
  ...place,
  nbStairs: place.nbStairs
    ? String(place.nbStairs)
    : undefined,
})

export const quotesRequestToFormData = (
  quotesRequest: DetailedQuoteRequest,
): QuotesRequestFormValues => {
  return {
    ...quotesRequest,
    movingDate: new Date(quotesRequest.movingDate),
    overnightFees: quotesRequest.overnightFees ? String(quotesRequest.overnightFees) : undefined,
    nbTrucks: keyValueMapToRecord(
      quotesRequest.nbTrucks,
    ),
    nbMen: String(quotesRequest.nbMen),
    estimatedMovingLabourTime: String(quotesRequest.estimatedMovingLabourTime),
    estimatedPackingLabourTime: (quotesRequest.estimatedPackingLabourTime ?? 0) > 0
      ? String(quotesRequest.estimatedPackingLabourTime)
      : '0',
    origin: placeToFormData(quotesRequest.origin),
    destination: placeToFormData(quotesRequest.destination),
    stops: quotesRequest.stops
      ? quotesRequest.stops.map(stop => ({
        ...stop,
        ...placeToFormData(stop),
        id: uuid(),
        stopDuration: String(stop.stopDuration),
      }))
      : undefined,
    specialServices: keyValueMapToRecord(
      quotesRequest.specialServices,
    ),
    specialItems: keyValueMapToRecord(
      quotesRequest.specialItems,
    ),
    protectiveMaterial: keyValueMapToRecord(
      quotesRequest.protectiveMaterial,
    ),

    /* details */
    residenceRooms: keyValueMapToRecord(
      quotesRequest.residenceRooms,
    ),
    nbBoxes: String(quotesRequest.nbBoxes ?? 0),
    furnitureRatio: String(quotesRequest.furnitureRatio ?? 0),
    volumeCubicFeet: String(quotesRequest.volumeCubicFeet),
  }
}

const filterEmptyValues = <T extends Record<any, string>>(value: T) => {
  return Object.fromEntries(Object.entries(value).filter(([_, value]) => !!value && value !== '0')) as T
}

const formatPlaceOutput = (place: QuotesRequestPlacePayload<string>) => ({
  address: {
    ...place.address,
    apartment: (place.address as Address).apartment !== ''
      ? (place.address as Address).apartment
      : undefined,
  },
  nbStairs: place.nbStairs
    ? parseInt(place.nbStairs)
    : undefined,
  elevatorReservationRequired: !!place.elevatorReservationRequired,
})

export const formatFormOutput = (data: QuotesRequestFormValues): [QuotesRequestPayload, MoveDetailsPayload] => {
  const {
    /* fields only used in form */
    nbTrucksTotal,
    residenceRoomsTotal,
    specialServicesTotal,
    specialItemsTotal,
    protectiveMaterialTotal,

    /* details fields */
    residenceType,
    residenceRooms,
    nbBoxes,
    furnitureRatio,
    volumeCubicFeet,

    /* form request fields */
    ...formData
  } = data

  /**
   * moving date is expected to be UTC midnight
   */
  const movingDate = fromZonedTime(set(formData.movingDate, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  }), 'UTC')

  const quoteRequest = {
    ...formData,
    movingDate,
    overnightFees: formData.overnightFees ? parseInt(formData.overnightFees) : undefined,
    nbMen: parseInt(formData.nbMen),
    estimatedMovingLabourTime: parseFloat(formData.estimatedMovingLabourTime),
    estimatedPackingLabourTime: formData.estimatedPackingLabourTime
      ? parseFloat(formData.estimatedPackingLabourTime)
      : undefined,
    nbTrucks: recordToKeyValueMap(
      filterEmptyValues(formData.nbTrucks),
      parseInt,
    )!,
    origin: formatPlaceOutput(formData.origin),
    destination: formatPlaceOutput(formData.destination),
    stops: formData.stops
      ? formData.stops.map(stop => ({
        ...stop,
        ...formatPlaceOutput(stop),
        id: undefined,
        stopDuration: parseFloat(stop.stopDuration ?? '0'),
      }))
      : undefined,
    specialServices: formData.specialServices
      ? recordToKeyValueMap(
        filterEmptyValues(formData.specialServices),
        parseInt,
      )
      : undefined,
    specialItems: formData.specialItems
      ? recordToKeyValueMap(
        filterEmptyValues(formData.specialItems),
        parseInt,
      )
      : undefined,
    protectiveMaterial: formData.protectiveMaterial
      ? recordToKeyValueMap(
        filterEmptyValues(formData.protectiveMaterial),
        parseInt,
      )
      : undefined,
    weightPounds: undefined,
  }

  const volume = volumeCubicFeet ? parseInt(volumeCubicFeet) : 0

  const formattedResidenceType = residenceType in ResidenceType
    ? residenceType as ResidenceType
    : residenceTypeFromRooms[residenceType as ResidenceAndRoomsType]

  const details = {
    residenceType: formattedResidenceType,
    residenceRooms: residenceRooms
      ? recordToKeyValueMap(
        filterEmptyValues(residenceRooms),
        parseInt,
      )
      : undefined,
    nbBoxes: nbBoxes ? parseInt(nbBoxes) || undefined : undefined,
    furnitureRatio: furnitureRatio ? parseInt(furnitureRatio) || undefined : undefined,
    volumeCubicFeet: volume,
    weightPounds: estimateWeight(volume),
  }

  return [
    quoteRequest,
    details,
  ]
}
