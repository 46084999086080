import { useState, useEffect } from 'react'

import { DEFAULT_QUERY } from './DataGrid.const'
import {
  type ExportFormatter,
  type Action,
  type CustomFiltersProps,
  type GridQuery,
  type ExportCallback,
  type GridQueryVariables,
} from './DataGrid.type'
import { DataGridProvider } from './DataGridContext'
import useRowSelection from './useRowSelection'
import Grid from './Grid'
import Table from './Table'
import {
  getSavedQuery,
  saveQuery,
  saveVisibleColumns,
  getSavedVisibleColumns,
} from './DataGrid.util'
import { useToggleColumns } from './useToggleColumns'

type DataGridProps = {
  children?: React.ReactNode
  customFilters?: React.FC<CustomFiltersProps>
  saveCustomFilterValues?: string[]
  name?: string
  columns?: React.ReactNode
  enableToggleColumns?: boolean
  count?: number
  actions?: Action[]
  onQueryUpdate?: (query: GridQueryVariables) => any
  initialQuery?: Partial<GridQuery>
  showToggleDeleted?: boolean
  disableMinHeight?: boolean
  exportCallback?: ExportCallback
  exportFormatter?: ExportFormatter
  exportEnabled?: boolean
  searchEnabled?: boolean
  disabled?: boolean
}

const DataGrid: React.FC<DataGridProps> = ({
  children,
  customFilters,
  name,
  columns,
  count,
  actions = [],
  saveCustomFilterValues = [],
  onQueryUpdate,
  initialQuery = {},
  showToggleDeleted = false,
  disableMinHeight = false,
  exportEnabled = false,
  searchEnabled = true,
  exportCallback,
  exportFormatter,
  disabled = false,
}) => {
  const isTable = !!columns
  const isStatic = !onQueryUpdate
  const userSavedQuery = getSavedQuery(name)
  const userSavedVisibleColumns = getSavedVisibleColumns(name)

  const [query, setQuery] = useState<GridQuery>({ ...DEFAULT_QUERY, ...initialQuery, ...userSavedQuery })
  const [loading, setLoading] = useState(false)
  const selection = useRowSelection()
  const toggleColumns = useToggleColumns(userSavedVisibleColumns)

  useEffect(() => {
    if (!onQueryUpdate) {
      return
    }
    setLoading(true)

    const { custom, ...queryVariables } = query
    const formattedQueryVariables = {
      query: queryVariables,
      ...custom,
    }

    onQueryUpdate(formattedQueryVariables).finally(() => { setLoading(false) })
    saveQuery(query, name, saveCustomFilterValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onQueryUpdate, query, name])

  useEffect(() => {
    if (!toggleColumns.visibleColumns) {
      return
    }
    saveVisibleColumns(toggleColumns.visibleColumns, name)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleColumns.visibleColumns, name])

  return (
    <DataGridProvider {...{
      count,
      loading,
      actions,
      showToggleDeleted,
      query,
      setQuery,
      exportEnabled: exportEnabled && !!exportCallback && !!exportFormatter,
      exportCallback,
      exportFormatter,
      disabled,
      ...selection,
      ...toggleColumns,
    }}
    >
      <Grid
        customFilters={customFilters}
        isTable={isTable}
        isStatic={isStatic}
        disableMinHeight={disableMinHeight}
        searchEnabled={searchEnabled}
      >
        { isTable
          ? (
            <Table columns={columns}>
              { children }
            </Table>
            )
          : children }

      </Grid>
    </DataGridProvider>
  )
}

export default DataGrid
