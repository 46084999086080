import { type DataOptions, useData } from '../../graphQl'
import { type FullCompanyBranch } from '../../adminCompanies'
import { useAuthenticationState, useCurrentUserAccountType } from '../../users'
import { useParams } from '../../navigation'
import { type Company } from './companies.models'
import * as queries from './companies.queries'
import { companyAdapter } from './companies.adapter'
import { useOnBehalfState } from './hooks'

/**
 * returns current partner company
 */
export const useCurrentCompany = (dataOptions?: DataOptions) => {
  const { isAuthenticated } = useAuthenticationState()
  const { isPartner, isAdmin } = useCurrentUserAccountType()
  const { onBehalfCompanyId } = useOnBehalfState()
  const invalidUser = !isPartner && !isAdmin

  const skip = !!dataOptions?.skip || !isAuthenticated || invalidUser || (isAdmin && !onBehalfCompanyId)

  return useData<Company>(queries.getCurrentCompany, {
    ...dataOptions,
    skip,
    variables: { onBehalfCompanyId },
    adapter: companyAdapter,
  })
}

/**
 * returns currently selected company branch
 * note : at the moment, there is only 1 branch per company so we return the first one
 */
export const useCurrentCompanyBranch = (dataOptions?: DataOptions) => {
  const { data: company, ...companyResult } = useCurrentCompany(dataOptions)
  const { branchId } = useParams()

  if (branchId && !companyResult.loading) {
    const currentBranch = company?.branches?.find(({ id }) => id === branchId)
    if (currentBranch) {
      return {
        loading: companyResult.loading,
        called: companyResult.called,
        error: companyResult.error,
        data: company?.branches?.find(({ id }) => id === branchId),
      }
    }
    return {
      loading: companyResult.loading,
      called: companyResult.called,
      data: undefined,
      error: new Error('Invalid company branch id'),
    }
  }

  return {
    data: company?.branches?.[0],
    loading: companyResult.loading,
    called: companyResult.called,
    error: companyResult.error,
  }
}

/**
 * returns current full company branch (company branch + parent company)
 */
export const useCurrentFullCompanyBranch = (): FullCompanyBranch | undefined => {
  const { data: company } = useCurrentCompany()
  const { data: companyBranch } = useCurrentCompanyBranch()
  if (!company || !companyBranch) {
    return
  }

  return {
    ...companyBranch,
    company,
  }
}
