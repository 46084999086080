import { gql } from '@apollo/client'

import { jobFieldsForPartner } from './jobs.queries'

export const closeJobForPartner = gql`
mutation CloseJobForPartner($jobId: String!, $payload: CloseJobForPartnerDto!) {
  closeJobForPartner(jobId: $jobId, payload: $payload) {
    ${jobFieldsForPartner}
  }
}
`

export const updateJobNote = gql`
mutation UpdateJobNote($jobId: String!, $payload: UpdateJobNoteDto!) {
  updateJobNote(jobId: $jobId, payload: $payload) {
    ${jobFieldsForPartner}
  }
}
`

export const uploadJobNoteAttachment = gql`
mutation UploadJobNoteAttachment($jobId: String!, $payload: UploadAttachmentDto!) {
  uploadJobNoteAttachment(jobId: $jobId, payload: $payload) {
    ${jobFieldsForPartner}
  }
}
`

export const deleteJobNoteAttachment = gql`
mutation DeleteJobNoteAttachment($jobId: String!, $payload: DeleteAttachmentDto!) {
  deleteJobNoteAttachment(jobId: $jobId, payload: $payload) {
    ${jobFieldsForPartner}
  }
}
`
