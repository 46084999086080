import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import InputAdornment from '@mui/material/InputAdornment'

import { type JobForPartner } from '../../../../../../modules/jobs'
import { Input } from '../../../../../form'
import InfoTooltip from '../../../../../InfoTooltip'
import Price from '../../../../../Price'
import { useChargeState } from '../../hooks'
import Row from '../Row'

type ChargeRowProps = {
  index: number
  job: JobForPartner
  onDelete?: () => void
  autoFocus?: boolean
}

const ChargeRow: React.FC<ChargeRowProps> = ({
  index,
  job,
  onDelete,
  autoFocus = false,
}) => {
  const { t } = useTranslation()
  const namePrefix = 'charges'
  const name = `${namePrefix}.${index}`
  const values = useWatch({ name })

  const {
    quantityEditable,
    chargeTitle,
    helpText,
    unitPrice,
    subtotal,
    unitAdornment,
    minTime,
    doubleDrive,
  } = useChargeState(values, job)

  const deletable = quantityEditable && !!onDelete

  return (
    <Row
      title={chargeTitle}
      subtitle={(
        <>
          { doubleDrive && (
            <strong>
              { t('forms.companyBranch.closeJob.california') } :
              { ' ' }
            </strong>
          ) }
          { helpText }
          { minTime && (
            <>
              { ' ' }
              <span className="font-bold">
                ({ t('forms.companyBranch.closeJob.minTime', { count: minTime }) })
              </span>
              { ' ' }
              <InfoTooltip tooltip={t('forms.companyBranch.closeJob.minTimeInfo')} />
            </>
          ) }
        </>
      )}
      header={(
        <>
          { quantityEditable && (
            <div>
              <Input
                name={`${name}.quantity`}
                size="small"
                className="bg-white md:max-w-[170px]"
                InputProps={{
                  endAdornment: unitAdornment
                    ? (
                      <InputAdornment position="end">
                        { unitAdornment }
                      </InputAdornment>
                      )
                    : undefined,
                  className: 'bg-white',
                }}
                inputProps={{
                  maxLength: 5,
                }}
                autoFocus={autoFocus}
              />
            </div>
          ) }

          <div className="mt-4 flex flex-row flex-wrap items-center gap-x-4 lg:mt-0 lg:flex-col lg:items-end">
            { unitPrice && (
              <div className="grow text-center text-xs text-neutral-500 lg:mb-1 lg:text-left">
                { !quantityEditable && values.quantity && (
                  <>
                    { values.quantity }
                    { ' ' }
                  </>
                ) }
                @ <Price amount={unitPrice} compact />
              </div>
            ) }
            <div className="grow text-center text-xl font-bold text-neutral-700 lg:w-[120px] lg:text-right">
              { subtotal ? <Price amount={subtotal} /> : '—' }
            </div>
          </div>
        </>
      )}
      onDelete={deletable ? onDelete : undefined}
    />
  )
}

export default ChargeRow
