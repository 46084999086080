import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import AddIcon from '@mui/icons-material/AddCircleOutline'

import { ChargeDescription } from '../../../../../../modules/invoices'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from '../../../../../DropDownMenu'

type AddItemDropdownProps = {
  description: ChargeDescription
  items: string[]
  onSelect: (item: string) => void
}

const AddItemDropdown: React.FC<AddItemDropdownProps> = ({
  description,
  items,
  onSelect,
}) => {
  const { t } = useTranslation()

  if (items.length === 0) {
    return null
  }

  const getCtaLabel = () => {
    switch (description) {
      case ChargeDescription.additionalChargesSpecialServices:
        return t('forms.companyBranch.closeJob.addCollection.addSpecialService')
      case ChargeDescription.additionalChargesSpecialItems:
        return t('forms.companyBranch.closeJob.addCollection.addSpecialItem')
      case ChargeDescription.protectiveMaterialProduct:
        return t('forms.companyBranch.closeJob.addCollection.addProtectiveMaterial')
    }
  }

  const getLabel = (item: string) => {
    switch (description) {
      case ChargeDescription.additionalChargesSpecialServices:
        return t(`specialServices.${item}.title`)
      case ChargeDescription.additionalChargesSpecialItems:
        return t(`specialItems.${item}.title`)
      case ChargeDescription.protectiveMaterialProduct:
        return t(`protectiveMaterial.${item}.title`)
    }
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="flex justify-center pt-2 lg:justify-normal">
          <Button
            className="w-full !normal-case sm:w-auto"
            variant="contained"
            color="secondary"
            startIcon={<AddIcon />}
            endIcon={<KeyboardArrowDownIcon />}
            disableFocusRipple
          >
            { getCtaLabel() }
          </Button>
        </div>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="start" sideOffset={8}>

        { items.map((item) => {
          return (
            <DropdownMenuItem
              key={item}
              className="my-2 cursor-pointer px-4"
              onClick={() => { onSelect(item) }}
            >
              { getLabel(item) }
            </DropdownMenuItem>
          )
        }) }

      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default AddItemDropdown
