import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import NoteIcon from '@mui/icons-material/DescriptionOutlined'
import PenIcon from '@mui/icons-material/CreateOutlined'

import { type MoveNote } from '../../modules/adminMoves'
import { mergeClassName } from '../../utils/mergeClassName'
import NoteForm from '../forms/moves/NoteForm'
import AuthorName from './AuthorName'
import Avatar from './Avatar'
import { useSanitizedNote } from './MoveNote.hooks'
import { type Author } from './MoveNote.types'
import MoveNoteAttachments from './MoveNoteAttachments'
import './style.css'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

type NoteProps = {
  note?: MoveNote
  author?: Author
  isAuthor?: boolean
  label?: React.ReactNode
  onUpload?: (file: File) => Promise<void> | void
  onEdit?: (note: string) => Promise<void> | void
  onDeleteFile?: (id: string) => Promise<void> | void
  headerOptions?: React.ReactNode
} & ReactDiv

const Note: React.FC<NoteProps> = ({
  note,
  author,
  isAuthor = false,
  label,
  onUpload,
  onDeleteFile,
  onEdit,
  headerOptions,
  className,
  ...props
}) => {
  const { t } = useTranslation()
  const [editing, setEditing] = useState(false)
  const sanitizedNote = useSanitizedNote(note?.note)
  const editable = onEdit

  const hasNote = !!note?.note || !!note?.attachments?.length

  if (!hasNote && !editable) {
    return null
  }

  /**
   * close editing mode when a note is successfully updated
   */
  const handleEditNote = async (note: string) => {
    await onEdit?.(note)
    setEditing(false)
  }

  return (
    <div
      className={mergeClassName(
        'flex gap-x-4',
        className,
      )}
      {...props}
    >
      { author && (
        <div className="hidden md:block">
          <Avatar author={author} />
        </div>
      ) }

      <div className={mergeClassName(
        'rounded-3xl border border-neutral-200 py-4 px-6 font-sans shadow',
        'w-full md:mr-4',
        author && 'rounded-tl-none',
        author && !isAuthor && 'bg-indigo-50/40',
        !author && 'w-full',
      )}
      >

        { editing && onEdit
          ? (
            <NoteForm
              note={note}
              onSubmit={handleEditNote}
              onUpload={onUpload}
              onDeleteFile={onDeleteFile}
              onCancel={() => { setEditing(false) }}
              autoFocus
            />
            )
          : (
            <>
              { author && hasNote && (
                <div className="mb-4 flex items-center justify-between">
                  <div className="flex items-center gap-x-2">
                    <NoteIcon />
                    <AuthorName author={author} />
                    { editable && (
                      <div>
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          startIcon={<PenIcon />}
                          className="!normal-case"
                          onClick={() => { setEditing(true) }}
                        >
                          { t('actions.edit') }
                        </Button>
                      </div>
                    ) }
                  </div>
                  { headerOptions && (
                    <div>
                      { headerOptions }
                    </div>
                  ) }
                </div>
              ) }

              { hasNote && sanitizedNote && (
                <div
                  className={mergeClassName(
                    'moveNote leading-6 text-neutral-700 my-1',
                    editable && 'hover:bg-gray-100 cursor-pointer p-2 -m-2 my-1 rounded-2xl',
                  )}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: sanitizedNote }}
                  onClick={editable ? () => { setEditing(true) } : undefined}
                />
              ) }

              { !hasNote && (
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<PenIcon />}
                  onClick={() => { setEditing(true) }}
                  className="!normal-case"
                  size="large"
                >
                  { t('actions.addNote') }
                </Button>
              ) }

              { !!note?.attachments?.length && (
                <MoveNoteAttachments
                  attachments={note.attachments}
                />
              ) }
            </>
            ) }

      </div>
    </div>
  )
}

export default Note
