import { type Amount } from '../../../common/amount'
import { type KeyValueMap } from '../../../common/keyValueMap'
import { type FullMove } from '../../adminMoves'
import { type ProtectiveMaterial, type SpecialItem, type SpecialService, type ArrivalWindow, type Truck } from '../../companies'
import { type FullMoveForPartner } from '../../moves'
import { type ResidenceType, type QuoteForPartner, type Quote } from '../../quotes'

export type JobsQuery = {
  status?: JobStatus[]
  dateRangeFilter?: DateRangeFilterValue
  companyBranchId?: string
}

export type DateRangeFilterValue = {
  from: Date
  to?: Date
}

export enum JobStatus {
  Pending = 'pending',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Closed = 'closed',
}

export type Job = {
  id: string
  status: JobStatus
  date: Date
  time: ArrivalWindow
  move: FullMove
  branchId: string
  quote: Quote
}

export type BasicJob = Omit<Job, 'move | quote'>

export type LightJob = {
  id: string
  createdAt: Date
  status: JobStatus
  date: Date
  time: ArrivalWindow
  customerName: string
  originCity: string
  originRegion: string
  destinationCity: string
  destinationRegion: string
  nbMen: number
  nbTrucks: KeyValueMap<Truck, number>
  moveDurationSeconds: number
  residenceType: ResidenceType
  volumeCubicFeet: number
  subtotal: Amount
}

export type JobForPartner = BasicJob & {
  move: FullMoveForPartner
  quote: QuoteForPartner
}

export type CloseJobForPartnerPayload = {
  movingLabourTime?: number
  packingLabourTime?: number
  transportLabourTime?: number

  travelTime?: number
  travelTruckDistance?: number
  transportTruckDistance?: number

  nbOvernights?: number
  nbStairs?: number

  specialServices?: KeyValueMap<SpecialService, { quantity: number }>
  specialItems?: KeyValueMap<SpecialItem, { quantity: number }>
  protectiveMaterial?: KeyValueMap<ProtectiveMaterial, { quantity: number, unitPrice: number }>
}

export type UpdateNotePayload = {
  note?: string | null
}

export type UploadNoteAttachmentPayload = {
  file: File
}

export type DeleteNoteAttachmentPayload = {
  fileId: string
}
