import {
  type DataOptions,
  useData,
  usePaginatedData,
} from '../../graphQl'
import * as queries from './adminMoves.queries'
import { type FullMove, type LightMove } from './adminMoves.models'
import {
  type MovesPaginatedQuery,
} from './adminMoves.types'

/**
 * returns all companies branches
 * query optimized for data table
 */
export const useMoves = () => {
  const { fetch: fetchMoves, ...data } = usePaginatedData<LightMove, MovesPaginatedQuery>(queries.getMoves)
  return {
    fetchMoves,
    ...data,
  }
}

/**
 * returns move detail
 */
export const useMove = (moveId?: string, options?: DataOptions) => {
  return useData<FullMove>(queries.getMove, {
    ...options,
    variables: { moveId },
    skip: !moveId,
  })
}
