import {
  type ProtectiveMaterial,
  type SpecialItem,
  type SpecialService,
} from '../../../../modules/companies'
import { type Charge, ChargeDescription } from '../../../../modules/invoices'
import { type CloseJobForPartnerPayload } from '../../../../modules/jobs'
import { type KeyValueMap, recordToKeyValueMap } from '../../../../common/keyValueMap'
import { roundPrice } from '../../../../utils/money'
import { type ChargeRow, type CloseJobFormValues } from './CloseJobForm.types'

const formatPriceAsString = (price: number) => {
  return String((Math.round(price * 100) / 100).toFixed(2))
}

export const chargesToFormData = (
  charges: Charge[] = [],
  collectionCharges: ChargeDescription[] = [],
): CloseJobFormValues => {
  const formData: ChargeRow[] = charges.map((charge, index) => ({
    chargeRefIndex: index,
    categoryAndDescription: `${charge.pricingCategory}.${charge.description}`,
    overrideDescription: charge.overrideDescription,
    quantity: String(charge.quantity),
    unit: charge.unit ?? 'units',
    unitPrice: formatPriceAsString(charge.unitPrice.price),
    item: String(charge.item),
  }))

  collectionCharges.forEach(collectionCharge => {
    const chargeIndex = charges.findIndex(charge => charge.description === collectionCharge)
    const charge = formData.find(({ chargeRefIndex }) => chargeRefIndex === chargeIndex)

    if (!charge) {
      return
    }
    formData.push({
      categoryAndDescription: charge.categoryAndDescription,
      quantity: '0',
      unitPrice: '0',
    })
  })

  return { charges: formData }
}

const parseQuantity = (input?: string) => {
  const quantity = Math.max(parseFloat(input ?? '0'), 0)
  return isNaN(quantity) ? 0 : quantity
}

const parsePrice = (input?: string) => {
  const price = Math.max(parseFloat(input ?? '0'), 0)
  return isNaN(price) ? 0 : roundPrice(price)
}

export const formatFormOutput = (values: CloseJobFormValues): CloseJobForPartnerPayload => {
  const chargesByDescription: Record<ChargeDescription, ChargeRow[]> = values.charges
    .reduce<any>((acc, curr) => {
    const description = curr.categoryAndDescription.split('.')[1] as ChargeDescription
    if (!acc[description]) {
      acc[description] = []
    }
    acc[description].push(curr)
    return acc
  }, {})

  const getQuantity = (description: ChargeDescription): number => {
    const charge = chargesByDescription[description]?.[0]
    return parseQuantity(charge?.quantity)
  }

  const getCollectionQuantity = <T>(
    description: ChargeDescription,
  ): KeyValueMap<T, { quantity: number }> => {
    const charges = chargesByDescription[description] ?? []

    return recordToKeyValueMap<any, any>(charges.reduce<any>((acc, curr) => {
      const quantity = parseQuantity(curr.quantity)
      if (!curr.item || !quantity) {
        return acc
      }
      acc[curr.item] = { quantity }
      return acc
    }, {}))
  }

  const getCollectionQuantityandPrice = <T>(
    description: ChargeDescription,
  ): KeyValueMap<T, { quantity: number, unitPrice: number }> => {
    const charges = chargesByDescription[description] ?? []

    return recordToKeyValueMap<any, any>(charges.reduce<any>((acc, curr) => {
      const quantity = parseQuantity(curr.quantity)
      const unitPrice = parsePrice(curr.unitPrice)

      if (!curr.item || !quantity || !unitPrice) {
        return acc
      }
      acc[curr.item] = { quantity, unitPrice }
      return acc
    }, {}))
  }

  const payload = {
    movingLabourTime: getQuantity(ChargeDescription.movingLabour),
    packingLabourTime: getQuantity(ChargeDescription.packingLabour),
    transportLabourTime: getQuantity(ChargeDescription.transportLabour),

    travelTime: getQuantity(ChargeDescription.travelLabour),
    travelTruckDistance: getQuantity(ChargeDescription.travelTruckFeePerKilometer),
    transportTruckDistance: getQuantity(ChargeDescription.transportTruckFeePerKilometer),

    nbOvernights: getQuantity(ChargeDescription.additionalChargesOvernight),
    nbStairs: getQuantity(ChargeDescription.additionalChargesStairs),

    specialServices: getCollectionQuantity<SpecialService>(
      ChargeDescription.additionalChargesSpecialServices,
    ),
    specialItems: getCollectionQuantity<SpecialItem>(
      ChargeDescription.additionalChargesSpecialItems,
    ),
    protectiveMaterial: getCollectionQuantityandPrice<ProtectiveMaterial>(
      ChargeDescription.protectiveMaterialProduct,
    ),
  }

  return payload
}
